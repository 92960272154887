import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { FormGroup, NgForm, Validators } from '@angular/forms';
import { SharedComponentsService } from '../shared-components-service';

// import { AppSharedDialogs } from '../app-shared-dialogs/app-shared-dialogs';
import { Router } from '@angular/router';
declare var numeral: any;
import moment from 'moment';
import Swal from 'sweetalert2';
import { of, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-times-entry',
  templateUrl: './times-entry.component.html'
})
export class TimesEntryComponent implements OnInit, AfterViewInit {
  showTimeSheetDialog: boolean = false;
  public isTopShow: boolean = false;
  public fromTimer: boolean = false;
  public isFromProject: boolean = false;
  public companyId;
  isEditTS: any;
  activeDate: any;
  timeSheetList: any;
  actualTimeTooltip: any;
  public client: string;
  public filteredClients: any[];
  billableTimeTooltip: any;
  projectsList: any = [];
  clientProjectsList: any = [];
  clientProjectTasksList: any = [];
  ClientName: any = [];
  isShowMemo = false;
  ClientID: any;
  showActualTimeToolTip: any;
  showBillableTimeToolTip: any;
  billableTimeChk: any;
  billingRateChk: any;
  staffID: any;
  staffName: any;
  user: any = 'staff';
  tse: any = { isDisable: false };
  dateList = [];
  counterList = [];
  isMemoChanged: boolean = false;
  public UserLevel: any = null;
  busy: Promise<any>;
  companyGlobalSetting: any = {};
  ddmSettings: any = {};
  startDate = moment().startOf('isoWeek').toDate();
  endDate = moment().endOf('isoWeek').toDate();
  staffList: any = [];
  clientList: any = [];
  activityList: any = [];
  timeIntervalDataSet: any = [];
  selClient;
  selProjectHeaderID;
  selProjectDetailsID;
  public staffDetails: any = {};
  public timerTask: any = {};
  public isUpdated: boolean = false;
  public isBillable: boolean = true;
  isFormValid: boolean = false;
  public workLocationsList: any = [];
  intervalid: any;
  public isFlatFeeText = 'Flat Fee';
  public isFlatFee = false;
  isBillingRateFetching: boolean = false;
  public companyLocationObj: any = null;
  @ViewChild('standamt') private elementRef: ElementRef;
  workLocation;
  titleDataSet;
  savedWorkLocation;
  timeexp;
  budgetList: any;
  isBillableAmtChanged: boolean = false;
  userPermissionType: string;
  invalidTimeDifferenceTimeout: any;
  invalidTimeDifferenceStatus: boolean;
  safeMemoUpdate: string;
  currentActivityId: number;
  openModalTime: any;

  debounceTimer: any;

  @Input() displayTimeEntryModal: boolean = false;
  @Output() closeTimeEntryModal = new EventEmitter<any>();
  @ViewChild('timeEntryMainForm') timeEntryMainForm;
  companySetting: any;
  isSaving: boolean = false;
  @ViewChild('autoComplete') autoComplete: AutoComplete;
  private currentFocusedIndex = -1;
  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private auth: AuthGuard,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    private _translate: TranslateService
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.sharedSrvc.engagementDialogData.subscribe(data => {
      if (this.selClient && this.selClient['ClientID']) {
        this.getProjects(this.selClient['ClientID']);
      }
    });
    this.sharedSrvc.activityDialogData.subscribe(data => {
      if (data) {
        this.getActivityGroups();
      }
    });
  }

  ngOnInit(): void {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

    this.UserLevel = this.encrDecSrvc.getObject(AppConstants.staffPermission);

    this.userPermissionType = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Title;

    this.workLocation = this.encrDecSrvc.getObject(AppConstants.workLocations);
    this.savedWorkLocation = this.encrDecSrvc.getObject(AppConstants.savedWorkLocation);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
    this.getAllDataList();

    const now = new Date();
    this.openModalTime = now.toISOString();
  }

  ngAfterViewInit() {
    this.timeEntryMainForm.control.valueChanges.subscribe(values => {
      this.validateForm();
      // this.isFormValid = this.timeEntryMainForm.valid && this.timeEntryMainForm.dirty;
    });    
  }
  
  validateForm() {
    this.isFormValid =
      this.timeEntryMainForm.valid &&
      (this.timeEntryMainForm.dirty || this.isMemoChanged || this.fromTimer);
  }

  getBudgetYears() {
    if (!this.tse['ClientID']) return;

    this.mangoAPISrvc
      .getBudgetEngagementAndYearOptions(
        this.tse['ClientID'],
        this.tse['ddmProjectHeaderID'] || null,
        this.companyId
      )
      .subscribe(
        (results: any) => {
          if (results?.length > 0 && this.tse['ProjectMasterID']) {
            results = results.filter(
              result => result.ProjectMasterID == this.tse['ProjectMasterID']
            );
          }
          if (!this.tse['BudgetsID'] && !this.isEditTS)
            this.tse['BudgetsID'] = results.find(
              result => result.Year == new Date().getFullYear()
            )?.BudgetsID;

          this.budgetList = results.map(result => ({
            label: result.Year?.toString(),
            value: result.BudgetsID
          }));
          if (!this.tse['BudgetsID'] && !this.isEditTS)
            this.tse['BudgetsID'] = this.budgetList?.length > 0 ? this.budgetList[0].value : null;

          console.log(results);
        },
        err => {}
      );
  }

  closePrivateMemo() {
    this.isShowMemo = false;
  }

  clearPrivateMemo() {
    this.tse.PrivateMemo = '';
  }

  async getProjects(clientId, isInitialEdit: boolean = false) {
    try {
      this.projectsList = [];
      if (!clientId) {
        return false;
      }

      this.mangoAPISrvc.showLoader(true);

      const data = await this.mangoAPISrvc.getProjectsByClientId(clientId).toPromise();
      let filterData = Object.values(data).filter(note => !note.Inactive);

      for (let i = 0; i < filterData.length; i++) {
        this.projectsList.push({
          label: filterData[i].EngagementName,
          value: filterData[i].ProjectMasterID,
          EngagementTypeID: filterData[i].EngagementTypeID,
          isBillable: filterData[i].isBillable
        });
      }
      const translatedPlaceholder = this._translate.instant('select-engagement'); 
      const dropdown = document?.querySelector('#itemProjectSelected');
      if (dropdown) {
        const dropdownLabel = dropdown.querySelector('.p-dropdown-label');
        if (dropdownLabel) {
          dropdownLabel.textContent = translatedPlaceholder;
        }
      }

      if (this.projectsList.length === 1) {
        this.tse['EngagementName'] = this.projectsList[0].label;
        this.tse['ProjectMasterID'] = this.projectsList[0].value;
        this.tse['EngagementTypeID'] = this.projectsList[0].EngagementTypeID;
        this.tse['isBillable'] = this.projectsList[0].isBillable;
        const dropdown = document?.querySelector('#itemProjectSelected');
        const dropdownLabel = dropdown?.querySelector('.p-dropdown-label');
        if (dropdownLabel) {
          dropdownLabel.textContent = this.projectsList[0].label;
        }
        this.selClient = this.selClient || {};
        this.selClient['MarkSlipsBilled'] = this.selClient['MarkSlipsBilled'] || false;
        this.selClient['Billable'] = this.selClient['Billable'] || false;

        let tempEng = this.projectsList.filter(
          proj => this.tse['ProjectMasterID'] == proj.value
        )[0];
        tempEng['isBillable'] = tempEng['isBillable'] || false;

        if (
          !isInitialEdit &&
          (this.selClient['MarkSlipsBilled'] === true ||
            this.selClient['Billable'] === false ||
            tempEng.isBillable === false)
        ) {
          this.tse.Billable = false;
        }

        await this.getClientProjects(
          !this.selClient['ClientID'] ? this.tse.ClientID : this.selClient['ClientID'],
          false
        );
      }

      if (this.tse.ProjectMasterID) {
        this.getBudgetYears();
      }

      this.projectsList = this.projectsList.filter(proj => proj.value !== null);

      this.mangoAPISrvc.showLoader(false);

      setTimeout(() => {
        this.applyBillableTime(false, isInitialEdit);
      }, 100);

      return true;
    } catch (error) {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false);
      throw error;
    }
  }

  async getClientProjects(clientId, isEdit, isAdd?) {
    if (isAdd) {
      const res: any = await this.mangoAPISrvc
        .getProjectList(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        .toPromise();
      if (res.status === 403) {
        this.handlePermissionError('add');
        return false;
      }
    }
    try {
      if (clientId && this.tse.ProjectMasterID) {
        const res: any = await this.mangoAPISrvc
          .getProjectList(
            null,
            null,
            null,
            null,
            clientId,
            null,
            null,
            this.companyId,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.tse.ProjectMasterID,
            null
          )
          .toPromise();

        if (res.status === 403) {
          this.handlePermissionError('edit');
          return false;
        }

        this.clientProjectsList = Array.isArray(res?.projectList)
          ? res?.projectList.map(res => ({
              ProjectHeaderID: res.ProjectHeaderID,
              TemplateName: res.TemplateName
            }))
          : [];
        if (!this.ddmSettings) return true;
        if (
          this.ddmSettings.isTrackingTimebyProjects &&
          this.tse.ddmProjectHeaderID &&
          this.clientProjectsList.length > 0
        ) {
          const evt = this.clientProjectsList
            .filter(a => a.ProjectHeaderID === this.tse.ddmProjectHeaderID)
            .map(res => ({
              ProjectHeaderID: res.ProjectHeaderID,
              TemplateName: res.TemplateName
            }))[0];

          this.selProjectHeaderID = evt;
          this.handleProjectSelectClick(evt, 0);
        }

        return true;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  private handlePermissionError(message: string) {
    this.mangoAPISrvc.notify('error', 'Error!', `You don't have permissions to ${message}`);
    this.mangoAPISrvc.showLoader(false);
  }

  handleSelectClick(obj, reset?) {
    let parent = this;

    this.selProjectHeaderID = null;
    this.selProjectDetailsID = null;

    if (obj.BlockTimeExpenseEntry) {
      parent.selClient = parent.tse?.ClientID
        ? {
            ClientID: parent.tse.ClientID,
            ClientName: parent.tse.ClientName,
            MarkSlipsBilled: parent.tse.MarkSlipsBilled,
            Billable: parent.tse.Billable
          }
        : null;
      Swal.fire({
        icon: 'warning',
        title: `${parent._translate.instant('Warning')}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${parent._translate.instant('block-time-entry')}!`
      });
    } else {
      if (reset) {
        this.tse.ProjectMasterID = null;
        this.tse.ServiceCodeID = null;
        this.tse['BudgetsID'] = null;
      }
      parent.mangoAPISrvc.getClientFullinformation(obj.ClientID).subscribe((clientdata: any) => {
        parent.selClient = clientdata;
        parent.tse.ClientName = clientdata.ClientName;
        parent.tse.ClientID = clientdata.ClientID;
        parent.tse.OriginatingPartnerID = clientdata.OriginatingPartnerID;
        parent.tse.BillingPartnerID = clientdata.BillingPartnerID;
        parent.tse.GroupDescriptionID = clientdata.BillingGroupID;
        parent.tse.GroupDescriptionIDArray = clientdata.GroupDescriptionIDArray;
        parent.tse.ClientTypeID = clientdata.ClientTypeID;
        parent.tse.Billable = clientdata.Billable ? clientdata.Billable : false;
        clientdata.MarkSlipsBilled = clientdata.MarkSlipsBilled
          ? clientdata.MarkSlipsBilled
          : false;
        parent.selClient['MarkSlipsBilled'] = clientdata.MarkSlipsBilled;

        Promise.resolve(parent.getProjects(clientdata.ClientID));

        /*
          Promise.resolve( parent.getClientProjects( clientdata.ClientID, false ) );
          */

        if (!parent.tse.BillingRate) {
          this.getBillingRateForUser();
        }
      });
    }
  }

  handleProjectSelectClick(evt, type) {
    if (type == 0) {
      // Project Header ID
      if (evt) {
        this.tse.ddmProjectHeaderID = evt.ProjectHeaderID;
        this.selProjectDetailsID = null;
        this.mangoAPISrvc
          .fetchprojectDetailsByHeaderId(evt.ProjectHeaderID)
          .subscribe((responseData: any) => {
            const taskList = responseData.taskList;
            const res = taskList;

            this.clientProjectTasksList = res
              .map(res => {
                return {
                  ProjectDetailsID: res.ProjectDetailsID,
                  TaskDescription: res.TaskDescription,
                  TaskRow: res.TaskRow
                };
              })
              .sort(function (a, b) {
                return a.TaskRow - b.TaskRow;
              });
            if (!this.selProjectDetailsID && this.tse.ddmProjectDetailID)
              this.selProjectDetailsID = this.clientProjectTasksList
                .filter(a => {
                  return a.ProjectDetailsID == this.tse.ddmProjectDetailID;
                })
                .map(res => {
                  return {
                    ProjectDetailsID: res.ProjectDetailsID,
                    TaskDescription: res.TaskDescription
                  };
                })[0];
          });
      }
    } else {
      this.tse.ddmProjectDetailID = evt.ProjectDetailsID;
    }
  }

  getDDMSetting() {
    const promiseResult = new Promise((resolve, reject) => {
      try {
        this.ddmSettings = this.encrDecSrvc.getObject(AppConstants.companyTags);

        if (!this.ddmSettings && (this.companyId || this.companyId != this.ddmSettings.CompanyID)) {
          this.mangoAPISrvc.fetchDDMSettings(this.companyId).subscribe((res: any) => {
            this.ddmSettings = res[0];

            this.encrDecSrvc.addObject(AppConstants.companyTags, res[0]);

            resolve(true);
          });
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });

    return promiseResult;
  }

  async getAllDataList(): Promise<any> {
    try {
      const promiseResult = new Promise(resolve => {
        setTimeout(resolve, 50);
      });

      await Promise.all([
        this.fetchClients(),
        this.getAllStaffList(),
        this.getActivityGroups(),
        this.getCompanyGlobalSetting(),
        this.getWorkLocations()
      ]);

      const promiseGetDDMSetting = this.getDDMSetting();

      this.mangoUtils.shouldFetchCode.next(true);

      return await Promise.allSettled([promiseResult, promiseGetDDMSetting]);
    } catch (error) {
      throw error;
    }
  }

  fetchClients() {
    let list = this.encrDecSrvc.clientList?.filter(
      item => item['ContactRecord'] != true && item['Inactive'] != true
    );

    if (this.clientList.length !== list.length) {
      this.clientList = [];

      for (let i = 0; i < list.length; i++) {
        let item = list[i];

        if (item['ContactRecord'] != true && item['Inactive'] != true) {
          this.clientList.push(item);

          // this.filteredClients.push(item);
        }
      }

      return false;
    } else {
      clearInterval(this.intervalid);

      return true;
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      let filtered: any[] = [];
      let query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        let client = this.clientList[i];
        if (
          client.ClientName &&
          client.ClientName.toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] == false
        ) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  filterClientProjects(event, type) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      let filtered: any[] = [];
      let query = event.query;

      if (type == 0) {
        //client projects
        for (let i = 0; i < this.clientProjectsList.length; i++) {
          let p = this.clientProjectsList[i];
          if (p.TemplateName && p.TemplateName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            filtered.push(p);
          }
          if (filtered.length > 20) break;
        }
        this.clientProjectsList = filtered;
      } else if (type == 1) {
        //client project tasks
        for (let i = 0; i < this.clientProjectTasksList.length; i++) {
          let p = this.clientProjectTasksList[i];
          if (
            p.TaskDescription &&
            p.TaskDescription.toLowerCase().indexOf(query.toLowerCase()) > -1
          ) {
            filtered.push(p);
          }
          if (filtered.length > 20) break;
        }
        this.clientProjectTasksList = filtered;
      }

      this.filterTimeout.unsubscribe();
    });
  }

  async editTSModal(obj) {
    this.mangoAPISrvc.showLoader(true);

    this.tse = obj;
    this.safeMemoUpdate = this.tse.Memo;
    await this.getAllDataList();

    this.selClient = await this.getFullclientInformation(obj.ClientID);

    if (this.selClient && this.ddmSettings?.isTrackingTimebyProjects) {
      const response = await this.getClientProjects(obj.ClientID, true);
      if (!response) return;
    }

    await this.getProjects(obj.ClientID, true);

    setTimeout(() => {
      this.isEditTS = true;

      this.tse.WorkLocation = obj.WorkLocation ? parseInt(obj.WorkLocation) : obj.WorkLocation;

      this.tse['isDisable'] = this.tse['isDisable'] ? this.tse['isDisable'] : false;

      /*
          this.tse.StaffName = this.staffName;
          this.tse.StaffID = this.staffID;
          */

      if (obj.isFlatFee) {
        this.isFlatFeeText = 'Hourly';

        this.tse.StandardAmount = this.tse['BilledAmount'];
      } else {
        this.isFlatFeeText = 'Flat Fee';

        if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero) {
          this.tse.StandardAmount = '0.00';
        } else {
          this.tse.StandardAmount = numeral(this.tse.StandardAmount).format('0,0.00');
        }
      }

      const dateInUTC = moment.utc(this.tse.Ddate).startOf('day');
      const offset = dateInUTC.utcOffset() - moment().utcOffset();
      const adjustedDate = dateInUTC.add(offset, 'minutes').toDate();
      this.tse.displayDate = adjustedDate;

      this.tse.Ddate = new Date(this.tse.Ddate);
      this.tse.BillingRate = obj.BillingRate;

      this.tse.OverrideBillingRate = this.tse.OverrideBillingRate
        ? this.tse.OverrideBillingRate
        : false;

      this.billingRateChk = this.tse.OverrideBillingRate;

      let enteredValue = numeral(this.tse.StandardAmount).value();

      enteredValue = enteredValue ? enteredValue : 0;

      /*
          if(
                this.selClient?.MarkSlipsBilled
            &&
                this.timeexp?.isAdminTimeZero
          ){
            this.tse.StandardAmount = '0.00';
          }
          else{
            this.tse.StandardAmount = (
              numeral( this.tse.StandardAmount )
              .format( '0,0.00' )
            );
          }

          this.tse.TimeStart = (
            new Date(
              moment( dStart ).year( ),
              moment( dStart ).month( ),
              moment( dStart ).date( ),
              moment( dStart ).hour( ),
              moment( dStart ).minute( ),
              0
            )
          );
          */

      this.tse.TimeStart = this.addOffset(new Date(this.tse.TimeStart));

      if (this.tse.TimeStop) {
        /*
            this.tse.TimeStop = (
              new Date(
                moment( dStop ).year( ),
                moment( dStop ).month( ),
                moment( dStop ).date( ),
                moment( dStop ).hour( ),
                moment( dStop ).minute( ),
                0
              )
            );
            */

        this.tse.TimeStop = this.addOffset(new Date(this.tse.TimeStop));
      } else {
        this.tse.TimeStop = undefined;
      }

      this.tse.ElaspedTime = this.tse.ElaspedTime;
      this.tse.TotalTime = this.tse.TotalTime;
      this.tse.ServiceDesc = this.tse.ServiceCode;

      // populate clientID
      let self = this;

      /*
          let client = (
            this.clientList.filter(
              (
                ( item ) => {
                  return  (
                            item.label == self.tse.ClientName
                          );
                }
              )
            )[ 0 ]
          );

          this.selClient = client;
          */

      this.selClient = {
        ClientID: this.tse.ClientID,
        ClientName: this.tse.ClientName,
        MarkSlipsBilled: this.tse.MarkSlipsBilled,
        Billable: this.tse.Billable
      };

      // populate serviceCode
      this.handleActivitySelectClick(
        {
          value: self.tse.ServiceCodeID
        },
        false,
        false,
        true
      );

      // populate staffID
      let staff = this.staffList.filter(item => {
        return item.value && item.value.staffID == this.staffID;
      })[0];

      if (staff) {
        this.staffID = staff.value;

        this.selectEditStaff(staff, true);
      }

      if(!this.validateLockTime()) {
        return;
      }

      this.tse.ElaspedTime = numeral(numeral(this.tse.ElaspedTime).value()).format('0.00');

      if (
        !this.tse.ElaspedTime ||
        this.tse.ElaspedTime.toString().indexOf('.') < 0 ||
        this.tse.ElaspedTime.toString().split('.').length > 2
      ) {
        this.tse.ElaspedTime = 0.0;
      }

      this.tse.ElaspedTime = this.formateNumber(this.tse.ElaspedTime);

      if (!this.tse.TotalTime) {
        return;
      }

      this.billableTimeTooltip =
        this.tse.TotalTime.toString().split('.')[0] +
        ' hours, ' +
        Math.round(parseInt(this.tse.TotalTime.toString().split('.')[1]) * 0.6) +
        ' minutes';

      this.actualTimeTooltip =
        Math.round(this.tse.ElaspedTime.toString().split('.')[0]) +
        ' hours, ' +
        Math.round(parseInt(this.tse.ElaspedTime.toString().split('.')[1]) * 0.6) +
        ' minutes';

      if (this.tse.Billed && !this.selClient.MarkSlipsBilled) {
        this.displayTimeEntryModal = false;

        this.mangoAPISrvc.notify(
          'error',
          'Error!',
          'This time entry is set as billed. You are not allowed to edit it.'
        );

        this.sharedSrvc.sharedCompVisibility.timesEntry = false;
      } else {
        this.displayTimeEntryModal = true;
      }

      this.calculateBillableAmount();

      this.mangoAPISrvc.showLoader(false);
    }, 50);
  }

  validateLockTime( selDate? , keepModalOpen? , newTimeEntry? ) {
    if(!selDate) selDate=this.tse.Ddate;
    if (this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0 
          && !(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      const dDate = moment(new Date(selDate)).format('MM/DD/YYYY');
      const dateNow = moment().format('MM/DD/YYYY');
      if (moment(dateNow, 'MM/DD/YYYY').diff(moment(dDate, 'MM/DD/YYYY'), 'days') > this.timeexp.LockTimeDays) {
        if(!keepModalOpen) {
          this.mangoAPISrvc.notify(
            'error',
            'Error!',
            newTimeEntry?'Time entries are locked for selected date. You are not allowed to create it.':'This time entry is still locked. You are not allowed to edit it.'
          );
          this.displayTimeEntryModal = false;
          this.sharedSrvc.sharedCompVisibility.timesEntry = false;
        }else{
          Swal.fire({
            icon: 'error',    
            title: `${this._translate.instant('Warning')}`,
            text: `Cannot save time entry. Time entries are locked for selected date.`,    
            confirmButtonText: 'OK',    
            showCancelButton: false,
            allowEscapeKey: true,
            allowEnterKey: true
          });
        }
        return false;
      }
    }
    return true;
  }

  addTS(name) {
    if (this.invalidTimeDifferenceStatus === true) {
      Swal.fire({
        icon: 'warning',

        title: `${this._translate.instant('Warning')}`,
        text: `Cannot save time entry. Invalid time difference. Start time should be before end time!`,

        confirmButtonText: 'OK',

        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true
      });

      return;
    }

    if(this.validateLockTime(this.tse.displayDate, true) == false) return;

    this.isSaving = true;
    let klassObj = this;
    this.isFlatFee = this.isFlatFeeText == 'Flat Fee' ? false : true;
    this.isFlatFeeText = 'Flat Fee';
    let timeAndExpense = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    // call update incase of edit mode
    if (this.isEditTS) {
      return this.updateTS(name);
    }

    if (this.tse.ElaspedTime == 0.0 && !timeAndExpense.isZeroTimeAllowed) {
      Swal.fire({
        icon: 'warning',

        title: `${this._translate.instant('Warning')}`,
        text: `${this._translate.instant('zero-time-records-not-allowed')}.`,

        confirmButtonText: 'OK',

        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true
      });

      return;
    }

    this.isEditTS = false;
    if (name == 'no' || name == 'yes') {
      // this.displayTimeEntryModal = false;
      if (name == 'no') {
        this.displayTimeEntryModal = false;
        return;
      }
    }
    if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero)
      this.tse.StandardAmount = '0.00';
    else
      this.tse.StandardAmount = numeral(
        numeral(this.tse.BillingRate).value() * numeral(this.tse.TotalTime).value()
      ).format('0,0.00');

    klassObj.tse['EngagementTypeID'] = klassObj.projectsList.filter(
      item => item.value == klassObj.tse.ProjectMasterID
    )[0]['EngagementTypeID'];
    klassObj.tse['GroupDescriptionIDArray'] =
      klassObj.tse['GroupDescriptionIDArray']?.length > 0
        ? klassObj.tse['GroupDescriptionIDArray']
        : null;
    klassObj.calculateSalesTax();
    //this.tse.displayDate = this.tse.Ddate;
    //this.removeOffset(this.tse.Ddate),
    let obj = {
      Ddate: moment(this.tse.displayDate).format('YYYY-MM-DD'),
      TimeStart: this.removeOffset(this.tse.TimeStart),
      TimeStop: this.tse.TimeStop ? this.removeOffset(this.tse.TimeStop) : this.tse.TimeStop,
      Approved: this.tse.Approved,
      Billable: this.tse.Billable,
      Billed: this.tse.Billed,
      BillingRate: numeral(this.tse.BillingRate).value(),
      ClientID: this.tse.ClientID,
      ClientName: this.tse.ClientName,
      ElaspedTime: this.tse.ElaspedTime,
      Description: this.tse.Description,
      Memo: this.tse.Memo,
      ServiceCodeID: this.tse.ServiceCodeID,
      StandardAmount: numeral(this.tse.StandardAmount).value(),
      TotalTime: this.tse.TotalTime,
      StaffID: this.tse.StaffID,
      StaffName: this.tse.StaffName,
      StaffDeptID: this.tse.StaffDeptID,
      StaffPayRate: this.tse.StaffPayRate,
      StaffCost: Number(this.tse.TotalTime) * Number(this.tse.StaffPayRate),
      WorkCodeID: this.tse.WorkCodeID,
      OriginatingPartnerID: this.tse.OriginatingPartnerID,
      BillingPartnerID: this.tse.BillingPartnerID,
      GroupDescriptionID: this.tse.GroupDescriptionID,
      GroupDescriptionIDArray: this.tse['GroupDescriptionIDArray'],
      ClientTypeID: this.tse.ClientTypeID,
      ProjectMasterID: this.tse.ProjectMasterID,
      OverrideBillingRate: this.billingRateChk,
      PrivateMemo: this.tse.PrivateMemo,
      IsTimeRecord: 'T',
      ExpenseCodeID: this.tse['ExpenseCodeID'],
      ExpenseCode: this.tse['ExpenseCode'],
      Cost: this.tse['Cost'],
      Units: this.tse['Units'],
      Markup: this.tse['Markup'],
      EngagementTypeID: this.tse['EngagementTypeID'],
      Tax: this.tse['Tax'],
      IsTaxable: this.tse.IsTaxable,
      isFlatFee: this.isFlatFee,
      WorkLocation: this.tse.WorkLocation,
      Reimbursed: this.tse['Reimbursed'] ? this.tse['Reimbursed'] : false,
      Reimburseable: this.tse['Reimburseable'] ? this.tse['Reimburseable'] : false,
      ddmProjectHeaderID: this.tse.ddmProjectHeaderID || null,
      ddmProjectDetailID: this.tse.ddmProjectDetailID || null,
      BudgetsID: this.tse.BudgetsID || null,
      BilledAmount: this.isBillableAmtChanged ? numeral(this.tse['BilledAmount']).value() : 0,
      CompanyID: this.companyId
    };

    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }
    // Check to see if ClientID.markSlipAsBilled = TRUE - make Billed=TRUE
    if (this.selClient['MarkSlipsBilled'] == true) {
      obj.Billed = true;
      obj['InvoiceDate'] = obj.Ddate;
    }

    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, this.tse.WorkLocation);
    klassObj.mangoAPISrvc.showLoader(true);
    klassObj.mangoAPISrvc.createTimeSheet(obj).subscribe(
      function (data) {
        klassObj.mangoAPISrvc.notify('success', 'Created!', AppConstants.createMsg);
        klassObj.mangoAPISrvc
          .sendBudgetAlert({
            ClientID: obj['ClientID'],
            ProjectMasterID: obj['ProjectMasterID'],
            CompanyID: klassObj.companyId,
            Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
          })
          .subscribe(
            data => {},
            err => {
              console.log(err);
            }
          );
        klassObj.actualTimeTooltip = '0 hours , 0 minutes';
        if (klassObj.fromTimer) {
          klassObj.deleteTimer();
          klassObj.isFormValid = true;
        }
        klassObj.isUpdated = true;
        if (name == 'duplicate') {
          klassObj.mangoAPISrvc.getLastEndTime(moment().format('YYYY-MM-DD')).subscribe(
            (result: any) => {
              if (result.TimeStop) {
                klassObj.tse.TimeStart = new Date(
                  moment.utc(result.TimeStop).year(),
                  moment.utc(result.TimeStop).month(),
                  moment.utc(result.TimeStop).date(),
                  moment.utc(result.TimeStop).hour(),
                  moment.utc(result.TimeStop).minute(),
                  0
                );
                klassObj.tse.TimeStop = null;
              }
            },
            err => {}
          );
          klassObj._markFormPristine(klassObj.timeEntryMainForm);
        } else if (name == 'new') {
          const translatedPlaceholder = klassObj._translate.instant('select-engagement'); 
          const dropdown = document?.querySelector('#itemProjectSelected');
          if (dropdown) {
            const dropdownLabel = dropdown.querySelector('.p-dropdown-label');
            if (dropdownLabel) {
              dropdownLabel.textContent = translatedPlaceholder;
            }
          }
          klassObj.addTSModal(klassObj.tse.Ddate, klassObj);
          setTimeout(() => {
            this.tse.Memo = '';
          }, 500);
        } else {
          klassObj.closeTimeEntryForm();
        }
        klassObj.isSaving = false;
        klassObj.mangoAPISrvc.showLoader(false);
      },
      error => {
        klassObj.mangoAPISrvc.notify('error', 'Error!', error);
        klassObj.isSaving = false;
        klassObj.mangoAPISrvc.showLoader(false);
      }
    );
  }

  calculateSalesTax() {
    // See if Company has Sales Tax Activated
    let lRates = this.companyGlobalSetting.ActivateLaborRates;
    this.tse['IsTaxable'] = false;

    let actualTaxRate = 0;
    if (lRates && lRates == true) {
      // 1st Check - Where to get labor rates by checking SalesTaxLevel Values, FirmRate, CompanyLocationRate, ClientRate
      if (this.selClient && this.selClient.SalesTaxLevel == 'ClientRate') {
        actualTaxRate = this.selClient.Tax1ID;
      } else if (this.selClient && this.selClient.SalesTaxLevel == 'CompanyLocationRate') {
        actualTaxRate = this.companyLocationObj.TaxRateLaborL;
      } else {
        actualTaxRate = this.companyGlobalSetting.LaborRate1;
      }
      this.tse['Tax'] =
        numeral(this.tse.StandardAmount).value() * (numeral(actualTaxRate).value() / 100);
      this.tse['IsTaxable'] = true;
    }
  }

  async getFullclientInformation(clientId) {
    try {
      const data = await this.mangoAPISrvc.getClientFullinformation(clientId);
      return of(data);
    } catch (error) {
      throw error;
    }
  }

  updateTS(name) {
    if (this.invalidTimeDifferenceStatus === true) {
      Swal.fire({
        icon: 'warning',

        title: `${this._translate.instant('Warning')}`,
        text: `Cannot save time entry. Invalid time difference. Start time should be before end time!`,

        confirmButtonText: 'OK',

        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true
      });

      return;
    }

    this.isSaving = true;
    if (name == 'no') {
      return;
    }
    this.isFlatFee = this.isFlatFeeText == 'Flat Fee' ? false : true;
    this.isFlatFeeText = 'Flat Fee';
    let klassObj = this;
    //this line is to make sure that BillingRate has value regardless.
    klassObj.tse.BillingRate = klassObj.tse.BillingRate ? klassObj.tse.BillingRate : 0;
    if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero)
      klassObj.tse.StandardAmount = '0.00';
    else
      klassObj.tse.StandardAmount = numeral(
        numeral(klassObj.tse.BillingRate).value() * numeral(klassObj.tse.TotalTime).value()
      ).format('0,0.00');

    klassObj.tse['EngagementTypeID'] = klassObj.projectsList.filter(
      item => item.value == klassObj.tse.ProjectMasterID
    )[0]['EngagementTypeID'];
    klassObj.tse['GroupDescriptionIDArray'] =
      klassObj.tse['GroupDescriptionIDArray']?.length > 0
        ? klassObj.tse['GroupDescriptionIDArray']
        : null;
    klassObj.calculateSalesTax();

    let obj = {
      Ddate: moment(this.tse.displayDate).format('YYYY-MM-DD'),
      TimeStart: this.removeOffset(this.tse.TimeStart),
      TimeStop: this.tse.TimeStop ? this.removeOffset(this.tse.TimeStop) : this.tse.TimeStop,
      Approved: this.tse.Approved,
      Billable: this.tse.Billable,
      Billed: this.selClient?.MarkSlipsBilled ? true : this.tse.Billed,
      BillingRate: numeral(this.tse.BillingRate).value(),
      ClientID: this.tse.ClientID,
      ClientName: this.tse.ClientName,
      ElaspedTime: this.tse.ElaspedTime,
      Description: this.tse.Description,
      Memo: this.tse.Memo,
      ServiceCode: this.tse.ServiceCode,
      ServiceCodeID: this.tse.ServiceCodeID,
      StandardAmount: numeral(this.tse.StandardAmount).value(),
      TotalTime: this.tse.TotalTime,
      StaffID: this.tse.StaffID,
      StaffName: this.tse.StaffName,
      StaffDeptID: this.tse.StaffDeptID,
      StaffPayRate: this.tse.StaffPayRate,
      StaffCost: Number(this.tse.TotalTime) * Number(this.tse.StaffPayRate),
      WorkCodeID: this.tse.WorkCodeID,
      OriginatingPartnerID: this.tse.OriginatingPartnerID,
      BillingPartnerID: this.tse.BillingPartnerID,
      GroupDescriptionID: this.tse.GroupDescriptionID,
      GroupDescriptionIDArray: this.tse.GroupDescriptionIDArray,
      ClientTypeID: this.tse.ClientTypeID,
      ProjectMasterID: this.tse.ProjectMasterID,
      OverrideBillingRate: this.billingRateChk,
      PrivateMemo: this.tse.PrivateMemo,
      EngagementTypeID: this.tse.EngagementTypeID,
      IsTimeRecord: 'T',
      WorkLocation: this.tse.WorkLocation,
      Tax: this.tse.Tax,
      IsTaxable: this.tse.IsTaxable,
      isFlatFee: this.isFlatFee,
      ddmProjectHeaderID: this.tse.ddmProjectHeaderID,
      ddmProjectDetailID: this.tse.ddmProjectDetailID,
      BudgetsID: this.tse.BudgetsID,
      BilledAmount: this.isBillableAmtChanged ? numeral(this.tse['BilledAmount']).value() : 0,
      CompanyID: this.companyId
    };
    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }
    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, this.tse.WorkLocation);
    this.mangoAPISrvc.updateTimeSheet(this.tse.SlipMasterID, obj).subscribe(
      function (data) {
        klassObj.mangoAPISrvc
          .sendBudgetAlert({
            ClientID: obj['ClientID'],
            ProjectMasterID: obj['ProjectMasterID'],
            CompanyID: klassObj.companyId,
            Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
          })
          .subscribe(
            data => {},
            err => {
              console.log(err);
            }
          );
        klassObj.getCompanyGlobalSetting();
        klassObj.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
        klassObj.isEditTS = false;
        klassObj.actualTimeTooltip = '0 hours , 0 minutes';

        if (name == 'duplicate') {
          klassObj.mangoAPISrvc.getLastEndTime(moment().format('YYYY-MM-DD')).subscribe(
            (result: any) => {
              if (result.TimeStop) {
                klassObj.tse.TimeStart = new Date(
                  moment.utc(result.TimeStop).year(),
                  moment.utc(result.TimeStop).month(),
                  moment.utc(result.TimeStop).date(),
                  moment.utc(result.TimeStop).hour(),
                  moment.utc(result.TimeStop).minute(),
                  0
                );
                klassObj.tse.TimeStop = null;
              }
            },
            err => {}
          );
          klassObj._markFormPristine(klassObj.timeEntryMainForm);
        } else if (name == 'new') {
          klassObj.addTSModal();
        } else {
          klassObj.closeTimeEntryForm(true);
        }
        klassObj.isSaving = false;
        klassObj.isUpdated = true;
      },
      error => {
        klassObj.mangoAPISrvc.notify('error', 'Error!', error);
        klassObj.isSaving = false;
        klassObj.mangoAPISrvc.showLoader(false);
      }
    );
  }

  async addTSModal(selDate?: any, klassObj?) {
    let parent = this;

    this.mangoAPISrvc.showLoader(true);

    const response = await this.getClientProjects(parent.tse.ClientID, false, true);
    if (!response) return;

    this.getAllDataList()
      .then(() => {
        parent.UserLevel = this.UserLevel;
        parent.fromTimer = false;
        parent.tse = { isDisable: false };
        parent.tse.TimeStop = null;
        parent.tse.TotalTime = null;
        parent.tse.ProjectMasterID = null;
        parent.selProjectHeaderID = null;
        parent.clientProjectTasksList = [];

        let savedWorkLocation = this.savedWorkLocation ? this.savedWorkLocation : null;

        parent.tse.WorkLocation = savedWorkLocation;
        parent.tse.Approved = parent.companyGlobalSetting.AutoApproveSlips;

        parent.tse.TimeMemoRequired = parent.companyGlobalSetting.TimeMemoRequired
          ? parent.companyGlobalSetting.TimeMemoRequired
          : false;

        parent.tse['OverrideBillingRate'] = false;

        if (selDate && selDate['ClientID'] == 'timer') {
          parent.fromTimer = true;
          parent.isFormValid = true;
        }

        // from timer
        if (selDate && selDate['isFromMenu'] == true) {
          parent.tse.Ddate = new Date();

          parent.tse.TimeStart = new Date(
            moment.utc(selDate).year(),
            moment.utc(selDate).month(),
            moment(selDate).date(),
            moment().hour(),
            moment().minute(),
            0
          );
        } else if (selDate && selDate['ClientID'] == null) {
          let dateTemp = selDate['selectedDate'] ? selDate['selectedDate'] : selDate;

          parent.tse.Ddate = new Date(dateTemp);

          parent.tse.TimeStart = new Date(
            moment.utc(selDate).year(),
            moment.utc(selDate).month(),
            moment(selDate).date(),
            moment().hour(),
            moment().minute(),
            0
          );
        } else {
          parent.tse.Ddate = new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0);

          parent.tse.TimeStart = new Date(
            moment().year(),
            moment().month(),
            moment().date(),
            moment().hour(),
            moment().minute(),
            0
          );
        }

        if(!this.validateLockTime( parent.tse.Ddate, false, true )) {
          return;
        }

        if (!parent.fromTimer) {
          if (selDate?.LatestEndTime) {
            const newDate = parent.tse.Ddate;
            const timeArr = selDate.LatestEndTime.split(':');

            newDate.setHours(timeArr[0], timeArr[1], timeArr[2]);

            parent.tse.TimeStart = newDate;
          } else {
            parent.mangoAPISrvc.getLastEndTime(moment().format('YYYY-MM-DD')).subscribe(
              (result: any) => {
                if (result.TimeStop) {
                  parent.tse.TimeStart = new Date(
                    moment.utc(result.TimeStop).year(),
                    moment.utc(result.TimeStop).month(),
                    moment.utc(result.TimeStop).date(),
                    moment.utc(result.TimeStop).hour(),
                    moment.utc(result.TimeStop).minute(),
                    0
                  );
                }
              },
              error => {}
            );
          }
        }

        parent.tse.displayDate = this.tse.Ddate;

        parent.isEditTS = false;

        let staffID = this.encrDecSrvc.getObject(AppConstants.resourceID);

        let srchStaff = parent.staffList.filter(item => {
          return item.value && item.value.staffID == staffID;
        });

        if (srchStaff.length) {
          let staff = parent.staffID
            ? {
                value: parent.staffID,
                label: parent.staffName
              }
            : srchStaff[0];

          parent.staffID = staff.value;
          parent.staffName = staff.label;

          parent.selectEditStaff(staff, false);
        }

        parent.selClient = null;
        parent.handleActivitySelectClick({}, false, false, false);

        if (selDate && selDate['ClientName']) {
          let selClient = {
            ClientID: selDate.ClientID,
            ClientName: selDate.ClientName
          };

          parent.handleSelectClick(selClient);

          parent.tse.ProjectMasterID = selDate['ProjectMasterID'];

          Promise.resolve(parent.getClientProjects(selClient['ClientID'], false));
        }

        let timerTask = parent.encrDecSrvc.getObject(AppConstants.timerTaskSelected)
          ? JSON.parse(parent.encrDecSrvc.getObject(AppConstants.timerTaskSelected))
          : null;

        if (timerTask) {
          parent.timerTask = timerTask;
          parent.tse.PrivateMemo = timerTask['PrivateMemo'];

          parent.tse.TimeStart = moment.utc(timerTask.TimeStart).toDate();

          parent.tse.ProjectMasterID = parent.timerTask['ProjectMasterID'];
          parent.tse.ddmProjectHeaderID = parent.timerTask['ddmProjectHeaderID'];
          parent.tse.ddmProjectDetailID = parent.timerTask['ddmProjectDetailID'];

          let totalTime: any = '';
          if (new Date(timerTask.TotalTime).toString() == 'Invalid Date') {
            totalTime = moment(timerTask.TotalTime, 'hh:mm:ss');
          } else {
            let validDate = moment.utc(timerTask.TotalTime).toDate();

            let str =
              validDate.getHours() + ':' + validDate.getMinutes() + ':' + validDate.getSeconds();

            totalTime = moment(str, 'hh:mm:ss');
          }

          //let totalTime = moment(timerTask.TotalTime,'hh:mm:ss');
          //moment.utc(timerTask.TotalTime).toDate();

          parent.tse.ElaspedTime = parent.tse.TotalTime = parent.convertHoursToNumericHours(
            `${moment(totalTime).format('hh:mm:ss')}`
          );

          parent.tse.TimeStop = moment
            .utc(timerTask.TimeStart)
            .add(totalTime.hours(), 'h')
            .add(totalTime.minutes(), 'm')
            .add(totalTime.seconds(), 's')
            .toDate();

          parent.calculateTimeDifference();

          // populate client
          let selClient = {
            ClientID: timerTask.ClientID,
            ClientName: timerTask.ClientName,
            OriginatingPartnerID: timerTask.OriginatingPartnerID,
            BillingPartnerID: timerTask.BillingPartnerID,
            GroupDescriptionID: timerTask.GroupDescriptionID,
            GroupDescriptionIDArray: timerTask['GroupDescriptionIDArray'],
            ClientTypeID: timerTask.ClientTypeID,
            MarkSlipsBilled: timerTask.MarkSlipsBilled,
            Billable: timerTask.Billable,
            isBillable: timerTask.isBillable
          };

          parent.handleSelectClick(selClient);

          // populate serviceCode
          parent.handleActivitySelectClick(
            {
              value: timerTask.ServiceCodeID
            },
            false,
            true,
            false
          );

          parent.tse.Memo = timerTask.Memo;
        }

        parent.isFromProject = false;

        if (selDate?.isFromProject) {
          parent.isFromProject = true;

          const selClient = {
            ClientID: selDate['projectObject']['ClientID'],
            ClientName: selDate['projectObject']['ClientName'],
            MarkSlipsBilled: selDate['projectObject']['MarkSlipsBilled'],
            Billable: selDate['projectObject']['Billable']
          };

          parent.selClient = selClient;

          parent.handleSelectClick(selClient);
          parent.tse.ProjectMasterID = selDate['projectObject']['ProjectMasterID'];
          parent.tse.ddmProjectHeaderID = selDate['projectObject']['ddmProjectHeaderID'];
          parent.tse.ddmProjectDetailID = selDate['projectObject']['ddmProjectDetailID'];

          Promise.resolve(parent.getClientProjects(selClient['ClientID'], false));
        } else {
          if (parent.tse.ddmProjectHeaderID) {
            Promise.resolve(parent.getClientProjects(parent.timerTask['ClientID'], false));
          }
        }

        // parent.getBudgetYears( );
        parent.displayTimeEntryModal = true;

        return true;
      })
      .then(() => {
        parent.encrDecSrvc.removeObject(AppConstants.timerTaskSelected);

        return true;
      })
      .then(() => {
        this.mangoAPISrvc.showLoader(false);
      })
      .catch(() => {
        this.mangoAPISrvc.showLoader(false);
      });
  }

  /*
  openTimeEntryDialog(data: any) {
    Promise.resolve( this.getAllDataList( ) );

    this.showTimeSheetDialog = true;
  }
  */

  getAllStaffList() {
    let parent = this;
    parent.staffList = [];
    //parent.staffList = [{ label: 'Select Staff', value: -1 }];
    let tempList = [];
    if (this.auth.isAllowAccess(14)) {
      tempList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
      tempList = tempList?.filter(
        staff => staff?.StaffID == this.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      parent.staffList.push({
        label: tempList[i].StaffName,
        value: { staffID: tempList[i].StaffID, staffName: tempList[i].StaffName }
      });
    }
  }

  getActivityGroups() {
    let parent = this;
    let tempList = this.encrDecSrvc.getObject(AppConstants.activityList);
    if (
      tempList.length == 0 ||
      (this.activityList.length > 0 && this.activityList.length == tempList.length)
    ) {
      return false;
    }
    parent.activityList = [];
    tempList = tempList.sort(parent.mangoUtils.compareValues('ServiceCode', 'asc'));
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive == false) {
        parent.activityList.push({
          label: tempList[i].ServiceCode + ' - ' + tempList[i].Description,
          value: tempList[i].ServiceCodeID,
          fullobj: tempList[i]
        });
      }
    }
  }

  getCompanyGlobalSetting() {
    let parent = this;
    parent.companyGlobalSetting = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    if (parent.companyGlobalSetting) {
      parent.companyGlobalSetting.isWorkLocationRequired = parent.companyGlobalSetting
        .isWorkLocationRequired
        ? parent.companyGlobalSetting.isWorkLocationRequired
        : false;
      parent.tse.Approved = parent.companyGlobalSetting.AutoApproveSlips;
      parent.tse.TimeMemoRequired = parent.companyGlobalSetting.TimeMemoRequired
        ? parent.companyGlobalSetting.TimeMemoRequired
        : false;
      if (parent && parent.tse && parent.tse.TimeMemoRequired) {
        parent.setRequired();
      }
    }
  }

  getWorkLocations() {
    let _this = this;
    if (_this.workLocationsList.length > 1) {
      return false;
    }
    let tempList = _this.encrDecSrvc.getObject(AppConstants.workLocations);
    _this.workLocationsList = [{ label: 'Select Location', value: null }];
    for (let index = 0; index < tempList.length; index++) {
      const element = tempList[index];
      element['label'] = element.ZipCode;
      element['value'] = element.WorkLocationID;
      _this.workLocationsList.push(element);
    }
  }

  setRequired() {
    if (
      this.timeEntryMainForm &&
      this.timeEntryMainForm.form &&
      this.timeEntryMainForm.form.controls &&
      this.timeEntryMainForm.form.controls.memo
    ) {
      this.timeEntryMainForm.form.controls.memo.setValidators([Validators.required]);
      this.timeEntryMainForm.form.controls.memo.updateValueAndValidity();
    }
  }

  closeTEModal(doNotRefresh?) {
    this.isFlatFeeText = 'Flat Fee';
    this.closeTimeEntryForm(doNotRefresh);
  }

  handleEngagementSelectClick(event, isReset = false) {
    if (isReset) this.tse['BudgetsID'] = null;

    this.selProjectHeaderID = null;
    this.selProjectDetailsID = null;

    this.selClient['MarkSlipsBilled'] = this.selClient['MarkSlipsBilled']
      ? this.selClient['MarkSlipsBilled']
      : false;
    this.selClient['Billable'] = this.selClient['Billable'] ? this.selClient['Billable'] : false;

    let tempEng = this.projectsList.filter(proj => event.value == proj.value)[0];
    this.tse.ProjectMasterID = tempEng.value;

    tempEng['isBillable'] = tempEng['isBillable'] ? tempEng['isBillable'] : false;

    this.tse.Billable = true;
    if (
      this.selClient['MarkSlipsBilled'] ||
      !this.selClient['Billable'] ||
      !tempEng['isBillable']
    ) {
      this.tse.Billable = false;
    }

    this.getBudgetYears();
    Promise.resolve(this.getClientProjects(this.selClient['ClientID'], false, true));

    const dropdown = document?.querySelector('#itemProjectSelected');
    if (dropdown) {
        const selectedLabel = tempEng.label;
        const dropdownLabel = dropdown.querySelector('.p-dropdown-label');
        if (dropdownLabel) {
            dropdownLabel.textContent = selectedLabel;
        }
    }
}

  applyBillableTime(isChangeEvent, isInitialEdit: boolean = false) {
    if (this.selClient) {
      //this.tse.Billable = false
      let activity = this.activityList.filter(item => {
        return item?.fullobj?.ServiceCodeID == this.tse.ServiceCodeID;
      });

      this.selClient['MarkSlipsBilled'] = this.selClient['MarkSlipsBilled']
        ? this.selClient['MarkSlipsBilled']
        : false;
      this.selClient['Billable'] = this.selClient['Billable'] ? this.selClient['Billable'] : false;
      let engagementBillabeValue;
      let projTempList = this.projectsList.filter(
        item => item.value == this.tse.ProjectMasterID
      )[0];
      if (this.projectsList.length > 0 && projTempList) {
        engagementBillabeValue = projTempList['isBillable'];
      } else {
        engagementBillabeValue = this.selClient['Billable'];
      }
      engagementBillabeValue = engagementBillabeValue ? engagementBillabeValue : false;

      if (!isInitialEdit) {
        if (
          this.selClient['MarkSlipsBilled'] == true ||
          this.selClient['Billable'] == false ||
          engagementBillabeValue == false
        ) {
          this.tse.Billable = false;
        } else {
          if (isChangeEvent && activity && activity[0] && activity[0]['fullobj']) {
            this.tse.Billable = activity[0]['fullobj'].Billable
              ? activity[0]['fullobj'].Billable
              : false;
          }
        }
      }
    }
  }

  handleActivitySelectClick(
    event,
    isDropDownSelected,
    isTimerFlow?: any,
    isInitialEdit: boolean = false
  ) {
    if (event.value) {
      let activityObj = this.activityList.filter(item => {
        return item.fullobj.ServiceCodeID == event.value;
      });
      if (!this.currentActivityId) {
        this.currentActivityId = event.value;
      }

      if (activityObj && activityObj.length > 0) {
        let activity = activityObj[0]['fullobj'];
        this.tse.ServiceCode = activity.ServiceCode;
        this.tse.ServiceCodeID = activity.ServiceCodeID;
        this.tse.ServiceDesc = activity.Description;
        this.tse.Description = activity.Description;
        this.tse.WorkCodeID = activity.WorkCodeID;
        this.safeMemoUpdate = this.isEditTS ? this.tse.Memo : activity.DefaultMemo;
        this.tse.Memo = this.isEditTS ? this.tse.Memo : activity.DefaultMemo;
        this.tse.Memo = this.mangoUtils.replaceCaretTemplate(this.tse.Memo);
      }

      if (this.currentActivityId != event.value) {
        let activityObj = this.activityList.filter(item => {
          return item.fullobj.ServiceCodeID == event.value;
        });
        if (activityObj && activityObj.length > 0) {
          let activity = activityObj[0]['fullobj'];
          if (this.tse.Memo != activity.DefaultMemo) {
            this.safeMemoUpdate = activity.DefaultMemo;
            this.tse.Memo = this.safeMemoUpdate;
          }
        }
      }

      // if (!this.isEditTS || isDropDownSelected) {
      //   let valueOfBillable = (activity.Billable == null) ? true : activity.Billable
      //   this.isBillable = valueOfBillable;
      //   this.tse.Billable = (this.selClient && this.selClient['MarkSlipsBilled'] == true) ? false : valueOfBillable;
      // }

      if (isDropDownSelected || isTimerFlow) {
        setTimeout(() => {
          if (event.hasOwnProperty('originalEvent') || isTimerFlow) {
            this.applyBillableTime(true, isInitialEdit);
          } else {
            this.applyBillableTime(false, isInitialEdit);
          }

          this.getBillingRateForUser();
        }, 500);
      }

      if (this.billingRateChk) {
        return false;
      }
    }

    //
    if (this.selClient && this.selClient['CompanyMangoLocationID']) {
      this.getCompanyLocation(this.selClient['CompanyMangoLocationID']);
    } else {
      this.calculateSalesTax();
    }

    // this.getBillingRateForUser();
  }

  getCompanyLocation(id) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocation(id).subscribe(
      (data: any) => {
        this.companyLocationObj = data;
        this.mangoAPISrvc.showLoader(false);
        this.calculateSalesTax();
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  closeTimeEntryForm(doNotRefresh?) {
    let url = this.router.url;
    const urls = [
      '/billing-invoicing/manualInvoice',
      '/client/timeslips',
      '/engagements/UnbilledTime',
      '/time-expense/list',
      '/dashboard'
    ];
    if ((this.isFormValid || doNotRefresh) && urls.includes(url)) {
      this.sharedSrvc.setTimeEntryDialogData(this.tse);
    }
    this.isMemoChanged = false;
    if (!doNotRefresh) this.closeTimeEntryModal.emit(this.tse.Ddate);
    this.tse = {};
    this.isFlatFeeText = 'Flat Fee';
    this.timeEntryMainForm.reset();
    this.displayTimeEntryModal = false;
    // this.shared.closeTimeEntryModal)()
    this.sharedSrvc.sharedCompVisibility.timesEntry = false;
  }

  isFlatText(obj) {
    if (this.isFlatFeeText == 'Flat Fee') {
      this.isMemoChanged = false;
      obj.StandardAmount = 0;
      this.isFlatFeeText = 'Hourly';
    } else {
      this.isMemoChanged = this.timeEntryMainForm.valid;
      obj.StandardAmount = this.tse.BillingRate * this.tse.TotalTime;
      this.isFlatFeeText = 'Flat Fee';
    }
    this.elementRef.nativeElement.focus();
    this.formateText();
    this.validateForm();
  }

  deleteTimer() {
    this.timerTask.deleted = true;
    this.sharedSrvc.timerTask = this.timerTask;
  }

  changeActualTime(val) {
    this.tse.TotalTime = this.tse.ElaspedTime.replace(/^0+/, '');
    this.calculateBillableAmount();
  }

  calculateDifferenceInSeconds(dateTimeString1, dateTimeString2) {
    // Convert string dates to moment objects
    const dateTime1 = moment(dateTimeString1);
    const dateTime2 = moment(dateTimeString2);

    // Calculate difference in seconds
    const differenceInMinutes = dateTime2.diff(dateTime1, 'seconds');

    return differenceInMinutes;
  }

  getEndTime() {
    const now = new Date();
    const dateTimeString2 = now.toISOString();

    const differenceInSeconds = this.calculateDifferenceInSeconds(
      this.openModalTime,
      dateTimeString2
    );
    //console.log(`La diferencia en segundos es: ${differenceInSeconds}`);

    const timeStartMoment = moment(this.tse.TimeStart);

    this.tse.TimeStop = now; //timeStartMoment.add(differenceInSeconds, 'seconds').toDate();

    this.calculateTimeDifference();
  }

  formateText(isChangeFromUI = false) {
    if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero)
      this.tse.StandardAmount = '0.00';
    else this.tse.StandardAmount = numeral(this.tse.StandardAmount).format('0,0.00');

    if (isChangeFromUI) {
      this.isBillableAmtChanged = true;
      this.tse['BilledAmount'] = this.tse.StandardAmount;
    }
  }

  changeStartTime() {
    if (!this.tse.TimeStart) {
      //this.tse.TimeStart = new Date();
      this.tse.TimeStart = moment.utc().toDate();
    } else {
      this.tse.TimeStart = moment.utc(this.tse.TimeStart).toDate();
    }

    if (this.tse.TimeStop) {
      this.calculateTimeDifference();
    }
  }

  changeEndTime() {
    if (!this.tse.TimeStop) {
      return;
    }

    // Convert the end time to UTC and set the seconds and milliseconds to 0
    this.tse.TimeStop = moment.utc(this.tse.TimeStop).seconds(0).milliseconds(0).toDate();

    // Ensure the date part matches the start time
    const startDate = moment.utc(this.tse.TimeStart);
    const endDate = moment
      .utc(this.tse.TimeStop)
      .year(startDate.year())
      .month(startDate.month())
      .date(startDate.date())
      .toDate();

    this.tse.TimeStop = endDate;

    this.calculateTimeDifference();
  }

  extractTimeAndConvertToHours(dateString) {
    // Dividir la cadena en partes utilizando espacios como delimitadores
    const parts = dateString.split(' ');
    // La parte de la hora está en el quinto elemento (índice 4)
    const timePart = parts[4];
    // Dividir la parte de la hora en horas, minutos y segundos
    const [hoursStr, minutesStr, secondsStr] = timePart.split(':');
    // Convertir horas, minutos y segundos a números
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);
    // Convertir todo a horas
    //const totalHours = hours + (minutes / 60) + (seconds / 3600);
    //Convertir a Segundos
    //const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    // Convertir todo a milisegundos
    const totalMilliseconds = hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000;
    return totalMilliseconds;
  }

  calculateTimeDifference() {
    const timeStartUTC = this.tse.TimeStart.toString();
    const timeStopUTC = this.tse.TimeStop.toString();
    //console.log(this.extractTimeAndConvertToHours(timeStartUTC));
    //console.log(this.extractTimeAndConvertToHours(timeStopUTC));
    let timeDiff =
      this.extractTimeAndConvertToHours(timeStopUTC) -
      this.extractTimeAndConvertToHours(timeStartUTC);
    //console.log(timeDiff);

    if (isNaN(timeDiff) || timeDiff < 0) {
      if (!this.invalidTimeDifferenceTimeout) {
        this.invalidTimeDifferenceTimeout = setTimeout(() => {
          this.mangoAPISrvc.notify(
            'error',
            'Warning!',
            'Invalid time difference. Start time should be before end time!'
          );
          clearTimeout(this.invalidTimeDifferenceTimeout);
          this.invalidTimeDifferenceTimeout = undefined;
        }, 500);
      }
      this.invalidTimeDifferenceStatus = true;
      return;
    }

    this.invalidTimeDifferenceStatus = false;

    let hours = Math.floor(timeDiff / (1000 * 60 * 60));
    let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    const globalMinute = this.companyGlobalSetting.BillingIncrements
      ? parseInt(this.companyGlobalSetting.BillingIncrements.split(' ')[0])
      : 0;

    if (globalMinute > 0) {
      const realMinutes = hours * 60 + minutes;
      const roundedMinutes = Math.ceil(realMinutes / globalMinute) * globalMinute;
      hours = Math.floor(roundedMinutes / 60);
      minutes = roundedMinutes % 60;
    }

    const elaspedTime = this.formateNumber(Math.round((timeDiff / (1000 * 60 * 60)) * 100) / 100);
    const totalTime = this.convertHoursToNumericHours(
      `${hours}:${minutes < 10 ? '0' + minutes : minutes}`
    );

    this.tse.ElaspedTime = elaspedTime;
    this.tse.TotalTime = this.companyGlobalSetting.BillingIncrements ? totalTime : elaspedTime;
    this.tse.actualTimeTooltip = `${hours} hours, ${minutes} minutes`;
    this.billableTimeTooltip = this.tse.actualTimeTooltip;

    this.calculateBillableAmount();
  }

  formatActuallTime() {
    this.tse.ElaspedTime = numeral(numeral(this.tse.ElaspedTime).value()).format('0.00');
    if (
      !this.tse.ElaspedTime ||
      this.tse.ElaspedTime.toString().indexOf('.') < 0 ||
      this.tse.ElaspedTime.toString().split('.').length > 2
    ) {
      this.tse.ElaspedTime = 0.0;
    }
    this.tse.ElaspedTime = this.formateNumber(this.tse.ElaspedTime);
    this.tse.TotalTime = this.tse.ElaspedTime;
    if (this.companyGlobalSetting.BillingIncrements) {
      let globalMinute = 0;
      globalMinute = parseInt(this.companyGlobalSetting.BillingIncrements.split(' ')[0]);

      let realmin = (Math.round((numeral(this.tse.ElaspedTime).value() * 60) % 60) * 100) / 100;
      let hours = Math.floor((numeral(this.tse.ElaspedTime).value() * 60) / 60);

      if (realmin <= globalMinute)
        if (realmin == 0) realmin = <any>'00';
        else realmin = globalMinute;
      else {
        let diffGlobalMin = 0;
        // infinity issue
        if (globalMinute != 0) {
          diffGlobalMin = Math.floor(realmin / globalMinute);
        }
        if (realmin % globalMinute === 0) realmin = diffGlobalMin * globalMinute;
        else realmin = (diffGlobalMin + 1) * globalMinute;
      }

      if (realmin >= 60) {
        hours = hours + Math.floor(realmin / 60);
        realmin = realmin % 60;
        if (realmin < 9) realmin = <any>'0' + realmin;
      }

      this.tse.TotalTime = this.convertHoursToNumericHours(hours + ':' + realmin);
    }

    if (!this.tse.TotalTime) {
      return;
    }
    this.billableTimeTooltip =
      this.tse.TotalTime.toString().split('.')[0] +
      ' hours, ' +
      Math.round(parseInt(this.tse.TotalTime.toString().split('.')[1]) * 0.6) +
      ' minutes';
    this.actualTimeTooltip =
      Math.round(this.tse.ElaspedTime.toString().split('.')[0]) +
      ' hours, ' +
      Math.round(parseInt(this.tse.ElaspedTime.toString().split('.')[1]) * 0.6) +
      ' minutes';
    this.calculateBillableAmount();
  }

  calculateBillableAmount() {
    if (this.tse.BillingRate) this.tse.BillingRate = this.tse.BillingRate.replace('$', '');

    if (!this.tse.TotalTime) {
      return;
    }

    this.tse.TotalTime = numeral(numeral(this.tse.TotalTime).value()).format('0.00');
    if (
      !this.tse.TotalTime ||
      this.tse.TotalTime.toString().indexOf('.') < 0 ||
      this.tse.TotalTime.toString().split('.').length > 2
    ) {
      this.tse.TotalTime = 0.0;
    }

    let splitTime = this.tse.TotalTime.split('.');
    let splitMinutes: any = parseInt(splitTime[1]) * 0.6;
    splitMinutes = splitMinutes < 10 ? '0' + splitMinutes + ' minutes' : splitMinutes + ' minutes';
    this.billableTimeTooltip = splitTime[0] + ' hours, ' + splitMinutes;
    this.tse.TotalTime = this.formateNumber(this.tse.TotalTime);

    if (this.tse.TotalTime && this.tse.BillingRate) {
      if (this.tse.BillingRate.replace('$', '') == 0)
        if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero)
          this.tse.StandardAmount = '0.00';
        else this.tse.StandardAmount = this.tse.BillingRate;
      else if (this.tse.isFlatFee) {
      } else {
        if (this.selClient?.MarkSlipsBilled && this.timeexp?.isAdminTimeZero)
          this.tse.StandardAmount = '0.00';
        else
          this.tse.StandardAmount = numeral(
            numeral(this.tse.TotalTime).value() * numeral(this.tse.BillingRate).value()
          ).format('0,0.00');
      }
    }
  }

  replaceCaret(value, desc, type?) {
    if (type) {
      return;
    }

    if (desc === 'Memo') this.tse.Memo = this.mangoUtils.replaceCaretTemplate(this.tse.Memo);
    else this.tse.PrivateMemo = this.mangoUtils.replaceCaretTemplate(this.tse.PrivateMemo);
  }

  onChangesDetection(event: any): void {
    this.isMemoChanged = true;
    this.validateForm();
  }

  convertHoursToNumericHours(time) {
    let arrTime = time.split(':');
    let minute: any = Math.round((arrTime[1] * 100) / 60);
    if (minute < 9) minute = '0' + minute;
    return arrTime[0] + '.' + minute;
  }

  formatBillingRate(name, prm) {
    if (prm) {
      if (!name) {
        return;
      }
      let enteredValue = numeral(this.tse.BillingRate).value();
      enteredValue = enteredValue ? enteredValue : 0;
      this.tse.BillingRate = '$' + numeral(enteredValue).format('0,0.00');
    }
  }

  formateNumber(name) {
    name = name.toString();
    if (name != undefined && name != '') {
      if (name.includes('.')) {
        var index = name.indexOf('.');
        if (name.substr(index + 1).length == 0) {
          name = name + '00';
        } else if (name.substr(index + 1).length == 1) {
          name = name + '0';
        }
      } else {
        name = name + '.00';
      }
      return name;
    }
  }

  removeOffset(date: Date) {
    var d = new Date();
    let offset = d.getTimezoneOffset();
    let time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  addOffset(date: Date) {
    var d = new Date();
    let offset = d.getTimezoneOffset();
    let time = date.getTime() + offset * 60 * 1000;
    return new Date(time);
  }

  replaceShortcuts(value, isMemo) {
    if (!value) {
      return;
    }
    let valueArr = value.split(' ');
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        let shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut['Inactive']) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    if (isMemo) {
      this.tse.Memo = valueArr.join(' ');
    } else {
      this.tse.PrivateMemo = valueArr.join(' ');
      this.isMemoChanged = true;
      this.validateForm();
    }
  }

  getBillingRateForUser() {
    let self = this;
    if (!self.tse.StaffID || !self.tse.ClientID || !self.tse.ServiceCodeID)
      console.log('not all parameters are selected');
    else {
      self.mangoAPISrvc.showLoader(true);
      self.isBillingRateFetching = true;
      self.mangoAPISrvc
        .getBillingRateFromRateTemplate(self.tse.ClientID, self.tse.ServiceCodeID, self.tse.StaffID)
        .subscribe(
          function (data: any) {
            let enteredValue = numeral(data.BillingRate).value();
            self.tse.BillingRate = numeral(enteredValue).format('0,0.00');
            self.calculateBillableAmount();
            self.isBillingRateFetching = false;
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.showLoader(false);
          },
          error => {
            self.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
            self.isBillingRateFetching = false;
            self.tse.BillingRate.BillingRate = 0;
            self.mangoAPISrvc.showLoader(false);
          }
        );
    }
  }

  handleClientDropdownClick(event) {
    this.filteredClients = [];
    //mimic remote call
    setTimeout(() => {
      this.filteredClients = this.clientList;
    }, 1);
  }

  private _markFormPristine(form: FormGroup | NgForm): void {
    Object.keys(form.controls).forEach(control => {
      form.controls[control].markAsPristine();
    });
    this.handleActivitySelectClick({}, false, false, false);
  }

  selectEditStaff(event, flowType) {
    let data = event.value;
    this.tse.StaffID = data.staffID;
    this.tse.StaffName = data.staffName;

    let self = this;
    self.staffDetails = {};
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getUserInfo(self.tse.StaffID).subscribe(
      data => {
        self.staffDetails = data;
        self.tse.StaffDeptID = self.staffDetails.StaffDeptID;
        self.tse.StaffPayRate = Number(self.staffDetails.AverageCostPerHour);
        self.mangoAPISrvc.showLoader(false);
      },
      error => {
        self.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        self.mangoAPISrvc.showLoader(false);
      }
    );
    if (this.billingRateChk || flowType) {
      return false;
    }
    self.getBillingRateForUser();
  }

  // adding new engagent
  addEngagementPopUp() {
    let parent = this;
    if (!parent.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: parent._translate.instant('client.engagement.permission')
      });
      return;
    }

    var obj = {};
    obj['isClientFlow'] = true;
    obj['isEditFlow'] = true;
    obj['ClientID'] = this.selClient['ClientID'];
    obj['ClientName'] = this.selClient['ClientName'];
    obj['AssignedTo'] = this.staffID;
    parent.sharedSrvc.openEngagementDialog(obj);
  }

  addActivity() {
    let obj = {
      isEditFlow: false,
      activity: {
        ServiceCode: '',
        Description: '',
        Tax1: false,
        Tax2: false,
        Billable: true,
        BillRate: '0.00',
        Inactive: false
      }
    };
    this.sharedSrvc.openActivityDialog(obj);
  }

  onEndTimeFocus() {
    if (!this.tse?.TimeStop) {
      const now = new Date();
      this.tse.TimeStop = now;
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    let dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    let lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    let monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)
    ).getMonth();
    let lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
      this.tse['isSystemLocked'] = true;
    } else {
      this.tse['isSystemLocked'] = false;
      return;
    }
  }

  validateWorkDateTimeout: any;
  validateWorkDate() {
    if (typeof this.validateWorkDateTimeout != 'undefined') {
      clearTimeout(this.validateWorkDateTimeout);
      this.validateWorkDateTimeout = undefined;
    }

    this.validateWorkDateTimeout = setTimeout(() => {
      const workDate = this.tse.displayDate;

      const dateToday = new Date();

      if (this.mangoUtils.dateDiff(workDate, dateToday, 'months') > 2) {
        Swal.fire({
          icon: 'warning',

          title: `${this._translate.instant('Warning')}?`,
          text: `${this._translate.instant('two-month-gap-date-entry-warning')}!`,

          confirmButtonText: 'OK',

          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
      }

      clearTimeout(this.validateWorkDateTimeout);
      this.validateWorkDateTimeout = undefined;
    }, 500);
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38);
  }

  focusOnItem(targetItem: any) {
    setTimeout(() => {
      const dropdownPanel = document.querySelector('.p-autocomplete-panel');
      if (!dropdownPanel) return;
      const itemElements = dropdownPanel.querySelectorAll('.p-autocomplete-item');
      itemElements.forEach((element: HTMLElement) => {
        if (element.textContent?.trim() === targetItem.ClientName) {
          element.scrollIntoView({ behavior: 'instant', block: 'nearest' }); 
          element.focus(); 
        }
      });
    });
  }
  filterClientsDebounced(event) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.filterClients(event);
    }, 300);
  }
  handleClientKeyPress(event) {  
    if(!this.selClient) {
      this.currentFocusedIndex = -1;
    }
    const key = event.key || event.code || event.which;
    if (key === 'ArrowDown') {
      if(this.currentFocusedIndex==-1){
        this.currentFocusedIndex=0;
      }else if(this.currentFocusedIndex<this.autoComplete.suggestions.length-1){
        this.currentFocusedIndex++;
      }else if(this.currentFocusedIndex==this.autoComplete.suggestions.length-1){
        this.currentFocusedIndex=0;
      }
    }else if (key === 'ArrowUp') {
      if(this.currentFocusedIndex==0){
        this.currentFocusedIndex=this.autoComplete.suggestions.length-1;
      }else if(this.currentFocusedIndex>0){
        this.currentFocusedIndex--;
      }
    }else if(key === 'Enter'){
      this.handleSelectClick(this.selClient, true);
    }
    else if(key === 'Backspace') {
      return;
    }
    if (['ArrowDown', 'ArrowUp'].includes(key) && this.currentFocusedIndex >= 0) {
      this.selClient = this.autoComplete.suggestions[this.currentFocusedIndex];
      this.focusOnItem(this.selClient);
    }
   
  }
  handleEngagementKeyPress( event ){
    const key = event.key || event.code || event.which;
    console.log(key);
  } 
}
