<div class="project-list-projects">
  <div class="layout-content">
    <div class="p-grid">
      <div class="p-col-12 p-pt-0">
        <div class="card p-pt-0 p-mt-1 p-mx-3 p-no-bottom-padding">
          <div class="p-col-12 p-pt-0 p-no-bottom-padding">
            <p-table
              #dt
              id="project-table"
              dataKey="ProjectHeaderID"
              styleClass="p-datatable-hoverable-rows p-datatable-sm custom-project-table"
              sortMode="multiple"
              sortField="ClientName"
              scrollDirection="both"
              [scrollHeight]="scrollHeight"
              [value]="clientsDataSource"
              [responsive]="true"
              [reorderableColumns]="true"
              [columns]="selectedColumns"
              [filterDelay]="0"
              [rows]="selectedRowsPerPage"
              [scrollable]="true"
              [globalFilterFields]="globalFilterColumns"
              [rowHover]="true"
              [sortOrder]="0"
              [multiSortMeta]="sortField"
              [(selection)]="selectedClientsItems"
              (onSort)="onSort( $event )"
              (onColReorder)="onColReorder( $event )"
              (onFilter)="onFilter( $event )"
              [resizableColumns]="true"
              columnResizeMode="expand"
              scrollWidth="100%"
              styleClass="tdLessPadding">
              <ng-template pTemplate="caption">
                <div class="table-header tdLessPadding">
                  <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input
                      #searchValue
                      pInputText
                      type="text"
                      placeholder="{{ 'Search-by' | translate }}"
                      (input)="onSearchInput( $event );" />
                  </span>
                  <span *ngIf="(( projectListSearchQuery .length> 0 ))">
                    <button
                      pButton
                      pRipple
                      class="p-button-rounded p-button-danger p-button-text"
                      type="button"
                      icon="pi pi-times"
                      tooltipPosition="top"
                      pTooltip="{{ 'clear' | translate }}"
                      (click)="clearSearchFilter( );"></button>
                  </span>
                  <p-message
                    *ngIf="(( filteredItemsSize > -1 ))"
                    severity="success"
                    text="{{ filteredItemsSize }} {{ 'records_found' | translate }}."
                    styleClass="p-ml-2">
                  </p-message>
                  <span class="pull-right">
                    <!-- <p-splitButton label="{{ 'mgmt-delete' | translate }}" *ngIf="selectedClientsItems.length > 0" [menuStyleClass]="'duplicate-menu'" (onClick)="deleteItems()" class="p-mr-2 p-text-left" icon="fal fa-trash-alt" [model]="buttonsDeleteList" [disabled]="selectedClientsItems.length == 0"> </p-splitButton> -->
                    <p-multiSelect
                      [options]="cols"
                      [(ngModel)]="selectedColumns"
                      optionLabel="header"
                      class="p-mr-2"
                      selectedItemsLabel="{0} columns selected"
                      (onChange)="handleColumns($event)"
                      [style]="{minWidth: '200px'}"
                      placeholder="Choose Columns"
                      >
                    </p-multiSelect>
                    <span *ngIf="selectedClientsItems.length > 0">
                      <label class="label-text p-mr-2 p-mb-2" style="display: inline-block">
                        {{ 'pm.batch_options' | translate }}
                      </label>
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-chevron-down"
                        class="p-mr-2"
                        (click)="menu.toggle($event)"></button>
                    </span>
                    <p-menu
                      appendTo="body"
                      #menu
                      [popup]="true"
                      styleClass="button-list"
                      [model]="buttonsDeleteList">
                    </p-menu>
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-file-o"
                      (click)="exportCSVFile('current')"
                      class="p-mr-2"
                      pTooltip="Export CSV of the current page"
                      tooltipPosition="bottom"
                      *ngIf="clientsDataSource?.length > 0"></button>
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-copy"
                      (click)="exportCSVFile('all')"
                      class="p-mr-2"
                      pTooltip="Export to CSV of all projects"
                      tooltipPosition="bottom"
                      *ngIf="clientsDataSource?.length > 0"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" *ngIf="clientsDataSource.length > 0">
                    </button> -->
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-file-pdf"
                      (click)="exportPdf('current')"
                      class="p-button-warning p-mr-2"
                      pTooltip="Export PDF of the current page"
                      tooltipPosition="bottom"
                      *ngIf="clientsDataSource?.length > 0"></button>
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-copy"
                      (click)="exportPdf('all')"
                      class="p-button-warning p-mr-2"
                      pTooltip="Export to PDF of all projects"
                      tooltipPosition="bottom"
                      *ngIf="clientsDataSource?.length > 0"></button>
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="fal fa-head-side-brain"
                      (click)="openModalBulkBudgetForProjects()"
                      class="p-button-alert p-mr-2"
                      pTooltip="Create Budgets for Capacity Planning"
                      tooltipPosition="bottom"
                      *ngIf="clientsDataSource?.length > 0 && companyTags.isCapacityPlanningEnabled"></button>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col
                    *ngFor="let col of ['header', 'ClientName', 'TemplateName', 'Complete']"
                    [style.width]="col.width" />
                  <col *ngFor="let col of columns" [style.width]="col.width" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th class="width-2p p-text-center">
                    <p-tableHeaderCheckbox> </p-tableHeaderCheckbox>
                  </th>
                  <!-- <th class="p-text-left"pSortableColumn="ProjectHeaderID"> Reference <p-sortIcon field="ProjectHeaderID"> </p-sortIcon> </th> -->
                  <th
                    class="p-text-left"
                    class="width-10p"
                    pSortableColumn="ClientName"
                    pResizableColumn>
                    {{ 'client' | translate }} <p-sortIcon field="ClientName"> </p-sortIcon>
                  </th>
                  <th
                    class="p-text-left"
                    class="width-20p"
                    pSortableColumn="TemplateName"
                    pResizableColumn>
                    {{ 'project' | translate }} <p-sortIcon field="TemplateName"> </p-sortIcon>
                  </th>
                  <th class="p-text-center width-8p" pResizableColumn>
                    {{ 'complete' | translate }}
                  </th>

                  <th
                  *ngIf="isColumnSelectedTaskProgress"
                  class="p-text-left width-8p"
                  pSortableColumn="TaskProgress"
                  pResizableColumn>
                  {{ 'Tasks' | translate }}
                  <p-sortIcon field="TaskProgress"></p-sortIcon>
                </th>

                <th
                  *ngIf="isColumnSelectedProjectMemo"
                  class="p-text-left width-8p"
                  pSortableColumn="ProjectMemo"
                  pResizableColumn>
                  {{ 'memo' | translate }}
                  <p-sortIcon field="ProjectMemo"></p-sortIcon>
                </th>

                <th
                  *ngIf="isColumnSelectedTotalNotes"
                  class="p-text-left width-8p"
                  pSortableColumn="TotalNotes"
                  pResizableColumn>
                  {{ 'notes' | translate }}
                  <p-sortIcon field="TotalNotes"></p-sortIcon>
                </th>

                  <th
                    *ngIf="isColumnSelectedDateReceived"
                    class="p-text-left width-8p"
                    pSortableColumn="DateReceived"
                    pResizableColumn>
                    {{ 'In-Date' | translate }}
                    <p-sortIcon field="DateReceived"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedFinishDate"
                    class="p-text-left width-8p"
                    pSortableColumn="FinishDate"
                    pResizableColumn>
                    {{ 'Out-Date' | translate }}
                    <p-sortIcon field="FinishDate"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedSortableDueDate"
                    class="p-text-left width-8p"
                    pSortableColumn="SortableDueDate"
                    pResizableColumn>
                    {{ 'due-date' | translate }}
                    <p-sortIcon field="SortableDueDate"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedStatus"
                    class="p-text-left width-8p"
                    pSortableColumn="Status"
                    pResizableColumn>
                    {{ 'status' | translate }}
                    <p-sortIcon field="Status"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedGroupDescriptionValues"
                    class="p-text-left width-8p"
                    pSortableColumn="GroupDescriptionValues"
                    pResizableColumn>
                    {{ 'Client_Groups' | translate }}
                    <p-sortIcon field="GroupDescriptionValues"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedRepeat"
                    class="p-text-left width-8p"
                    pSortableColumn="Repeat"
                    pResizableColumn>
                    {{ 'repeat' | translate }}
                    <p-sortIcon field="Repeat"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedTaskDescription"
                    class="p-text-left width-8p"
                    pSortableColumn="TaskDescription"
                    pResizableColumn>
                    {{ 'next-task' | translate }}
                    <p-sortIcon field="TaskDescription"></p-sortIcon>
                  </th>
                  <th
                    *ngIf="isColumnSelectedStaffNames"
                    class="p-text-left width-8p"
                    pSortableColumn="StaffNames"
                    pResizableColumn>
                    {{ 'Assigned' | translate }}
                    <p-sortIcon field="StaffNames"></p-sortIcon>
                  </th>

                  <th
                  *ngIf="isColumnSelectedCompletionDate"
                  class="p-text-left width-8p"
                  pSortableColumn="CompletionDate"
                  pResizableColumn>
                  {{ 'Completion-Date' | translate }}
                  <p-sortIcon field="CompletionDate"></p-sortIcon>
                  </th>


                  <th
                  *ngIf="isColumnSelectedusername"
                  class="p-text-left width-8p"
                  pSortableColumn="username"
                  pResizableColumn>
                  {{ 'manager' | translate }}
                  <p-sortIcon field="username"></p-sortIcon>
                  </th>

                  <th
                    *ngIf="isColumnSelectedmanagername"
                    class="p-text-left width-8p"
                    pSortableColumn="managername"
                    pResizableColumn>
                    {{ 'partner' | translate }}
                    <p-sortIcon field="managername"></p-sortIcon>
                  </th>
                  <th
                  *ngIf="isColumnSelectedCompanyLocation"
                  class="p-text-left width-8p"
                  pSortableColumn="CompanyLocation"
                  pResizableColumn>
                  {{ 'company_location' | translate }}
                  <p-sortIcon field="CompanyLocation"></p-sortIcon>
                  </th>

                  <th
                  *ngIf="isColumnSelectedEngagement"
                  class="p-text-left width-8p"
                  pSortableColumn="EngagementName"
                  pResizableColumn>
                  {{ 'engagement' | translate }}
                  <p-sortIcon field="EngagementName"></p-sortIcon>
                  </th>

                  <th
                  *ngIf="isColumnSelectedTags"
                  class="p-text-left width-8p"
                  pSortableColumn="Tags"
                  pResizableColumn>
                  {{ 'Tags' | translate }}
                  <p-sortIcon field="Tags"></p-sortIcon>
                </th>
                  
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
                let-rowIndex="rowIndex">
                <tr>
                  <td class="width-2p p-text-center">
                    <p-tableCheckbox
                      #checkboxTableP
                      [value]="rowData"
                      [index]="rowIndex"
                      (click)="mangoUtils.checkboxTable(checkboxTableP, $event, dt)">
                    </p-tableCheckbox>
                  </td>
                  <!-- <td class="p-text-left p-text-nowrap"pTooltip="{{ rowData.ClientName }}"tooltipStyleClass="tooltip-no-wrap"tooltipPosition="top"showDelay="800"> <span>
{{ rowData.ProjectHeaderID }} </span> </td> -->
                  <td
                    class="width-10p p-text-left p-text-nowrap project-link cursor-hand"
                    pTooltip="{{ rowData.ClientName }}"
                    showDelay="800"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <span
                      class="project-link cursor-hand"
                      routerLink="/project-management/{{ rowData['ClientID'] }}/clientDetails">
                      {{ rowData.ClientName }}
                    </span>
                  </td>
                  <td
                    class="width-20p p-text-left p-text-nowrap p-text-truncate project-link cursor-hand"
                    pTooltip="{{ rowData.TemplateName }}"
                    showDelay="800"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <span
                      class="project-link cursor-hand"
                      (click)="redirectToProjectDetails( rowData );">
                      {{ rowData.TemplateName }}
                    </span>
                  </td>
                  <td class="p-text-center">
                    <ejs-radiobutton
                      *ngIf="rowData.Status !== 'Completed'"
                      label=""
                      name="groupname"
                      [value]="rowData.ProjectHeaderID"
                      (change)="radioClicked(rowData, selectedClientsItems.length, false)">
                    </ejs-radiobutton>
                    <span *ngIf="rowData.Status === 'Completed'"> &nbsp; </span>
                  </td>
                  


                  <td *ngIf="isColumnSelectedTaskProgress" class="p-text-left p-text-truncate">
                    <span
                      *ngIf="rowData.TasksCompleted >= 0"
                      class="project-link cursor-hand"
                      (click)="openTasksSideBar(rowData);">
                      {{ rowData.TasksCompleted }} / {{ rowData.TotalTasks }}
                    </span>
                  </td>

                  <td  *ngIf="isColumnSelectedProjectMemo" class="p-text-left p-text-truncate" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" showDelay="800">
                    <span *ngIf="rowData.ProjectMemo" pTooltip="{{ rowData.ProjectMemo }}">
                      <i class="fa fa-sticky-note ic-orange cursor-hand"> </i>
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedTotalNotes" class="p-text-left p-text-truncate">
                    <span *ngIf="rowData.TotalNotes > 0" class="notes">
                      {{ rowData.TotalNotes }}
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedDateReceived" class="p-text-left p-text-truncate">
                    <span *ngIf="rowData.DateReceived" pTooltip="{{ rowData.DateReceived | date:'MM/dd/yyyy' }}">
                      {{ rowData.DateReceived | date:'MM/dd' }}
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedFinishDate" class="p-text-left p-text-truncate">
                    <span *ngIf="rowData.FinishDate" pTooltip="{{ rowData.FinishDate | date:'MM/dd/yyyy' }}">
                      {{ rowData.FinishDate | date:'MM/dd' }}
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedSortableDueDate" class="p-text-left p-text-truncate">
                    <span
                      *ngIf="rowData.ExtensionDate || rowData.DueDate" tooltipStyleClass="tooltip-no-wrap" pTooltip="{{ rowData.NextDueDate?('Next Due Date: '+(rowData.NextDueDate | date:'MM/dd/yyyy')):'' }}"
                      [ngClass]="{ 'dueDate_violet': rowData.ExtensionDate, 'dueDate_critical': checkDueDate(rowData) }">
                      {{ (rowData.ExtensionDate || rowData.DueDate | date:'MM/dd/yyyy') || '-' }}
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedStatus" class="p-text-left p-text-truncate">
                    <div class="flag project-summary-{{ rowData.statusClass | lowercase }}">
                      {{ rowData.Status }}
                    </div>
                  </td>

                  <td *ngIf="isColumnSelectedGroupDescriptionValues" class="p-text-left p-text-truncate">
                    <span>{{ rowData.GroupDescriptionValues }}</span>
                  </td>

                  <td *ngIf="isColumnSelectedRepeat" class="p-text-left p-text-truncate">
                    <span>
                      {{ mangoUtils.capitalizeFirstLetter(rowData.Repeat) || 'None' }}
                    </span>
                  </td>

                  <td *ngIf="isColumnSelectedTaskDescription" class="p-text-left p-text-truncate" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" showDelay="800">
                    <span *ngIf="rowData.TaskDescription" pTooltip="{{ rowData.TaskDescription }}">
                      {{ rowData.TaskDescription }}
                    </span>
                  </td>
                  <td *ngIf="isColumnSelectedStaffNames" class="p-text-left p-text-truncate" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" showDelay="800">
                    <span *ngIf="rowData.StaffNames" pTooltip="{{ rowData.StaffNames }}">
                      {{ rowData.StaffNames }}
                    </span>
                  </td>             
              
                  <td *ngIf="isColumnSelectedCompletionDate" class="p-text-left p-text-truncate">
                    <span *ngIf="rowData.CompletionDate" pTooltip="{{ rowData.CompletionDate | date:'MM/dd/yyyy' }}">
                      {{ rowData.CompletionDate | date:'MM/dd/yyyy' }}
                    </span>
                  </td>            
               
                  <td *ngIf="isColumnSelectedusername" class="p-text-left p-text-truncate">
                    <span>{{ rowData.username ?? 'Unassigned' }}</span>
                  </td>
                  <td *ngIf="isColumnSelectedmanagername" class="p-text-left p-text-truncate">
                    <span>{{ rowData.managername ?? 'Unassigned'}}</span>
                  </td>
                  <td *ngIf="isColumnSelectedCompanyLocation" class="p-text-left p-text-truncate">
                    <span>{{ rowData.CompanyLocation }}</span>
                  </td>
                
                  <td *ngIf="isColumnSelectedEngagement" class="p-text-left p-text-truncate">
                    <span>{{ rowData.EngagementName }}</span>
                  </td>      
                  
                  <td class="p-text-left width-8p" *ngIf="isColumnSelectedTags">
                    <span *ngFor="let tag of rowData['Tags']">
                      <span
                        *ngIf="findTagByValue(tag,'label') != ''"
                        class="pTag p-text-nowrap"
                        pTooltip="{{ findTagByValue(tag,'label') }}"
                        showDelay="800"
                        tooltipStyleClass="tooltip-no-wrap"
                        tooltipPosition="top"
                        [style.background-color]="findTagByValue(tag,'color')">
                        &nbsp;
                      </span>
                    </span>
                  </td>
                 
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="(( clientsDataSource?.length > 0 ))">
                  <td class="p-text-center" colspan="1">
                    <span> {{ selectedClientsItems.length || 0 }} Selected </span>
                  </td>
                  <td colspan="11" class="p-text-right">
                    <span [innerHTML]="getSortValues()"> </span>
                  </td>
                  <td class="p-text-center" colspan="1">
                    <span> {{ clientsDataSource?.length || 0 }} Item/s </span>
                  </td>
                  <td class="p-text-center" colspan="1">
                    <span> {{ projectListTotalCount || 0 }} Total Items </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="14">
                    <p-message
                      severity="info"
                      text="{{ 'data_not_found' | translate }}."
                      styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div>
              <p-paginator
                #p
                *ngIf="projectListTotalCount > 0"
                [totalRecords]="projectListTotalCount"
                [rows]="selectedRowsPerPage"
                [rowsPerPageOptions]="rowsPerPageOptions"
                (onPageChange)="onTablePageChange($event)"
                styleClass="p-padding-y-0-5rem">
              </p-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-sidebar
    position="right"
    [(visible)]="displayFilterOption.dialogStatus"
    [style]="{'min-width':'30%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}"
    (onHide)="onCloseFilter( );">
    <div class="card" style="min-height: calc(100vh - 400px)">
      <div>
        <div class="p-field p-fluid">
          <label class="label-text"> {{ 'client' | translate }} </label>
          <p-autoComplete
            [(ngModel)]="selClient"
            [ngModelOptions]="{ standalone: true }"
            [suggestions]="filteredClients"
            autocomplete="off"
            (completeMethod)="filterClients($event)"
            field="ClientName"
            (onSelect)="handleSelectClick($event)"
            ngDefaultControl
            placeholder="{{ 'Search-Clients' | translate }}"
            (onFocus)="$event.target.select()"
            [dropdown]="true"
            required>
            <ng-template let-client pTemplate="item">
              <div>
                {{ client.ClientName }}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="p-field p-fluid">
          <label class="label-text"> Tags</label>
          <!-- <p-dropdown [options]="tagsListForFilters" styleClass="project-tags-dropdown" [(ngModel)]="projectObj.tagID"name="itemTagSelected" scrollHeight="500px" id="itemTagSelected" (onChange)="onChangeFilters($event, 'tag')" #itemTagSelected="ngModel"[ngModelOptions]="{standalone: true}"> <ng-template let-item pTemplate="selectedItem">
          <span class="p-text-center {{ item.color }}">
{{ item.label }}
          </span> </ng-template> <ng-template let-item pTemplate="item">
          <div class="ui-helper-clearfix"> <div class="p-text-center p-p-2 {{ item.color }}">
{{ item.label }}
          </div>
          </div> </ng-template>
          </p-dropdown> -->
          <p-multiSelect
            [options]="tagsListForFilters"
            [(ngModel)]="projectObj.tagIDs"
            (onChange)="onChangeFilters($event, 'tag')"
            [ngModelOptions]="{standalone: true}"
            selectedItemsLabel="{0} status selected"
            placeholder="All Tags">
            <ng-template let-item pTemplate="selectedItem">
              <span
                class="p-text-center"
                [style.color]="item.textColor"
                [style.background-color]="item.color && item.color != '' ? item.color : null">
                {{ item.label }}
              </span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="ui-helper-clearfix">
                <div
                  class="p-text-center p-p-2"
                  [style.color]="item.textColor"
                  [style.background-color]="item.color && item.color != '' ? item.color : null">
                  {{ item.label }}
                </div>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="p-field p-fluid">
          <div class="p-d-flex p-jc-between">
            <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline">
              <label class="label-text p-mr-3 p-mb-2"> Date Range </label>
              <p-dropdown
                [options]="dueDateList"
                [(ngModel)]="projectObj.dueDateID"
                scrollHeight="350px"
                class="width-100p"
                name="itemdueDateSelected"
                id="itemdueDateSelected"
                (onChange)="onChangeFilters($event, 'dueDate')"
                #itemdueDateSelected="ngModel"
                [ngModelOptions]="{standalone: true}">
              </p-dropdown>
            </div>
            <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline p-ml-2">
              <label class="label-text p-mr-3 p-mb-2"> {{ 'date_to_filter' | translate }} </label>
              <p-dropdown
                [options]="dateToFilterList"
                [(ngModel)]="DateToFilter"
                scrollHeight="350px"
                class="width-100p"
                name="DateToFilter"
                id="DateToFilter"
                (onChange)="onChangeFilters($event, 'dateToFilter')"
                [ngModelOptions]="{standalone: true}">
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="p-field p-fluid" *ngIf="projectObj.dueDateID == 'custom'">
          <div class="p-d-flex p-jc-between">
            <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline">
              <label class="label-text p-mr-3 p-mb-2"> {{ 'date-from' | translate }} </label>
              <p-calendar
                (onSelect)="onChangeFilters($event, 'dateFrom')"
                (onClearClick)="onChangeFilters($event, 'dateFrom')"
                (onBlur)="onChangeFilters($event, 'dateFrom')"
                [monthNavigator]="true"
                [ngModelOptions]="{standalone: true}"
                [yearNavigator]="true"
                yearRange="2000:2090"
                [(ngModel)]="DateFrom"
                class="width-100p">
              </p-calendar>
            </div>
            <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline p-ml-2">
              <label class="label-text p-mr-3 p-mb-2"> {{ 'date-to' | translate }} </label>
              <p-calendar
                (onSelect)="onChangeFilters($event, 'dateTo')"
                (onClearClick)="onChangeFilters($event, 'dateTo')"
                (onBlur)="onChangeFilters($event, 'dateTo')"
                [monthNavigator]="true"
                [ngModelOptions]="{standalone: true}"
                [yearNavigator]="true"
                yearRange="2000:2090"
                [(ngModel)]="DateTo"
                class="width-100p">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="p-field p-fluid">
          <div class="p-d-flex p-jc-between">
            <div class="p-col-12 p-pl-0 p-py-0 p-formgroup-inline">
              <label class="label-text p-mr-3 p-mb-2"> {{ 'Completion-Date' | translate }} </label>
              <p-dropdown
                [options]="completionDateList"
                [(ngModel)]="projectObj.completionDate"
                scrollHeight="350px"
                class="width-100p"
                name="itemcompletionDateSelected"
                id="itemcompletionDateSelected"
                (onChange)="onChangeFilters($event, 'completionDate')"
                #itemcompletionDateSelected="ngModel"
                [ngModelOptions]="{standalone: true}">
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="p-field p-fluid">
          <label class="label-text"> {{ 'repeat' | translate }} </label>
          <p-multiSelect
            [options]="dateRangeList"
            [(ngModel)]="projectObj.repeatValues"
            (onChange)="onChangeFilters($event, 'repeat')"
            [ngModelOptions]="{standalone: true}"
            selectedItemsLabel="{0} selected"
            placeholder="All">
            <ng-template let-item pTemplate="selectedItem">
              <span class="p-text-center {{ item.color | lowercase }}"> {{ item.label }} </span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="p-text-center drop_{{ item.color | lowercase }}">{{ item.label }}</div>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="p-field p-fluid">
          <label class="label-text"> {{ 'status' | translate }} </label>
          <!-- <p-dropdown [options]="statusList" [(ngModel)]="projectObj.statusID" scrollHeight="300px"name="itemStatusSelected" id="itemStatusSelected" (onChange)="onChangeFilters($event, 'status')" #itemStatusSelected="ngModel"[ngModelOptions]="{standalone: true}"> <ng-template let-item pTemplate="item">
          <div class="ui-helper-clearfix"> <div class="p-text-center drop_{{ item.color | lowercase }}">
{{ item.label }}
          </div>
          </div> </ng-template>
          </p-dropdown> -->
          <p-multiSelect
            [options]="statusList"
            [(ngModel)]="projectObj.statusIDs"
            (onChange)="onChangeFilters($event, 'status')"
            [ngModelOptions]="{standalone: true}"
            selectedItemsLabel="{0} status selected"
            placeholder="All Active">
            <ng-template let-item pTemplate="selectedItem">
              <span class="p-text-center {{ item.color | lowercase }}"> {{ item.label }} </span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="p-text-center drop_{{ item.color | lowercase }}">{{ item.label }}</div>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="p-field p-fluid" *ngIf="isAllowFilterOtherUser">
          <label class="label-text"> {{ 'manager' | translate }} </label>
          <p-dropdown
            [options]="AllStaffsTypes"
            [(ngModel)]="projectObj.staffId"
            name="itemStaffIdSelected"
            id="itemStaffIdSelected"
            (onChange)="onChangeFilters($event, 'manager')"
            #itemStaffIdSelected="ngModel"
            [ngModelOptions]="{standalone: true}">
          </p-dropdown>
        </div>
        <div class="p-field p-fluid" *ngIf="projectParamsObj.staffId">
          <label>- or -</label>
        </div>
        <div class="p-field p-fluid" *ngIf="isAllowFilterOtherUser">
          <label class="label-text"> {{ 'partner' | translate }} </label>
          <p-dropdown
            [options]="AllStaffsTypes"
            [(ngModel)]="projectObj.managerId"
            name="itemManagerIdSelected"
            id="itemManagerIdSelected"
            (onChange)="onChangeFilters($event, 'partner')"
            #itemManagerIdSelected="ngModel"
            [ngModelOptions]="{standalone: true}">
          </p-dropdown>
        </div>
        <div class="p-field p-fluid">
          <label class="label-text"> {{ 'Client_Group' | translate }} </label>
          <p-dropdown
            [options]="groupList"
            [(ngModel)]="projectObj.clientGroup"
            placeholder="All"
            (onChange)="onChangeFilters($event, 'clientGroup')">
          </p-dropdown>
        </div>

        <h6
          *ngIf="projectParamsObj.redirectStatus"
          pTooltip="{{ 'PM-dashboard-redirect-tooltip' | translate }}"
          tooltipPosition="top">
          <b>Other filters from dashboard<i class="fa fa-question-circle ic-blue p-mx-1"></i>: </b>
          <span *ngIf="projectParamsObj.dueDateID">
            {{getLabel('DueDate', projectParamsObj.dueDateID)}},</span
          >
          <span *ngIf="projectParamsObj.staffId"> Manager/Partner,</span>
          <span *ngIf="projectParamsObj.dateRange && projectParamsObj.dateRange != 'All'"
            >{{projectParamsObj.dateRange}}</span
          >
        </h6>

        <div class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns">
          <button
            pButton
            pRipple
            class="p-mr-2"
            type="button"
            icon="fal fa-sync"
            label="{{ 'fetch' | translate }}"
            [disabled]="(projectObj.dueDateID == 'custom' && (!DateTo || !DateFrom))"
            (click)="fetchData( );"></button>
          <button
            pButton
            pRipple
            class="p-mr-2"
            type="button"
            icon="fal fa-undo"
            label="{{ 'Reset' | translate }}"
            (click)="onResetFilters( );"></button>
          <button
            pButton
            pRipple
            class="p-mr-2"
            type="button"
            icon="fal fa-times"
            label="{{ 'cancel_button' | translate }}"
            (click)="onCloseFilter( );"></button>
        </div>
      </div>
    </div>
  </p-sidebar>
  <p-sidebar
    *ngIf="isShowRequestSidebar"
    position="right"
    [style]="{ 'min-width':'70%', 'min-height':'100%', 'overflow': 'auto', 'background':'#8e8f92', 'padding':'2rem' }"
    [(visible)]="isShowRequestSidebar"
    (onHide)="isShowRequestSidebar = false;">
    <div class="card p-pt-0">
      <div class="p-d-flex p-jc-between">
        <h4 class="label-text p-pt-3 p-mb-0">{{ 'Project-Tasks' | translate }}</h4>
      </div>
      <p-table
        *ngIf="(( projectTaskList?.length > 0 ))"
        #dt
        dataKey="TaskRow"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
        editMode="row"
        [value]="projectTaskList"
        [responsive]="true"
        [rows]="10"
        [rowHover]="true"
        [sortOrder]="1"
        [scrollable]="true"
        scrollDirection="both"
        scrollHeight="350px">
        <ng-template pTemplate="caption">
          <div *ngIf="projectTaskList.length > 0" class="table-header p-text-center">
            <span class="ic-size-16"> {{ totalProcessLen }}/{{ projectTaskList.length }} </span>
            <span>
              <p-progressBar
                [value]="( ( totalProcessLen / projectTaskList.length ) * 100 )"
                [showValue]="false">
              </p-progressBar>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="width-2p p-text-center"></th>
            <th class="width-5p p-text-center">{{ 'task' | translate }} #</th>
            <th
              class="width-25p p-text-left required-field"
              pTooltip="{{ 'Task-Description' | translate }}"
              tooltipPosition="top"
              showDelay="800">
              {{ 'Task-Description' | translate }}
              <i class="fa fa-question-circle ic-blue"></i>
            </th>
            <th
              *ngIf="selectedRowOnTasks.automaticComputeDuedate == true"
              class="width-7p p-text-center"
              pTooltip="{{ 'due-date' | translate }} {{ 'offset_days' | translate }}"
              tooltipPosition="top"
              showDelay="800">
              {{ 'offset_days' | translate }} <i class="fa fa-question-circle ic-blue"> </i>
            </th>
            <th
              class="width-7p p-text-center"
              pTooltip="{{ 'Completion-Date' | translate }}"
              tooltipPosition="top"
              showDelay="800">
              {{ 'Completion' | translate }} <i class="fa fa-question-circle ic-blue"> </i>
            </th>
            <th class="width-7p p-text-center">{{ 'due-date' | translate }}</th>
            <th
              class="width-10p p-text-left"
              pTooltip="{{ 'Assigned-User' | translate }}"
              tooltipPosition="top"
              showDelay="800">
              {{ 'Assigned' | translate }} <i class="fa fa-question-circle ic-blue"> </i>
            </th>
          </tr>
        </ng-template>
        <ng-template let-rowData let-rowIndex="rowIndex" let-editing="editing" pTemplate="body">
          <tr
            [ngStyle]="{ 'background-color': (( !rowData.IsCompleted && !rowData.IsTaskReady && selectedRowOnTasks.IsCompleteInOrder ) ? ( '#ececec' ) : ( '' )) }">
            <td class="width-3p p-text-center">
              <p-checkbox
                #itemIsCompleted="ngModel"
                id="itemIsCompleted"
                name="itemIsCompleted"
                pTooltip="{{ 'complete_task' | translate }}"
                binary="true"
                showDelay="800"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!rowData.IsCompleted && !rowData.IsTaskReady && selectedRowOnTasks.IsCompleteInOrder"
                [(ngModel)]="rowData.IsCompleted"
                (onChange)="onCompleteTaskRow(rowData)">
              </p-checkbox>
            </td>
            <td class="width-5p p-text-center p-px-3">
              <div class="badge-number bg-blue">{{ rowIndex + 1 }}</div>
            </td>
            <td
              class="width-25p p-text-left p-text-nowrap p-text-truncate p-fluid"
              pTooltip="{{ rowData[ 'TaskDescription' ] }}"
              tooltipPosition="top"
              tooltipStyleClass="tooltip-no-wrap"
              showDelay="800"
              [ngClass]="{ 'new-row': (( projectTaskList.length - 1 ) == rowIndex ) }">
              <span> {{ rowData[ 'TaskDescription' ] }} </span>
            </td>
            <td
              *ngIf="selectedRowOnTasks.automaticComputeDuedate == true"
              class="width-5p p-text-center p-text-nowrap p-text-truncate p-fluid"
              pTooltip="{{ 'offset_days' | translate }}"
              tooltipPosition="top"
              tooltipStyleClass="tooltip-no-wrap"
              showDelay="800"
              [ngClass]="{ 'new-row': (( projectTaskList.length-1 ) == rowIndex ) }">
              <span> {{ rowData[ 'dueDateOffsetDaysIndicator' ] }} </span>
            </td>
            <td
              class="width-7p p-text-center p-text-nowrap p-text-truncate p-fluid"
              pTooltip="{{ rowData[ 'CompletionDate' ] | date:'MM/dd/yyyy' }}{{ rowData[ 'CompletedByStaffName' ] ? ' - ' + rowData[ 'CompletedByStaffName' ] : '' }}"
              tooltipPosition="top"
              tooltipStyleClass="tooltip-no-wrap"
              showDelay="800">
              <span>
                {{ rowData['CompletionDate' ] | date:'MM/dd/yyyy' }}{{ rowData[ 'CompletionDate' ]
                && rowData['CompletedByStaffName' ] ? ' - ' + rowData[ 'CompletedByStaffName' ] : ''
                }}
              </span>
            </td>
            <td
              class="width-7p p-text-center p-text-nowrap p-text-truncate p-fluid"
              pTooltip="{{ rowData['DueDate'] | date:'MM/dd/yyyy' }}"
              tooltipPosition="top"
              tooltipStyleClass="tooltip-no-wrap"
              showDelay="800">
              <span> {{ rowData[ 'DueDate' ] | date:'MM/dd/yyyy' }} </span>
            </td>
            <td
              class="width-10p p-text-left p-text-nowrap p-text-truncate p-fluid"
              pTooltip="{{ rowData[ 'StaffNames' ] }}"
              tooltipPosition="top"
              tooltipStyleClass="tooltip-no-wrap"
              showDelay="800">
              {{ rowData[ 'StaffNames' ] ? rowData[ 'StaffNames' ] : 'Unassigned' }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator
        *ngIf="(( taskListPaginateStatus === true ) && ( projectTaskListTotalCount > 0 ) )"
        [totalRecords]="projectTaskListTotalCount"
        [rows]="10"
        (onPageChange)="onProjectTaskListPageChange( $event )">
      </p-paginator>
    </div>
  </p-sidebar>
</div>
<p-dialog
  header="{{ 'pm.Batch_Update_Projects' | translate }} - {{ selectedClientsItems.length }} {{ 'selected' | translate }}"
  [(visible)]="showBatchUpdateProjForm"
  [modal]="true"
  [style]="{width: '35vw'}"
  [maximizable]="false"
  showEffect="fade"
  (onHide)="closeBatchFormModal();"
  [baseZIndex]="1"
  [draggable]="true"
  [resizable]="false">
  <form role="form" [formGroup]="batchUpdateForm" #formDirective="ngForm" novalidate>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <label class="p-text-bold ic-blue"> {{ 'pm.batch_update_select' | translate }} </label>
        </div>
        <div class="p-col-6 p-mt-3">
          <label class="p-text-bold ic-blue"> {{ 'pm.batch_update_values' | translate }} </label>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateDueDate"
            [binary]="true"
            label="{{ 'due-date' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-calendar
            formControlName="dueDate"
            [monthNavigator]="true"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isUpdateDueDate'].value"
            (onSelect)="validateForm(true);"
            (onClearClick)="validateForm(true);"
            (onBlur)="validateForm(true);"
            [yearNavigator]="true"
            yearRange="2000:2099"
            showButtonBar="true">
          </p-calendar>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateRecDate"
            [binary]="true"
            label="{{ 'pm.received-date' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-calendar
            formControlName="receivedDate"
            [monthNavigator]="true"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isUpdateRecDate'].value"
            (onSelect)="validateForm(true);"
            (onClearClick)="validateForm(true);"
            (onBlur)="validateForm(true);"
            [yearNavigator]="true"
            yearRange="2000:2099"
            showButtonBar="true">
          </p-calendar>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateExtDate"
            [binary]="true"
            label="{{ 'Extension-Date' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-calendar
            formControlName="extensionDate"
            [monthNavigator]="true"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isUpdateExtDate'].value"
            (onSelect)="validateForm(true);"
            (onClearClick)="validateForm(true);"
            (onBlur)="validateForm(true);"
            [yearNavigator]="true"
            yearRange="2000:2099"
            showButtonBar="true">
          </p-calendar>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdatePartner"
            [binary]="true"
            label="{{ 'partner' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            scrollHeight="350px"
            [options]="staffListBatchUpdate"
            formControlName="partner"
            placeholder="{{ 'assign_user' | translate }}"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isUpdatePartner'].value">
          </p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateManager"
            [binary]="true"
            label="{{ 'manager' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            scrollHeight="350px"
            [options]="staffListBatchUpdate"
            formControlName="manager"
            placeholder="{{ 'assign_user' | translate }}"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isUpdateManager'].value">
          </p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateTags"
            [binary]="true"
            label="{{ 'Tags' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-px-0 p-mt-3 p-d-flex p-jc-between">
          <div class="p-col-6">
            <p-multiSelect
              [options]="tagsList"
              placeholder="{{ 'Select Tags' | translate }}"
              appendTo="body"
              formControlName="tags"
              [disabled]="!batchUpdateForm.controls['isUpdateTags'].value"
              scrollHeight="350px">
              <ng-template let-data pTemplate="item">
                <div
                  [style.background-color]="data.color && data.color != '' ? data.color : null"
                  style="width: 100%; padding: 5px; border-radius: 3px">
                  <div>{{ data.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
          <div class="p-col-6 p-d-flex p-jc-between">
            <div class="p-col-4 p-mt-1">
              <p-inputSwitch
                formControlName="isAddOnly"
                [disabled]="!batchUpdateForm.controls['isUpdateTags'].value">
              </p-inputSwitch>
            </div>
            <div class="p-col-8 p-pr-0 p-mt-2">
              <label class="label-text"> {{ onChangeTagSwitch() }} </label>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateRepeat"
            [binary]="true"
            label="{{ 'repeat' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3 p-d-flex p-jc-between">
          <div class="p-col-{{ batchUpdateForm.value['repeat'] == 'custom' ? '10' : '12' }} p-px-0">
            <p-dropdown
              [options]="dateRangeList"
              (onChange)="onChangeRepeat()"
              formControlName="repeat"
              appendTo="body"
              [disabled]="!batchUpdateForm.controls['isUpdateRepeat'].value">
            </p-dropdown>
          </div>
          <div
            class="p-col-2 p-pr-0 p-pt-2 p-pl-3"
            *ngIf="batchUpdateForm.value['repeat'] == 'custom'">
            <i
              class="fal fa-repeat ic-red cursor-hand ic-size-13"
              tooltipPosition="top"
              pTooltip="{{ 'view_custom' | translate }}"
              pRipple
              (click)="rruleComponent.isDisplayRuleDialog = true;rruleComponent.ngOnInit();">
            </i>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdateTemplateWildcards"
            [binary]="true"
            (onChange)="onChangeUpdateWildcards($event)"
            label="{{ 'pm.template-name-with-wildcards' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <input
            class="width-100p p-text-left"
            type="text"
            pInputText
            (onBlur)="validateForm(true);"
            [disabled]="!batchUpdateForm.controls['isUpdateTemplateWildcards'].value"
            formControlName="TemplateWildcards"
            (focus)="$event.target.select();" />
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isUpdatePreviousYearPolicy"
            [binary]="true"
            label="{{ 'pm.previous_period_year_policy' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isPreviousPeriodYearPolicy"
            [binary]="true"
            [disabled]="!batchUpdateForm.controls['isUpdatePreviousYearPolicy'].value">
          </p-checkbox>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isUpdatePreviousYearPolicy'].value && batchUpdateForm.controls['isPreviousPeriodYearPolicy'].value == true">
            Set as true
          </label>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isUpdatePreviousYearPolicy'].value && batchUpdateForm.controls['isPreviousPeriodYearPolicy'].value == false">
            Set as false
          </label>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isRequireFinalReview"
            [binary]="true"
            label="{{ 'pm.Requires-Final-Review' | translate }}"
            class="label-text">
          </p-checkbox
          ><i
            pTooltip="{{ 'pm.requires-final-review' | translate }}"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-no-wrap"
            class="fa fa-question-circle ic-blue p-ml-1">
          </i>
        </div>
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="requireFinalReview"
            [binary]="true"
            [disabled]="!batchUpdateForm.controls['isRequireFinalReview'].value">
          </p-checkbox>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isRequireFinalReview'].value && batchUpdateForm.controls['requireFinalReview'].value == true">
            Set as true
          </label>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isRequireFinalReview'].value && batchUpdateForm.controls['requireFinalReview'].value == false">
            Set as false
          </label>
        </div>
      </div>
      <div class="p-col-12 p-mb-4 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isCompleteInOrder"
            [binary]="true"
            label="{{ 'pm.complete-tasks-in-order' | translate }}"
            class="label-text">
          </p-checkbox
          ><i
            pTooltip="{{ 'pm.Complete-Tasks-tooltip' | translate }}"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-no-wrap"
            class="fa fa-question-circle ic-blue p-ml-1">
          </i>
        </div>
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="completeInOrder"
            [binary]="true"
            [disabled]="!batchUpdateForm.controls['isCompleteInOrder'].value">
          </p-checkbox>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isCompleteInOrder'].value && batchUpdateForm.controls['completeInOrder'].value == true">
            Set as true
          </label>
          <label
            class="ic-blue p-ml-3"
            *ngIf="batchUpdateForm.controls['isCompleteInOrder'].value && batchUpdateForm.controls['completeInOrder'].value == false">
            Set as false
          </label>
        </div>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      (click)="closeBatchFormModal()"
      label="{{ 'cancel_button' | translate }}"></button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      label="{{ 'update_projects' | translate }}"
      (click)="onBatchUpdate()"
      [disabled]="!isFormValid"></button>
  </ng-template>
</p-dialog>
<app-batch-assign-budget
  *ngIf="showAssignBudgetDialog"
  [isFromProject]="true"
  [selectedItems]="selectedProjects"
  (onAssignBudgetsCompleted)="onAssignBudgetsCompleted($event)">
</app-batch-assign-budget>
<app-rrule-generator
  [selectedDueDate]="batchUpdateForm.value['dueDate'] || repeatDueDate"
  [selectedRuleString]="batchUpdateForm.value['ruleString']"
  (onCompleteRrule)="onCompleteRruleDialog($event)">
</app-rrule-generator>