<div class="project-details" (click)="checkIfRemoveEdit()">
  <form role="form" [formGroup]="projectEditForm" novalidate>
    <div class="layout-content common-dialog">
      <span class="pull-right p-mr-3 clientform-btn-top">
        <!--  [disabled]="projectEditForm.invalid || projectEditForm.pristine" -->
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'save_button' | translate }}"
          icon="fal fa-check"
          class="p-mr-2 p-button-sm"
          (click)="saveAllTasks()"
          [disabled]="disableSave()"></button>
        <p-button
          pRipple
          label="{{ 'dashboard' | translate }}"
          icon="fal fa-tachometer-alt-fastest"
          styleClass="p-button-sm"
          class="p-button-help p-pr-2"
          routerLink="/project-management/dashboard">
        </p-button>
        <p-button
          pRipple
          label="{{ 'Projects' | translate }}/{{ 'Tasks' | translate }} List"
          icon="fal fa-building"
          styleClass="p-button-sm "
          class="p-button-help p-pr-2"
          routerLink="/project-management/projectList"></p-button>
        <!-- <p-button pRipple label="Clients" icon="fal fa-users" styleClass="p-button-sm " class="p-button-help p-pr-2"
               routerLink="/project-management/clientHistory"></p-button>

           <p-button pRipple label="Settings" icon="fal fa-cog" styleClass="p-button-sm" class="p-button-help p-pr-2"
               routerLink="/project-management/settings"></p-button> -->

        <p-button
          pRipple
          label="{{ 'PM-Settings' | translate }}"
          icon="fal fa-cog"
          styleClass="p-button-sm"
          class="p-button-help p-pr-2"
          routerLink="/project-management/settings"></p-button>

        <p-button
          pRipple
          icon="fal fa-thumbs-up"
          styleClass="p-button-sm"
          pTooltip="{{ 'Finish-Review' | translate }}"
          tooltipPosition="top"
          class="p-button-help p-pr-2"
          (click)="finishReview()"
          *ngIf="statusText === 'Ready for Review'">
        </p-button>
        <p-button
          pRipple
          icon="fal fa-thumbs-down"
          styleClass="p-button-sm"
          pTooltip="{{ 'Failed-Review' | translate }}"
          tooltipPosition="top"
          class="p-button-help p-pr-2"
          (click)="failFinishReview()"
          *ngIf="statusText === 'Ready for Review'">
        </p-button>
        <p-splitButton
          *ngIf="isAllowCreateProject"
          label=" {{ 'New-Projects' | translate }}"
          icon="fal fa-plus"
          styleClass="p-button-sm split-button-size"
          class="p-button-help p-pr-2"
          [model]="buttonsList"
          (onClick)="redirectProject()"
          [menuStyleClass]="'new-project-button-list'">
        </p-splitButton>
      </span>
      <div class="p-grid p-mx-2">
        <div class="p-col-12" style="height: 100%">
          <div class="card p-pt-2 p-pb-2 p-d-flex p-jc-between" style="height: 100%">
            <div class="p-flex-row">
              <div class="pull-left p-mr-2" *ngFor="let tag of selectedProjectDetails?.Tags">
                <span
                  tooltipPosition="top"
                  *ngIf="findTagByValue(tag,'label') !== ''"
                  class="Tag-project-details p-text-center"
                  [style.background-color]="findTagByValue(tag,'color')"
                  [style.color]="findTagByValue(tag,'textColor')"
                  tooltipStyleClass="tooltip-no-wrap">
                  {{findTagByValue(tag,'label')}}
                  <i
                    class="pi pi-times cursor-hand ic-size-13 fb p-mt-1"
                    (click)="deleteTag(tag);validateForm(true)"></i>
                </span>
              </div>
            </div>
            <div class="pull-right ddm-buttons p-d-flex p-ai-center">
              <p-button
                pRipple
                type="button"
                icon="fal fa-plus-square"
                styleClass="p-button-sm"
                (click)="op2.show($event);validateForm(false);"
                class="p-button-help p-pr-2"
                pTooltip="{{ 'add_tags' | translate }}"
                tooltipPosition="top">
              </p-button>
            </div>
            <!-- <div>

                        </div> -->
          </div>
        </div>
        <div class="p-col-12">
          <div class="card">
            <div style="align-items: center" class="p-d-flex p-jc-between p-mx-2 p-mb-2">
              <div class="p-d-flex">
                <h4 class="label-text p-pr-6">{{ 'project' | translate }}</h4>
                <div [ngClass]="{'editing-record': !isCreateFlow, 'adding-record': isCreateFlow }">
                  {{ (isCreateFlow ? "Adding" : "Editing") | translate }}
                </div>
              </div>
              <div class="p-d-flex p-jc-end">
                <div
                  class="flag project-summary-{{statusClass | lowercase}} p-mr-2 p-mt-1"
                  style="font-size: 15px">
                  {{statusText}}
                </div>

                <div>
                  <button
                    pRipple
                    pButton
                    icon="fal fa-plus"
                    class="p-mr-2 p-button-sm"
                    pTooltip="Add Note"
                    label="Add Note"
                    (click)="onCloseProjectNotesSidebar();"
                    showDelay="800"
                    tooltipPosition="top"></button>
                  <span class="p-mr-3" *ngIf="!isCreateFlow">
                    <label class="label-text p-mr-2 p-mb-2" style="display: inline-block"
                      >{{ 'More Options' | translate }}</label
                    >
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-chevron-down"
                      (click)="menu1.toggle($event)"></button>
                  </span>
                  <p-menu
                    appendTo="body"
                    #menu1
                    [popup]="true"
                    styleClass="button-list"
                    [model]="moreOptions">
                  </p-menu>
                </div>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12">
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-4">
                    <div class="p-d-flex p-jc-between p-mb-2">
                      <label class="required-field label-text"
                        >{{ 'Project-Name' | translate }}</label
                      >
                      <i
                        class="fal fa-file-alt ic-purple cursor-hand ic-size-18 p-pr-2"
                        tooltipPosition="top"
                        tooltipStyleClass="tooltip-no-wrap"
                        pTooltip="{{ 'pm.view_project_template' | translate }}"
                        pRipple
                        (click)="onOpenDisplayTemplateWildcardsDialog()"></i>
                    </div>
                    <div (click)="onFocusTemplateName()">
                      <input
                        class="width-100p p-text-left"
                        type="text"
                        pInputText
                        [readonly]="projectEditForm.value['TemplateWildcards']?.includes('^')"
                        formControlName="TemplateName"
                        (click)="onFocusTemplateName()"
                        [disabled]="projectEditForm.value['TemplateWildcards']?.includes('^')"
                        [ngClass]="{'p-disabled': projectEditForm.value['TemplateWildcards']?.includes('^'), '': !projectEditForm.value['TemplateWildcards']?.includes('^')}"
                        (change)="isTouched = true;onTemplateNameChanged($event)" />
                    </div>
                  </div>
                  <div class="p-field p-col-4">
                    <label class="label-text">{{ 'due-date' | translate }}</label>
                    <p-calendar
                      formControlName="DueDate"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2000:2099"
                      showButtonBar="true"
                      (onSelect)="applyDueDate();"
                      (onClearClick)="applyDueDate();"
                      readonlyInput="true">
                    </p-calendar>
                  </div>
                  <div class="p-field p-col-4 p-mb-1">
                    <label class="label-text">{{ 'Extension-Date' | translate }}</label>
                    <div class="p-formgroup-inline">
                      <div class="p-field p-col-5 p-pl-0">
                        <p-calendar
                          formControlName="ExtensionDate"
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          yearRange="2000:2099"
                          showButtonBar="true"
                          (onSelect)="onSelectExtensionDate(true);"
                          (onBlur)="onSelectExtensionDate();"
                          (onClearClick)="onSelectExtensionDate();">
                        </p-calendar>
                      </div>
                      <div class="p-field p-col-1 p-pl-0">
                        <!-- <button pButton pRipple pButton
                                                    *ngIf="isShowIcon" (click)="op3.show($event)"
                                                    class="p-button-icon pi pi-chevron-down"
                                                    pTooltip="{{ 'Extend-Date' | translate }}" tooltipPosition="top">
                                                </button> -->

                        <button
                          class="p-button p-ripple p-button p-component p-button-icon-only ng-star-inserted"
                          pbutton=""
                          pripple=""
                          type="button"
                          *ngIf="isShowIcon"
                          (click)="op3.show($event)"
                          pTooltip="{{ 'Extend-Date' | translate }}"
                          tooltipPosition="top">
                          <span class="p-button-icon pi pi-chevron-down" aria-hidden="true"></span
                          ><span aria-hidden="true" class="p-button-label"></span>
                        </button>
                      </div>
                      <div class="p-field p-col-6">
                        <p-checkbox
                          formControlName="isReviewRequired"
                          [binary]="true"
                          label="{{ 'pm.Requires-Final-Review' | translate }}"
                          (onChange)="finalReviewRequired($event)"
                          pTooltip="{{ 'pm.requires-final-review' | translate }}"
                          tooltipPosition="top"
                          tooltipStyleClass="tooltip-no-wrap"
                          class="label-text">
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-4">
                    <label class="label-text p-col-12 p-pl-0">
                      <span>{{ 'client' | translate }}</span>
                      <span class="pull-right">
                        <i
                          *ngIf="selClient?.ClientID != null"
                          class="fal fa-user ic-amex cursor-hand ic-size-18 p-pr-3"
                          tooltipPosition="top"
                          pTooltip="{{ 'view_client' | translate }}"
                          pRipple
                          (click)="openClient()"></i>
                        <i
                          *ngIf="selClient?.ClientID != null"
                          class="fal fa-user-friends ic-red cursor-hand ic-size-18"
                          tooltipPosition="top"
                          pTooltip="{{ 'view_client_history' | translate }}"
                          pRipple
                          routerLink="/project-management/{{selClient?.ClientID}}/clientDetails"></i>
                      </span>
                    </label>

                    <p-autoComplete
                      field="ClientName"
                      placeholder="Search Clients"
                      styleClass="p-pt-0 p-mt-0"
                      autocomplete="off"
                      [ngModelOptions]="{ standalone: true }"
                      [suggestions]="filteredClients"
                      [dropdown]="true"
                      [(ngModel)]="selClient"
                      [disabled]="!isAllowChangeClient"
                      (completeMethod)="
                                            filterClients( $event );
                                          "
                      (onSelect)="
                                            handleSelectClick( $event );
                                            projectEditForm.markAsDirty( );
                                          "
                      (onFocus)="
                                            $event.target.select( );
                                          "
                      ngDefaultControl
                      required>
                      <ng-template let-client pTemplate="item">
                        <div>
                          {{ client.ClientName }}{{ client.ClientNumber ? ' - ' +
                          client.ClientNumber : '' }}
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                  <div class="p-field p-col-2">
                    <label class="label-text">{{ 'In-Date' | translate }}</label>
                    <p-calendar
                      formControlName="DateReceived"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2000:2099"
                      showButtonBar="true"
                      (onSelect)="calculateFinishDate(true); onOffsetDaysChange(1, null);"
                      (onClearClick)="calculateFinishDate(true);"
                      (onBlur)="calculateFinishDate(true);">
                    </p-calendar>
                  </div>
                  <div class="p-field p-col-2">
                    <label class="label-text">{{ 'Out-Date' | translate }}</label>
                    <p-calendar
                      formControlName="FinishDate"
                      [minDate]="projectEditForm.get('DateReceived').value"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2000:2099"
                      showButtonBar="true"
                      (onSelect)="calculateFinishDate(false);applyDueDate();"
                      (onClearClick)="calculateFinishDate(false);"
                      (onBlur)="calculateFinishDate(false);"
                      [disabled]="projectEditForm.get('DateReceived').value == null">
                    </p-calendar>
                  </div>
                  <div class="p-field p-col-4 p-mb-1">
                    <div class="p-formgroup-inline">
                      <div class="p-field p-col-4 p-pl-0">
                        <label class="label-text p-col-12 p-pl-0">
                          <span>{{ 'repeat' | translate }}</span>
                          <span class="pull-right">
                            <i
                              *ngIf="projectEditForm.get('Repeat').value === 'custom'"
                              class="fal fa-repeat ic-red cursor-hand ic-size-13"
                              tooltipPosition="top"
                              pTooltip="{{ 'view_custom' | translate }}"
                              pRipple
                              (click)="isDisplayRuleDialog = true;"></i>
                          </span>
                        </label>
                        <p-dropdown
                          [options]="dateRangeList"
                          formControlName="Repeat"
                          (onChange)="validateForm(true);verifyCustomDialog()"
                          placeholder=" Select Option"
                          (click)="onClickRepeatBtn()"
                          [disabled]="projectEditForm.get('DueDate').value == null">
                        </p-dropdown>
                      </div>
                      <div class="p-field p-col-4 p-mt-5">
                        <div *ngIf="tasksDataSource.length > 1">
                          <p-checkbox
                            formControlName="IsCompleteInOrder"
                            [binary]="true"
                            (onChange)="onIsCompleteInOrderChange($event)"
                            label="{{ 'pm.complete-tasks-in-order' | translate }}"
                            pTooltip="{{ 'pm.Complete-Tasks-tooltip' | translate }}"
                            tooltipPosition="top"
                            tooltipStyleClass="tooltip-no-wrap"
                            class="label-text">
                          </p-checkbox>
                        </div>
                      </div>
                      <div class="p-field p-col-4 p-mt-5">
                        <div *ngIf="tasksDataSource.length > 1">
                          <p-checkbox
                            formControlName="automaticComputeDuedate"
                            [binary]="true"
                            (onChange)="onAutoComputeDueDate($event)"
                            label="{{ 'auto_compute_due_date' | translate }}"
                            pTooltip="{{ 'auto_compute_due_date-tooltip' | translate }}"
                            tooltipPosition="top"
                            tooltipStyleClass="tooltip-no-wrap"
                            class="label-text">
                          </p-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-formgroup-inline">
                  <div class="p-col-6 p-pl-0">
                    <div class="p-formgroup-inline" *ngIf="ddmSettingsObj.isBudgetingProjectLevel">
                      <div
                        class="p-field p-col-12 p-pl-2"
                        *ngIf="ddmSettingsObj.isBudgetingProjectLevel">
                        <label class="required-field label-text"
                          >{{ 'engagement.name' | translate }}</label
                        >
                        <p-dropdown
                          *ngIf="engagementListItems && engagementListItems.length > 0"
                          [disabled]="!selClient?.ClientID"
                          (onChange)="updateTimeRecords($event)"
                          (onFocus)="setPreviousEngagement()"
                          [options]="engagementListItems"
                          placeholder="Select Engagement"
                          formControlName="EngagementTypeID">
                        </p-dropdown>
                      </div>
                    </div>
                    <div class="p-formgroup-inline">
                      <div class="p-field p-my-0 p-pl-0 p-d-flex p-jc-between p-pl-0 p-col-12">
                        <div class="p-col-4">
                          <label class="label-text">{{ 'Billing_Partner' | translate }}</label>
                          <p-dropdown
                            (onChange)="validateForm(true)"
                            styleClass="p-mt-2"
                            [options]="AllManagerTypes"
                            [placeholder]="selectedProjectDetails?.managername || 'Unassigned'"
                            formControlName="ManagerID">
                          </p-dropdown>
                        </div>
                        <div class="p-col-4">
                          <label class="label-text">{{ 'manager' | translate }}</label>
                          <p-dropdown
                            (onChange)="validateForm(true)"
                            styleClass="p-mt-2"
                            [options]="AllManagerTypes"
                            [placeholder]="selectedProjectDetails?.username || 'Unassigned'"
                            formControlName="UserAssignedID">
                          </p-dropdown>
                        </div>
                        <div class="p-col-4">
                          <label class="label-text">{{ 'Turnaround-Days' | translate }}</label>
                          <p-inputNumber
                            mode="decimal"
                            placeholder="# of Days"
                            inputStyleClass="p-text-right p-mt-2"
                            formControlName="TurnAround"
                            (focus)="$event.target.select()"
                            maxlength="3"
                            inputId="withoutgrouping"
                            (onBlur)="calculateFinishDate(true);"
                            [useGrouping]="false">
                          </p-inputNumber>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-col-6 p-field">
                    <label class="label-text">{{ 'pm.Project-Memo' | translate }}</label>
                    <i
                      pTooltip="{{ 'project_memo_tooltip' | translate }}"
                      tooltipPosition="top"
                      class="fa fa-question-circle ic-blue p-ml-1"></i>
                    <app-mentions
                      [rows]="5"
                      [(inputText)]="projectEditForm.controls['ProjectMemo'].value"
                      (inputTextChange)="inputChanged();projectEditForm.controls['ProjectMemo'].setValue($event);isTouched=true;"
                      style="width: 100% !important; resize: vertical"></app-mentions>
                  </div>
                </div>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
        <p-dialog
          header="{{'alerts.task_email_alerts_defaults' | translate }}"
          *ngIf="selectedTaskEmailAlert"
          [(visible)]="showTaskEmailAlert"
          [modal]="true"
          [style]="{width: '600px'}"
          [responsive]="true"
          [draggable]="true"
          [resizable]="false"
          showEffect="fade"
          [baseZIndex]="1"
          [maximizable]="false"
          (onHide)="showTaskEmailAlert = false">
          <div class="p-col-12">
            <p-checkbox
              class="label-text"
              [(ngModel)]="selectedTaskEmailAlert.OverrideFirmDefaults"
              binary="true"
              [ngModelOptions]="{standalone: true}"
              label="{{ 'Override Firm Defaults?' | translate }}"
              (onChange)="onOverrideTaskFirmDefaults($event);selectedTaskEmailAlert.IsEmailAlertChanged = true;">
            </p-checkbox>
            <i
              class="pi pi-cog p-ml-3 cursor-hand ic-blue"
              pTooltip="{{ 'alerts.go_to_project_settings' | translate }}"
              (click)="redirectToProjectSettings()"
              showDelay="800"
              tooltipPosition="top"></i>
          </div>
          <div class="p-grid p-ml-3 p-mt-2">
            <div class="p-col-12" style="font-size: 11px; font-weight: 400">
              <p class="ic-red" *ngIf="!selectedTaskEmailAlert.OverrideFirmDefaults">
                {{ 'alerts.firm_defaults_used' | translate }}
              </p>
            </div>
            <div class="p-col-12">
              <p-checkbox
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults"
                class="label-text"
                [(ngModel)]="selectedTaskEmailAlert.DaysBeforePastDueDateBool"
                binary="true"
                [ngModelOptions]="{standalone: true}"
                label="{{ 'alerts.notify_days_before_due_date' | translate }}"
                (onChange)="toggleCheckBoxWithInt($event, selectedTaskEmailAlert, 'DaysBeforePastDueDate');selectedTaskEmailAlert.IsEmailAlertChanged = true;">
              </p-checkbox>
            </div>
            <div class="p-field p-col-8 p-ml-5">
              <label
                class="p-col-12"
                [ngClass]="!selectedTaskEmailAlert.OverrideFirmDefaults || !selectedTaskEmailAlert.DaysBeforePastDueDateBool? 'label-text-disabled' : 'label-text'"
                >{{'alerts.number_of_days_before_due_date' | translate}}</label
              >
              <p-inputNumber
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults || !selectedTaskEmailAlert.DaysBeforePastDueDateBool"
                maxlength="3"
                (click)="$event.target.select()"
                autocomplete="off"
                class="p-ml-3"
                (ngModelChange)="selectedTaskEmailAlert.IsEmailAlertChanged = true;"
                [(ngModel)]="selectedTaskEmailAlert.DaysBeforePastDueDate"
                mode="decimal"
                inputStyleClass="p-text-right"
                [ngModelOptions]="{standalone: true}"
                placeholder="0"></p-inputNumber>
            </div>
            <div class="p-col-12">
              <p-checkbox
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults"
                class="label-text"
                [(ngModel)]="selectedTaskEmailAlert.PastDue"
                binary="true"
                [ngModelOptions]="{standalone: true}"
                label="{{ 'alerts.notify_when_past_due' | translate }}"
                (onChange)="toggleCheckBoxWithInt($event, selectedTaskEmailAlert, 'TasksDaysToRemind');selectedTaskEmailAlert.IsEmailAlertChanged = true;">
              </p-checkbox>
            </div>
            <div class="p-field p-col-8 p-ml-5">
              <label
                class="p-col-12"
                [ngClass]="!selectedTaskEmailAlert.OverrideFirmDefaults || !selectedTaskEmailAlert.PastDue? 'label-text-disabled' : 'label-text'"
                >{{'alerts.interval_days_after_due_date' | translate}}</label
              >
              <p-inputNumber
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults || !selectedTaskEmailAlert.PastDue"
                maxlength="3"
                (click)="$event.target.select()"
                autocomplete="off"
                class="p-ml-3"
                (ngModelChange)="selectedTaskEmailAlert.IsEmailAlertChanged = true;"
                [(ngModel)]="selectedTaskEmailAlert.TasksDaysToRemind"
                mode="decimal"
                inputStyleClass="p-text-right"
                [ngModelOptions]="{standalone: true}"
                placeholder="0"></p-inputNumber>
            </div>
            <div class="p-col-12">
              <p-checkbox
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults"
                class="label-text"
                [(ngModel)]="selectedTaskEmailAlert.isNotifyManager"
                binary="true"
                [ngModelOptions]="{standalone: true}"
                label="{{ 'alerts.notify_manager' | translate }}"
                (onChange)="selectedTaskEmailAlert.IsEmailAlertChanged = true;">
              </p-checkbox>
            </div>
            <div class="p-col-12">
              <p-checkbox
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults"
                class="label-text"
                [(ngModel)]="selectedTaskEmailAlert.isNotifyBillingPartner"
                binary="true"
                [ngModelOptions]="{standalone: true}"
                label="{{ 'alerts.notify_billing_partner' | translate }}"
                (onChange)="selectedTaskEmailAlert.IsEmailAlertChanged = true;">
              </p-checkbox>
            </div>
            <div class="p-col-12">
              <p-checkbox
                [disabled]="!selectedTaskEmailAlert.OverrideFirmDefaults"
                class="label-text"
                [(ngModel)]="selectedTaskEmailAlert.isNotifyStaffAssigned"
                binary="true"
                [ngModelOptions]="{standalone: true}"
                label="{{ 'alerts.notify_assigned_staff' | translate }}"
                (onChange)="selectedTaskEmailAlert.IsEmailAlertChanged = true;">
              </p-checkbox>
            </div>
          </div>
          <ng-template pTemplate="footer">
            {{selectedTaskEmailAlert.IsEmailAlertChanged}}
            <button
              pButton
              type="button"
              (click)="showTaskEmailAlert=false;validateForm(true);projectEditForm.markAsDirty()"
              icon="fas fa-check"
              class="p-pr-2"
              label="{{ 'Save' | translate }}"
              [disabled]="!selectedTaskEmailAlert.IsEmailAlertChanged"></button>
            <button
              pButton
              type="button"
              label="Cancel"
              (click)="showTaskEmailAlert = false"
              class="p-pr-2"></button>
          </ng-template>
        </p-dialog>
        <div class="p-col-12" style="padding-bottom: 200px">
          <div class="card p-pt-0">
            <div class="p-d-flex p-jc-between">
              <h4 class="label-text p-pt-3 p-mb-0">{{ 'Project-Tasks' | translate }}</h4>
              <div class="p-d-flex p-jc-end p-mt-3">
                <span *ngIf="selectedItems.length > 0">
                  <label class="label-text p-mr-2 p-mb-2" style="display: inline-block"
                    >{{ 'pm.batch_options' | translate }}</label
                  >
                  <button
                    type="button"
                    class="p-button-sm"
                    pButton
                    pRipple
                    icon="pi pi-chevron-down"
                    (click)="menu.toggle($event)"></button>
                </span>
                <button
                  pRipple
                  pButton
                  icon="fal fa-plus"
                  class="p-ml-4 p-button-sm"
                  label="{{'Add-Row' | translate}}"
                  pTooltip="{{'mgmt-add-new' | translate}}"
                  showDelay="800"
                  tooltipPosition="top"
                  (click)="addRow(null, null, dt)"></button>
                <p-menu
                  appendTo="body"
                  #menu
                  [popup]="true"
                  styleClass="button-list"
                  [model]="buttonsDeleteList"></p-menu>
              </div>
            </div>
            <p-table
              #dt
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              editMode="row"
              dataKey="TaskRow"
              [value]="tasksDataSource"
              [responsive]="true"
              [rows]="10"
              [rowHover]="true"
              [reorderableColumns]="!projectEditForm.controls['IsCompleteInOrder'].value"
              [sortOrder]="1"
              [scrollable]="true"
              [scrollHeight]="'calc(50vh - 100px)'"
              [(selection)]="selectedItems"
              (onRowReorder)="onRowChange($event)">
              <ng-template pTemplate="caption">
                <!-- <div *ngIf="selectedItems.length > 0">
                             <p-splitButton label="{{ 'mgmt-delete' | translate }}" (onClick)="deleteItems()"
                                 [menuStyleClass]="'duplicate-menu'" class="p-text-left cursor-hand" styleClass="p-button-sm split-button-size"
                                 icon="fal fa-trash-alt" [model]="buttonsDeleteList">
                             </p-splitButton>
                         </div> -->
                <!-- <span *ngIf="selectedItems.length > 0">
                                  <label class="label-text p-mr-2 p-mb-2" style="display: inline-block;">{{
                                      'pm.batch_options' | translate }}</label>
                                  <button type="button" class="p-mr-4" pButton pRipple icon="pi pi-chevron-down"
                                      (click)="menu.toggle($event)"></button>
                              </span>
                              <span [ngClass]="(selectedItems.length > 0)? 'pull-right' :'p-d-flex p-jc-end'">
                                  <button pRipple pButton icon="fal fa-plus" class="p-button-sm" label="{{'Add-Row' | translate}}" pTooltip="{{'mgmt-add-new' | translate}}"
                                      showDelay="800" tooltipPosition="top" (click)="addRow(null, null, dt)">
                                  </button>
                              </span>
                              <p-menu appendTo="body" #menu [popup]="true" styleClass="button-list" [model]="buttonsDeleteList"></p-menu> -->
                <div class="table-header p-text-center" *ngIf="tasksDataSource.length > 0">
                  <span class="ic-size-16">{{totalProcessLen}}/{{tasksDataSource.length}}</span>
                  <span>
                    <p-progressBar
                      [value]="((totalProcessLen/tasksDataSource.length) * 100)"
                      [showValue]="false">
                    </p-progressBar>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-2p p-text-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="width-4p p-text-center">{{ 'actions' | translate }}</th>
                  <th class="width-3p p-text-center">{{ 'Reorder-Row' | translate }}</th>
                  <th class="width-3p p-text-center">{{ 'complete_task' | translate }}</th>
                  <th class="width-5p p-text-center">{{ 'task' | translate }} #</th>
                  <th
                    class="width-15p p-text-left required-field"
                    showDelay="800"
                    pTooltip="{{ 'Task-Description' | translate }}"
                    tooltipPosition="top">
                    {{ 'Task-Description' | translate }}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-7p p-text-center"
                    pTooltip="{{ 'due-date' | translate }} {{ 'offset_days' | translate }}"
                    tooltipPosition="top"
                    showDelay="800"
                    *ngIf="projectEditForm.get('automaticComputeDuedate').value == true">
                    {{ 'offset_days' | translate }}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-7p p-text-center"
                    pTooltip="{{ 'Completion-Date' | translate }}"
                    tooltipPosition="top"
                    showDelay="800">
                    {{ 'Completion' | translate }}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th *ngIf="showCapacityPlanning" class="width-7p p-text-center">
                    {{ 'scheduled-date' | translate }}
                  </th>
                  <th class="width-7p p-text-center">{{ 'due-date' | translate }}</th>
                  <th
                    class="width-10p p-text-left"
                    pTooltip="{{ 'Assigned-User' | translate }}"
                    tooltipPosition="top"
                    showDelay="800">
                    {{ 'Assigned' | translate }}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-5p p-text-center"
                    *ngIf="!isCreateFlow && ddmSettingsObj.isTrackingTimebyProjects">
                    {{ 'timer.add-timer' | translate }}
                  </th>
                  <th
                    class="width-3p p-text-center"
                    *ngIf="projectEditForm.get('Repeat').value != null && projectEditForm.get('Repeat').value !== 'none'"
                    pTooltip="{{ 'Repeat_Tooltip' | translate }}"
                    tooltipPosition="top">
                    {{ 'repeat' | translate }}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-rowIndex="rowIndex"
                let-editing="editing">
                <tr
                  [pReorderableRow]="rowIndex"
                  [pEditableRow]="rowData"
                  [ngStyle]="{'background-color':(!rowData.IsCompleted && !rowData.IsTaskReady && projectEditForm.get('IsCompleteInOrder').value == true)? '#ececec' : '' }">
                  <td class="width-2p p-text-center">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="width-4p p-text-center">
                    <i
                      class="pi pi-cog p-pr-2 cursor-hand ic-blue"
                      (click)="openTaskEmailAlert(rowData)"
                      pTooltip="{{ 'Task Email Alert Settings' | translate }}"
                      showDelay="800"
                      tooltipPosition="top"></i>
                    <i
                      class="fal fa-trash-alt p-pr-2 cursor-hand ic-red"
                      (click)="deleteRow($event,rowData)"
                      pTooltip="{{ 'Delete-Row' | translate }}"
                      showDelay="800"
                      tooltipPosition="top"></i>
                    <i
                      class="fa fa-sticky-note p-pr-2 cursor-hand"
                      [ngClass]="(rowData.TaskMemo === null || rowData.TaskMemo === '') ? 'ic-grey' : 'ic-folder-yellow'"
                      (click)="onShowScratchpad(rowData)"
                      pTooltip="{{ 'memo' | translate }}"
                      tooltipPosition="top"
                      showDelay="800"></i>
                    <i
                      class="fal fa-plus cursor-hand"
                      (click)="addRow(null, null, dt)"
                      pTooltip="{{ 'add_new_row' | translate }}"
                      showDelay="800"
                      id="new-row"
                      tooltipPosition="top"
                      *ngIf="((tasksDataSource.length-1) == rowIndex)"></i>
                  </td>
                  <td class="width-3p p-text-center">
                    <i
                      [ngClass]="projectEditForm.get('IsCompleteInOrder').value == false? ' cursor-hand' : 'notAllowed'"
                      class="fal fa-bars"
                      pReorderableRowHandle
                      pTooltip="{{ 'pm.reorder-tooltip' | translate }}"
                      showDelay="800"
                      tooltipPosition="top"></i>
                  </td>
                  <td class="width-3p p-text-center">
                    <p-checkbox
                      [(ngModel)]="rowData.IsCompleted"
                      [ngModelOptions]="{ standalone: true }"
                      pTooltip="{{ 'complete_task' | translate }}"
                      binary="true"
                      showDelay="800"
                      (onChange)="onChecked($event,rowData,rowIndex);projectEditForm.markAsDirty()"
                      name="itemIsCompleted"
                      id="itemIsCompleted"
                      #itemIsCompleted="ngModel"
                      [disabled]="!rowData.IsCompleted && !rowData.IsTaskReady && projectEditForm.get('IsCompleteInOrder').value == true">
                    </p-checkbox>
                  </td>
                  <td class="width-5p p-text-center p-px-3">
                    <div class="badge-number bg-blue">{{rowIndex+1}}</div>
                  </td>
                  <td
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'TaskDescription', rowIndex)"
                    [ngClass]="{'new-row': ((tasksDataSource.length-1) == rowIndex)}"
                    class="width-15p p-text-left p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{rowData['TaskDescription']}}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="text"
                          [(ngModel)]="rowData['TaskDescription']"
                          [ngModelOptions]="{standalone: true}"
                          (focus)="$event.target.select()"
                          class="tasks-row-edit task-desc-{{rowIndex}}"
                          (ngModelChange)="validateForm(true);projectEditForm.markAsDirty();onTaskDescriptionChange(rowData, rowIndex)"
                          required />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span>{{rowData['TaskDescription']}}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'OffsetDays', rowIndex)"
                    [ngClass]="{'new-row': ((tasksDataSource.length-1) == rowIndex)}"
                    class="width-5p p-text-center p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{ 'offset_days' | translate}}"
                    tooltipPosition="top"
                    showDelay="800"
                    *ngIf="projectEditForm.get('automaticComputeDuedate').value == true"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="number"
                          [(ngModel)]="rowData['dueDateOffsetDaysIndicator']"
                          [ngModelOptions]="{standalone: true}"
                          (focus)="$event.target.select()"
                          min="0"
                          class="tasks-row-edit offset-days-{{rowIndex}}"
                          (ngModelChange)="validateForm(true);projectEditForm.markAsDirty();onOffsetDaysChange($event, rowIndex)"
                          [disabled]="rowData.IsCompleted"
                          required />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span>{{rowData['dueDateOffsetDaysIndicator']}}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'CompletionDate', rowIndex)"
                    class="width-7p p-text-center p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{rowData['CompletionDate'] | date:'MM/dd/yyyy'}}{{ rowData['CompletedByStaffName'] ? ' - ' + rowData['CompletedByStaffName'] : '' }}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-calendar
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="rowData['CompletionDate']"
                          appendTo="body"
                          yearRange="2000:2099"
                          dateFormat="mm/dd/yy"
                          showButtonBar="true"
                          (onSelect)="validateForm(true)"
                          styleClass="tasks-row-edit"
                          panelStyleClass="tasks-row-edit"
                          [disabled]="!rowData.IsCompleted && !rowData.IsTaskReady && projectEditForm.get('IsCompleteInOrder').value == true"
                          inputStyleClass="tasks-row-edit complete-date-{{rowIndex}}"
                          (ngModelChange)="onCompletionDateChange(rowData, rowIndex);validateForm(true);projectEditForm.markAsDirty();">
                        </p-calendar>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span
                          >{{rowData['CompletionDate'] | date:'MM/dd/yyyy'}}{{
                          rowData['CompletionDate'] && rowData['CompletedByStaffName'] ? ' - ' +
                          rowData['CompletedByStaffName'] : '' }}</span
                        >
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td
                    *ngIf="showCapacityPlanning"
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'ScheduledDate', rowIndex)"
                    class="width-7p p-text-center p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{rowData['ScheduledDate'] | date:'MM/dd/yyyy'}}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-calendar
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="rowData['ScheduledDate']"
                          appendTo="body"
                          yearRange="2000:2099"
                          showButtonBar="true"
                          (onSelect)="validateForm(true)"
                          styleClass="tasks-row-edit"
                          panelStyleClass="tasks-row-edit"
                          inputStyleClass="tasks-row-edit scheduled-date-{{rowIndex}}"
                          (ngModelChange)="validateForm(true);projectEditForm.markAsDirty(); onDueDateChange($event, rowIndex);"
                          [disabled]="rowData.IsCompleted || (projectEditForm.get('automaticComputeDuedate').value == true && rowIndex > 0)">
                        </p-calendar>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span>{{rowData['ScheduledDate'] | date:'MM/dd/yyyy'}}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'DueDate', rowIndex)"
                    class="width-7p p-text-center p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{rowData['DueDate'] | date:'MM/dd/yyyy'}}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-calendar
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="rowData['DueDate']"
                          appendTo="body"
                          yearRange="2000:2099"
                          showButtonBar="true"
                          (onSelect)="validateForm(true)"
                          styleClass="tasks-row-edit"
                          panelStyleClass="tasks-row-edit"
                          inputStyleClass="tasks-row-edit due-date-{{rowIndex}}"
                          (ngModelChange)="validateForm(true);projectEditForm.markAsDirty(); onDueDateChange($event, rowIndex);"
                          [disabled]="rowData.IsCompleted || (projectEditForm.get('automaticComputeDuedate').value == true && rowIndex > 0)">
                        </p-calendar>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span>{{rowData['DueDate'] | date:'MM/dd/yyyy'}}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    pInitEditableRow
                    (click)="onRowEditInit(rowData, 'StaffName', rowIndex)"
                    class="width-10p p-text-left p-text-nowrap p-text-truncate p-fluid"
                    pTooltip="{{rowData['StaffNames']}}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <!-- <p-dropdown [(ngModel)]="rowData['UserAssigned']"
                                                  [options]="AllManagerTypes" name="itemUserAssigned"
                                                  placeholder="{{rowData['StaffName'] ? rowData['StaffName'] : 'Unassigned'}}" id="itemUserAssigned"
                                                  #itemUserAssigned="ngModel" appendTo="body"
                                                  [ngModelOptions]="{standalone: true}" styleClass="tasks-row-edit staff-name-{{rowIndex}}" panelStyleClass="tasks-row-edit"
                                                  (onChange)="handleDropSelectClick($event,rowData);projectEditForm.markAsDirty()">
                                              </p-dropdown> -->
                        <p-multiSelect
                          [(ngModel)]="rowData['UserAssignedIDArray']"
                          [options]="StaffOptionsForTasks"
                          placeholder="Unassigned"
                          (onChange)="handleDropSelectClick($event,rowData);projectEditForm.markAsDirty();"
                          appendTo="body"
                          id="itemUserAssigned"
                          name="itemUserAssigned"
                          [ngModelOptions]="{standalone: true}"
                          styleClass="tasks-row-edit staff-name-{{rowIndex}}"
                          panelStyleClass="tasks-row-edit"></p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{rowData['StaffNames'] ? rowData['StaffNames'] : 'Unassigned'}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="width-5p p-text-center"
                    *ngIf="!isCreateFlow && ddmSettingsObj.isTrackingTimebyProjects">
                    <i
                      class="fal fa-stopwatch cursor-hand ic-green"
                      (click)="openTimerDialog(rowData)"
                      pTooltip="{{ 'timer.add-timer' | translate }}"
                      showDelay="800"
                      tooltipPosition="top"></i>
                  </td>
                  <td
                    class="width-3p p-text-center"
                    *ngIf="projectEditForm.get('Repeat').value != null && projectEditForm.get('Repeat').value !== 'none'">
                    <p-checkbox
                      [(ngModel)]="rowData.IsRepeatTask"
                      [ngModelOptions]="{ standalone: true }"
                      pTooltip="{{ 'repeat' | translate }}"
                      binary="true"
                      showDelay="800"
                      (onChange)="onCheckedRepeat($event,rowData,rowIndex);projectEditForm.markAsDirty()"
                      [disabled]="projectEditForm.get('Repeat').value == null || projectEditForm.get('Repeat').value === 'none' || rowData['DueDate'] == null">
                    </p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9">
                    <p-message
                      severity="info"
                      text="{{ 'data_not_found' | translate }}."
                      styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
    <p-dialog
      [modal]="true"
      [(visible)]="isDisplayTemplateWildcardsDialog"
      [style]="{width: '35vw'}"
      [responsive]="true"
      (onHide)="isDisplayTemplateWildcardsDialog=false"
      [draggable]="false"
      [resizable]="false"
      showEffect="fade"
      [closable]="false"
      [baseZIndex]="1"
      [maximizable]="false">
      <p-header>{{ 'pm.template-name-with-wildcards' | translate }}</p-header>
      <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
        <div class="p-col-12 p-formgroup-inline p-mt-2">
          <div class="p-col-7">
            <div class="p-col-12 p-mt-2 p-d-flex p-jc-between">
              <label class="label-text">{{ "dm.Template_Name" | translate }}</label>
              <i
                class="fal fa-table ic-green cursor-hand ic-size-18 p-pr-2"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap"
                pTooltip="{{ 'pm.show_wildcards_table' | translate }}"
                pRipple
                (click)="isDisplayWildcardsTable=true"></i>
            </div>
            <div class="p-col-12 p-mt-2">
              <input
                class="width-100p p-text-left"
                type="text"
                pInputText
                formControlName="TemplateWildcards"
                (focus)="$event.target.select();" />
            </div>
          </div>
          <div class="p-col-5 p-mt-5">
            <p-checkbox
              formControlName="isPreviousPeriodYearPolicy"
              label="{{ 'pm.previous_period_year_policy' | translate }}"
              class="label-text p-mt-2"
              binary="true">
            </p-checkbox>
            <i
              class="fa fa-question-circle ic-blue"
              aria-hidden="true"
              pTooltip="{{ 'projectWildcardsToolTip' | translate}}"
              tooltipPosition="top"></i>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-end">
          <button
            type="button"
            pButton
            pRipple
            icon="fal fa-check"
            class="p-pr-2"
            label="{{ 'Save' | translate }}"
            (click)="onSaveTemplateWildcards()"
            [disabled]="projectEditForm.value?.TemplateWildcards?.length == 0 ||
                        (previousTemplateWildcards === projectEditForm.value?.TemplateWildcards &&
                        lastIsPreviousPeriodYearPolicy === projectEditForm.value?.isPreviousPeriodYearPolicy)"></button>
          <button
            pButton
            icon="fal fa-times"
            type="button"
            label="Cancel"
            (click)="onCloseTemplateWildcardsDialog()"></button>
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>

<p-sidebar
  [(visible)]="isFilePreview"
  [position]="'right'"
  styleClass="width-60p"
  [dismissible]="true"
  [showCloseIcon]="true"
  (onHide)="closePreviewDialog()"
  [closeOnEscape]="false">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <div
          #preiewviewer
          class="preiewviewer"
          [ngStyle]="{'visibility':isPreviewViewerShow? 'visible' : 'hidden' }"></div>
        <div #dummyViewer class="dummyViewer"></div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  [(visible)]="isShowProjectDocs"
  [position]="'right'"
  styleClass="width-60p"
  [dismissible]="true"
  [showCloseIcon]="true"
  (onHide)="onCloseProjectDocsSidebar()"
  [closeOnEscape]="false">
  <div class="p-grid">
    <div class="p-col-12 p-formgroup-inline p-mb-1"></div>
    <div class="p-col-12">
      <p-panel header="Documents" styleClass="p-text-right">
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <p-table
              [value]="projectDocuments"
              [responsive]="true"
              dataKey="dmsParentID"
              [sortOrder]="1"
              [scrollable]="true"
              scrollHeight="calc(90vh - 300px)"
              [rowHover]="true"
              #dtdoc
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
              <ng-template pTemplate="header">
                <tr>
                  <th class="width-7p p-text-center">{{ 'actions' | translate }}</th>
                  <th class="width-15p p-text-left">{{ 'File' | translate }}</th>
                  <th class="width-6p p-text-center">{{ 'time.type' | translate }}</th>
                  <th class="width-6p p-text-center">{{ 'Size' | translate }}</th>
                  <th class="width-8p p-text-left">{{ 'Uploaded-By' | translate }}</th>
                  <th class="width-8p p-text-center">{{ 'Created-On' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="width-7p p-text-center">
                    <i
                      class="fal fa-pencil p-mr-2 cursor-hand"
                      pTooltip="{{ 'Edit' | translate }}"
                      tooltipPosition="top"
                      (click)="editRow(rowData)"></i>
                    <i
                      class="fal fa-trash-alt p-mr-2 ic-red cursor-hand"
                      pTooltip="{{ 'mgmt-delete' | translate }}"
                      tooltipPosition="top"
                      (click)="deleteDocRow(rowData)"></i>
                    <i
                      class="fal fa-eye cursor-hand"
                      pTooltip="{{ 'preview' | translate }}"
                      tooltipPosition="top"
                      (click)="previewRow(rowData)"></i>
                  </td>
                  <td class="width-15p p-text-left">
                    <span class="project-link cursor-hand" (click)="previewRow(rowData)"
                      >{{rowData.FName}}</span
                    >
                  </td>
                  <td class="width-6p p-text-center">
                    <span>{{rowData.FileType}}</span>
                  </td>
                  <td class="width-6p p-text-center">
                    <span>{{rowData.Size}}</span>
                  </td>
                  <td
                    class="width-8p p-text-left p-text-nowrap p-text-truncate"
                    pTooltip="{{rowData['ModifyBy']}}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <span>{{rowData['ModifyBy']}}</span>
                  </td>
                  <td
                    class="width-8p p-text-center p-text-nowrap p-text-truncate"
                    pTooltip="{{rowData['CreatedOn'] | date:'MM/dd/yyyy' : 'UTC'}}"
                    tooltipStyleClass="tooltip-no-wrap"
                    tooltipPosition="top">
                    <span>{{rowData['CreatedOn'] | date:'MM/dd/yyyy' : 'UTC'}}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">
                    <p-message
                      severity="info"
                      text="{{ 'data_not_found' | translate }}."
                      styleClass="p-px-2">
                    </p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  styleClass="width-60p"
  [(visible)]="isShowProjectNotes"
  [position]="'right'"
  [dismissible]="true"
  [showCloseIcon]="true"
  [closeOnEscape]="false"
  (onShow)="initializeProjectNoteSidebar( );"
  (onHide)="onCloseProjectNotesSidebar( );">
  <div class="p-grid">
    <div class="p-col-12 p-formgroup-inline p-mb-1"></div>
    <div class="p-col-12">
      <p-panel header="Project Notes" styleClass="p-text-left">
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <app-mentions
              [rows]="5"
              [(inputText)]="noteHistory"
              style="width: 100% !important; resize: vertical"></app-mentions>
            <div class="p-d-flex p-jc-between p-mt-3">
              <div
                class="
                            "></div>
              <div class="p-d-flex p-jc-between" style="flex-grow: 1">
                <div class="p-mr-1">
                  <p-multiSelect
                    styleClass="email-multiselect"
                    defaultLabel="{{ 'send_email_to_users' | translate }}"
                    pTooltip="{{ 'send_email_to_users' | translate }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="projectNoteMailRecipientList"
                    [(ngModel)]="selectedUsers"
                    [virtualScroll]="true">
                  </p-multiSelect>
                </div>
                <div
                  class="
                              ">
                  <button
                    pButton
                    pRipple
                    class="p-button-sm"
                    icon="fal fa-check"
                    label="{{ 'save_button' | translate }}"
                    tooltipPosition="top"
                    [disabled]="noteHistory === ''"
                    (click)="saveNotes( )"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12" *ngIf="notesDataSource.length > 0">
            <p-scrollPanel styleClass="note-scroll">
              <div *ngIf="!isListView">
                <p-timeline [value]="notesDataSource" align="left" id="NotesTimeline">
                  <ng-template pTemplate="marker" let-event>
                    <span
                      class="custom-marker p-shadow-2"
                      *ngIf="event.StaffImage"
                      style="
                        border-radius: 30px;
                        height: 30px;
                        width: 30px;
                        box-shadow: 2px 2px 5px #7b7b7b;
                        text-align: center;
                        padding: 7.3px;
                        display: block;
                        background-size: cover;
                      "
                      [ngStyle]="{ 'background-image': 'url(' + event.StaffImage + ')'}">
                    </span>
                    <span
                      class="custom-marker p-shadow-2"
                      *ngIf="!event.StaffImage"
                      style="
                        border-radius: 30px;
                        height: 30px;
                        width: 30px;
                        box-shadow: 2px 2px 5px #7b7b7b;
                        text-align: center;
                        padding: 7.3px;
                      ">
                      <i class="fal fa-user-circle ic-size-15"></i>
                    </span>
                  </ng-template>
                  <ng-template pTemplate="content" let-event>
                    <p-card
                      subheader="{{event.StaffName}} - {{event.DateCreated | date:'medium'}}"
                      class="bg-notes"
                      [style]="{'padding': '0 15px 15px 15px', 'background-color': '#bfcdde61', 'border': '1px solid #aba3a3','border-radius': '5px', 'margin-bottom': '10px'}">
                      <ng-template pTemplate="title">
                        <span *ngIf="!event.isEdit" class="pull-right">
                          <i
                            class="fal fa-trash-alt ic-red cursor-hand p-pl-2 p-mr-1 ic-size-15"
                            pTooltip="{{ 'mgmt-delete' | translate }}"
                            tooltipPosition="left"
                            aria-hidden="true"
                            (click)="deleteNotes(event.ProjectNotesID)"></i>
                          <i
                            class="fal fa-pencil-alt ic-green cursor-hand p-pl-2 ic-size-15"
                            pTooltip="{{ 'Edit' | translate }}"
                            tooltipPosition="left"
                            aria-hidden="true"
                            (click)="event.isEdit = true"></i>
                        </span>
                        <span *ngIf="event.isEdit" class="pull-right">
                          <i
                            class="fal fa-check ic-green cursor-hand p-pl-2 ic-size-15"
                            pTooltip="{{ 'save_button' | translate }}"
                            tooltipPosition="left"
                            aria-hidden="true"
                            (click)="saveNote(event)"></i>
                        </span>
                      </ng-template>
                      <!-- <ng-template pTemplate="subtitle">
                                                <div>
                                                    <span class="timeline-property-container p-mr-2">
                                                        {{event.StaffName}}
                                                    </span>
                                                    <span class="timeline-property-container p-mr-2">
                                                        {{event.DateCreated | date:'medium'}}"
                                                    </span>
                                                </div>
                                            </ng-template> -->
                      <div *ngIf="!event.isEdit" class="p-mt-2 p-ml-2">
                        <p>{{event.NoteEdited}}</p>
                      </div>
                      <div *ngIf="event.isEdit" class="p-mt-2 p-ml-2">
                        <app-mentions
                          [rows]="5"
                          [(inputText)]="event.NoteEdited"
                          id="desc_{{event.ProjectsNoteID}}"
                          style="width: 100% !important; resize: vertical"></app-mentions>
                      </div>
                    </p-card>
                  </ng-template>
                  <ng-template pTemplate="opposite" let-event>
                    <div style="display: none"></div>
                  </ng-template>
                </p-timeline>
              </div>
            </p-scrollPanel>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</p-sidebar>

<p-overlayPanel #op2 [showCloseIcon]="true" [dismissable]="true">
  <div *ngFor="let item of tagsList;">
    <div
      class="p-text-center tagpop text-nowrap"
      [class.disabled]="isDisableTag(item.value)"
      [style.color]="item.textColor"
      [style.background-color]="item.color && item.color !== '' ? item.color : null"
      (click)="addTagItem(item.value);">
      {{item.label}}
    </div>
  </div>
</p-overlayPanel>
<p-overlayPanel #op3 [showCloseIcon]="false" [dismissable]="true">
  <div *ngFor="let item of dueDateList;">
    <div
      class="p-text-center p-text-nowrap cursor-hand"
      (click)="changeExtensionDate(item.value);clearAddTag('Add','Edate');">
      {{item.label}}
    </div>
  </div>
</p-overlayPanel>
<div *ngIf="isDisplayDetailsTable">
  <app-create-project-from-template (onClose)="onCloseTemplateDialog()">
  </app-create-project-from-template>
</div>

<div *ngIf="isDisplayWildcardsTable">
  <app-pm-wildcards-dialog (onClose)="isDisplayWildcardsTable=false"> </app-pm-wildcards-dialog>
</div>

<p-sidebar [(visible)]="showAttachedFiles" position="bottom" [style]="{'min-height':'300px'}">
  <div class="card">
    <div class="header-color text-nowrap col-sm-9">
      <h4 class="label-text">{{ 'dm.Upload_Files' | translate }}</h4>
    </div>
    <div class="p-grid p-mx-2">
      <div class="card flex justify-content-center" style="width: 100%">
        <p-fileUpload
          name="fileupload"
          [disabled]="!selClient?.ClientID || !projectEditForm.value.TemplateName"
          #fileUpload
          [auto]="true"
          chooseLabel="Upload"
          (onSelect)="onSelect($event)"
          [multiple]="true"
          accept="text/csv"
          maxFileSize="3145728">
          <ng-template pTemplate="content">
            <div class="p p-d-flex p-jc-center" *ngIf="files.length == 0" style="height: 40px">
              Drag file(s) here
            </div>
            <ul *ngIf="files.length > 0">
              <li *ngFor="let file of files">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
  </div>
</p-sidebar>

<p-dialog
  header="{{'alerts.project_email_alerts_defaults' | translate }}"
  [(visible)]="showProjectEmailAlerts"
  [modal]="true"
  [style]="{width: '600px'}"
  [responsive]="true"
  [draggable]="true"
  [resizable]="false"
  showEffect="fade"
  [baseZIndex]="1"
  [maximizable]="false"
  (onHide)="showProjectEmailAlerts = false">
  <div class="p-col-12">
    <p-checkbox
      class="label-text"
      [(ngModel)]="projectEmailAlerts.OverrideFirmDefaults"
      binary="true"
      label="{{ 'Override Firm Defaults?' | translate }}"
      (onChange)="onOverrideProjectFirmDefaults($event);projectEmailAlerts.IsChanged = true;">
    </p-checkbox>
    <i
      class="pi pi-cog p-ml-3 cursor-hand ic-blue"
      pTooltip="{{ 'alerts.go_to_project_settings' | translate }}"
      (click)="redirectToProjectSettings()"
      showDelay="800"
      tooltipPosition="top"></i>
  </div>
  <div class="p-grid p-ml-3 p-mt-2">
    <div class="p-col-12" style="font-size: 11px; font-weight: 400">
      <p class="ic-red" *ngIf="!projectEmailAlerts.OverrideFirmDefaults">
        {{ 'alerts.firm_defaults_used' | translate }}
      </p>
    </div>
    <div class="p-col-12">
      <p-checkbox
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults"
        class="label-text"
        [(ngModel)]="projectEmailAlerts.DaysBeforePastDueDateBool"
        binary="true"
        label="{{ 'alerts.notify_days_before_due_date' | translate }}"
        (onChange)="toggleCheckBoxWithInt($event, projectEmailAlerts, 'DaysBeforePastDueDate');projectEmailAlerts.IsChanged = true;">
      </p-checkbox>
    </div>
    <div class="p-field p-col-8 p-ml-5">
      <label
        class="p-col-12 p-mb-1"
        [ngClass]="!projectEmailAlerts.OverrideFirmDefaults || !projectEmailAlerts.DaysBeforePastDueDateBool? 'label-text-disabled' : 'label-text'"
        >{{'alerts.number_of_days_before_due_date' | translate}}</label
      >
      <p-inputNumber
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults || !projectEmailAlerts.DaysBeforePastDueDateBool"
        maxlength="3"
        (click)="$event.target.select()"
        autocomplete="off"
        class="p-ml-3"
        (ngModelChange)="projectEmailAlerts.IsChanged = true;"
        [(ngModel)]="projectEmailAlerts.DaysBeforePastDueDate"
        mode="decimal"
        inputStyleClass="p-text-right"
        placeholder="0"></p-inputNumber>
    </div>
    <div class="p-col-12">
      <p-checkbox
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults"
        class="label-text"
        [(ngModel)]="projectEmailAlerts.PastDue"
        binary="true"
        label="{{ 'alerts.notify_when_past_due' | translate }}"
        (onChange)="toggleCheckBoxWithInt($event, projectEmailAlerts, 'ProjectDaysToRemind');projectEmailAlerts.IsChanged = true;">
      </p-checkbox>
    </div>
    <div class="p-field p-col-8 p-ml-5">
      <label
        class="p-col-12 p-mb-1"
        [ngClass]="!projectEmailAlerts.OverrideFirmDefaults || !projectEmailAlerts.PastDue? 'label-text-disabled' : 'label-text'"
        >{{'alerts.interval_days_after_due_date' | translate}}</label
      >
      <p-inputNumber
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults || !projectEmailAlerts.PastDue"
        maxlength="3"
        (click)="$event.target.select()"
        autocomplete="off"
        class="p-ml-3"
        (ngModelChange)="projectEmailAlerts.IsChanged = true;"
        [(ngModel)]="projectEmailAlerts.ProjectDaysToRemind"
        mode="decimal"
        inputStyleClass="p-text-right"
        placeholder="0"></p-inputNumber>
    </div>
    <div class="p-col-12">
      <p-checkbox
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults"
        class="label-text"
        [(ngModel)]="projectEmailAlerts.isNotifyManager"
        binary="true"
        label="{{ 'alerts.notify_manager' | translate }}"
        (onChange)="projectEmailAlerts.IsChanged = true;">
      </p-checkbox>
    </div>
    <div class="p-col-12">
      <p-checkbox
        [disabled]="!projectEmailAlerts.OverrideFirmDefaults"
        class="label-text"
        [(ngModel)]="projectEmailAlerts.isNotifyBillingPartner"
        binary="true"
        label="{{ 'alerts.notify_billing_partner' | translate }}"
        (onChange)="projectEmailAlerts.IsChanged = true;">
      </p-checkbox>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      icon="fas fa-check"
      class="p-pr-2"
      label="{{ 'Save' | translate }}"
      (click)="saveProjectEmailAlerts()"
      [disabled]="!projectEmailAlerts.IsChanged"></button>
    <button
      pButton
      type="button"
      label="Cancel"
      (click)="showProjectEmailAlerts = false"
      class="p-pr-2"></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Select Client Folders"
  [(visible)]="showClientFolders"
  [modal]="true"
  [style]="{width: '50vw'}"
  [responsive]="true"
  [draggable]="true"
  [resizable]="false"
  showEffect="fade"
  [baseZIndex]="1"
  [maximizable]="false"
  (onHide)="showClientFolders = false">
  <div class="card" style="height: 50vh">
    <p-tree
      selectionMode="single"
      (onNodeSelect)="nodeSelect($event)"
      [scrollHeight]="'calc(50vh - 100px)'"
      [value]="clientFiles"></p-tree>
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      icon="fas fa-check"
      class="p-pr-2"
      label="{{ 'Link to Client' | translate }}"
      [disabled]="!dmsParentIDToLink && !parentSelected"
      (click)="linkToClientDMSFolder()"></button>
    <button
      pButton
      type="button"
      label="Cancel"
      (click)="showClientFolders = false"
      class="p-pr-2"></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Clone Project to Another Client"
  [(visible)]="showCloneModal"
  [modal]="true"
  [style]="{width: '25vw'}"
  [responsive]="true"
  [draggable]="true"
  [resizable]="false"
  showEffect="fade"
  [baseZIndex]="1"
  [maximizable]="false"
  (onHide)="showCloneModal = false">
  <div class="p-col-12 p-pl-3 ic-size-15">
    <label class="label-text p-mb-2"> {{ 'client.name' | translate }}: </label>
    <p-autoComplete
      [(ngModel)]="selClientClone"
      [ngModelOptions]="{standalone: true}"
      [inputStyle]="{'font-weight': '700 !important'}"
      [forceSelection]="true"
      autocomplete="off"
      [suggestions]="filteredClients"
      styleClass="width-100p p-mt-2"
      appendTo="body"
      (completeMethod)="filterClients($event)"
      field="ClientName"
      (onSelect)="handleSelectCloneClient($event);"
      ngDefaultControl
      placeholder="Type Client Name.."
      #selclientID="ngModel"
      [dropdown]="true"
      required>
      <ng-template let-client pTemplate="item">
        <div>
          {{ client.ClientName }}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      icon="fal fa-copy"
      class="p-pr-2"
      label="{{ 'pm.Clone-Project' | translate }}"
      [disabled]="!!!selClientClone?.ClientID"
      (click)="handleCloneProject()"></button>
    <button
      pButton
      type="button"
      label="Cancel"
      (click)="showCloneModal = false"
      class="p-pr-2"></button>
  </ng-template>
</p-dialog>

<!-- ScratchPad sidebar -->
<p-sidebar
  [(visible)]="scratchPadEnabled"
  position="bottom"
  [style]="{'min-height':'522px','background':'#8e8f92'}">
  <div class="card">
    <div class="control-label header-color text-nowrap p-col-12">
      <h4 class="label-text">{{ 'Task-Memo' | translate }}</h4>
    </div>
    <div class="p-col-12 col-md-12">
      <ejs-richtexteditor
        id="clientEditor"
        #clientEditor
        (change)="onScratchpadChange()"
        [(value)]="editorValue"
        [toolbarSettings]="tools"
        [height]="400">
      </ejs-richtexteditor>
    </div>
  </div>
</p-sidebar>

<p-dialog
  header="New Message"
  showEffect="fade"
  [(visible)]="isEmailDialog"
  [modal]="true"
  [style]="{width: '50vw'}"
  [responsive]="true"
  [draggable]="true"
  [resizable]="false"
  [baseZIndex]="1"
  [maximizable]="false"
  (onShow)="initializeSendClientProjectNoteMail( );"
  (onHide)="closeEmail( );">
  <div class="p-fluid">
    <div class="p-formgroup-inline">
      <div class="p-field p-col-6 p-pl-0">
        <label for="cc" class="label-text"> {{ 'From' | translate }}: </label>
        <input
          pInputText
          id="cc"
          type="email"
          [(ngModel)]="emailTemplate['from']"
          (ngModelChange)="validateEmailForm( );" />
      </div>
      <div class="p-field p-col-6 p-pr-0">
        <label for="to" class="label-text"> {{ 'To' | translate }}: </label>
        <input
          pInputText
          id="to"
          type="email"
          [(ngModel)]="emailTemplate['to']"
          (ngModelChange)="validateEmailForm( );"
          required />
      </div>
    </div>
    <div class="p-field">
      <label for="bcc" class="label-text"> {{ 'Subject' | translate }}: </label>
      <input
        pInputText
        type="email"
        id="bcc"
        [(ngModel)]="emailTemplate['subject']"
        (ngModelChange)="validateEmailForm( );" />
    </div>
    <div class="p-field richtext">
      <ejs-richtexteditor
        [height]="300"
        [toolbarSettings]="emailTools"
        [(value)]="emailEditorValue"
        (ngModelChange)="validateEmailForm( );">
      </ejs-richtexteditor>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      class="p-pr-2"
      icon="fas fa-check"
      type="button"
      label="{{ 'Send' | translate }}"
      [disabled]="!isUserSettingsTouched"
      (click)="sendAnEmailForClient( )"></button>
    <button pButton class="p-pr-2" type="button" label="Cancel" (click)="closeEmail( );"></button>
  </ng-template>
</p-dialog>

<p-sidebar
  [(visible)]="isDisplayRuleDialog"
  position="right"
  [style]="{'min-width':'60%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card">
    <div style="height: 1px; color: #fff">
      <div id="rule-output" style="height: 1px; color: #fff">{{projHeaderRule}}</div>
    </div>
    <div id="RecurrenceEditor"></div>
    <ejs-recurrenceeditor
      #recurrenceObj
      [value]="projHeaderRule"
      [selectedType]="selectedType"
      (change)="onChange($event)"></ejs-recurrenceeditor>
    <div class="project-details"></div>
    <div class="p-my-2" *ngIf="ruledateSource">
      <span class="label-text">{{ 'Current-Due-Date' | translate }}: </span>
      <span><strong> {{ruledateSource | date: "MM/dd/yyyy"}} </strong></span>
    </div>
    <div class="p-my-2" *ngIf="ruleTwodateSource">
      <span class="label-text">{{ 'Next-Due-Date' | translate }} : </span>
      <span><strong> {{ruleTwodateSource | date: "MM/dd/yyyy"}} </strong></span>
      <br />
      <small
        *ngIf="rule && (convertUTCDate(ruleTwodateSource) != convertUTCDate(rule.all()[1]))"
        class="p-pt-2 p-text-italic"
        >{{ "pm.next-due-date-adjusted" | translate }}</small
      >
    </div>

    <div class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns">
      <button
        type="button"
        pButton
        label="{{'Apply' | translate}}"
        icon="fal fa-check"
        class="p-mr-2"
        (click)="createRule()"
        [disabled]="!ruledateSource"></button>
      <button
        type="button"
        pButton
        label="{{'Cancel' | translate}}"
        icon="fal fa-times"
        (click)="isDisplayRuleDialog = false"></button>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  [(visible)]="isShowBudgetsDialog"
  position="right"
  (onHide)="onCloseBudgetDialog()"
  [baseZIndex]="2"
  [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" *ngIf="isShowBudgetsDialog">
    <app-budgets [projectBudgetObj]="budgetObject" [isFromProject]="true"></app-budgets>
  </div>
</p-sidebar>
