<div class="manage-accounts">
  <div class="layout-content p-px-3">
      <div class="p-grid p-p-0">
          <div class="p-col-12 p-d-flex p-jc-between p-pb-1 p-pr-5">
              <div
                class="width-21p p-pr-1 p-fluid"
              >
                <p-autoComplete
                  id="companyID"
                  name="companyID"
                  placeholder="Search Company"
                  field="CompanyName"

                  #company="ngModel"

                  [suggestions]="filteredCompanyList"
                  [minLength]="1"
                  [dropdown]="true"

                  [(ngModel)]="companySel"

                  (onSelect)="handleCompanySelect($event)"
                  (completeMethod)="filterCompanyList($event)"

                  required
                >
                  <ng-template
                    let-row
                    pTemplate="item"
                  >
                    <div
                      class="ui-helper-clearfix"
                    >
                      {{ row.CompanyName + " - " + row.CompanyID }}
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
              <div>
                  <p-button pRipple label="Save" icon="fal fa-check" styleClass="p-button-sm bg-salmon" [disabled]="!cardChanged"
                      class="p-button-help" *ngIf="companySel?.CompanyID" (click)="cardChanged ? onSave() : null"></p-button>
                  <p-button pRipple label="Super Admin Tool" icon="fal fa-tools" styleClass="p-button-sm p-ml-2"
                      class="p-button-help" *ngIf="companySel?.CompanyID" (click)="onShowSuperAdminTool()"></p-button>
                  <p-button pRipple label="Update Stax Enums" icon="fal fa-list" styleClass="p-button-sm  p-ml-2"
                      class="p-button-help"  (click)="onShowUpdateStaxEnums()"></p-button>
                  <p-button pRipple label="Register Company" icon="fal fa-user-plus" styleClass="p-button-sm  p-ml-2"
                  class="p-button-help" *ngIf="!companySel?.CompanyID" (click)="logoutAndRegister()"></p-button>
                  <p-button pRipple label="Reset Records" icon="fal fa-check" styleClass="p-button-sm  p-ml-2"
                  class="p-button-help" *ngIf="companySel?.CompanyID" (click)="onResetRecords()"></p-button>
                  <p-button pRipple label="Delete" icon="fal fa-trash-alt" styleClass="p-button-sm p-ml-2"
                      class="p-button-help" [disabled]="!(companySel?.CompanyID)" *ngIf="companySel?.CompanyID && superAdminDeleteVisible.includes(staffId)" (click)="onDelete()"></p-button>
                  <p-button pRipple label="Update Client Contacts" icon="fal fa-address-book" styleClass="p-button-sm p-ml-2"
                      class="p-button-help" [disabled]="!(companySel?.CompanyID)" *ngIf="companySel?.CompanyID" (click)="onUpdateClientContact()"></p-button>
                  <p-button pRipple label="Export Documents to IShare" icon="fal fa-file-export" styleClass="p-button-sm p-ml-2"
                      class="p-button-help" [disabled]="!(companySel?.CompanyID)" *ngIf="companySel?.CompanyID" (click)="onExportDocumentsToIShare()"></p-button>
              </div>
          </div>
          <div class="super-admin-manage" *ngIf="!companySel?.CompanyID">Super Admin</div>
          <div class="p-col-12 p-pt-0">
              <div class="p-grid width-100p p-mt-1 p-pl-2 p-pr-3 p-d-flex p-jc-between" *ngIf="companySel?.CompanyID">
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25" *ngIf="!editableTexts.plan" (click)="onLabelClick('plan')">
                              {{ companyDetails.SubscriptionLevel }}
                          </div>
                          <div class="p-text-left p-fluid" *ngIf="editableTexts.plan">
                              <p-dropdown [options]="plans" [(ngModel)]="companyDetails.SubscriptionLevel" name="subscriptionLevelID" id="subscriptionLevelID" #subscriptionLevelID="ngModel" styleClass="plan-dropdown" (onChange)="handleChangedCard($event,'SubscriptionLevel')"
                                  appendTo="body" [style]="{'width':'200px !important'}"></p-dropdown>
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Plan</div>
                      </div>
                  </div>
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25" *ngIf="!editableTexts.users" (click)="onLabelClick('users')">
                              {{ companyDetails.NumberofUsers ? companyDetails.NumberofUsers : 0 }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.users">
                            <p-inputNumber  [(ngModel)]="companyDetails.NumberofUsers" 
                                            styleClass="p-text-right"
                                            inputId="users" 
                                            mode="decimal" 
                                            [min]="1" 
                                            maxlength="3" 
                                            [allowEmpty]="false" 
                                            (onBlur)="handleChangedCard($event,'NumberofUsers')" 
                                            [showButtons]="true" 
                                            (onFocus)="$event.target.select()"/>
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">User(s)</div>
                      </div>
                  </div>
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25" (click)="onLabelClick('trial')" *ngIf="!editableTexts.trial">
                              {{ companyDetails.DateSubscribed ? (companyDetails.DateSubscribed | date:'MM/dd/YYYY') : 'N/A' }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.trial">
                              <p-calendar [(ngModel)]="companyDetails.DateSubscribed" showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" (ngModelChange)="handleChangedCard($event,'DateSubscribed')" appendTo="body">
                              </p-calendar>
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Trial End Date</div>
                      </div>
                  </div>
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25" (click)="onLabelClick('datePlan')" *ngIf="!editableTexts.datePlan">
                              {{ companyDetails.DatePlanActivated ? (companyDetails.DatePlanActivated | date:'MM/dd/YYYY') : 'N/A' }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.datePlan">
                              <p-calendar [(ngModel)]="companyDetails.DatePlanActivated" showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" (ngModelChange)="handleChangedCard($event,'DatePlanActivated')" appendTo="body">
                              </p-calendar>
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Plan Date</div>
                      </div>
                  </div>
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25" (click)="onLabelClick('dateCancelled')" *ngIf="!editableTexts.dateCancelled">
                              {{ companyDetails.DateCancelled ? (companyDetails.DateCancelled | date:'MM/dd/YYYY') : 'N/A' }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.dateCancelled">
                              <p-calendar [(ngModel)]="companyDetails.DateCancelled" showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" (ngModelChange)="handleChangedCard($event,'DateCancelled')" appendTo="body">
                              </p-calendar>
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Cancel Date</div>
                      </div>
                  </div>
                  <div class="width-16p">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25">
                              {{ companyDetails.CompanyID }}
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Company ID</div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-red ic-size-25" (click)="onLabelClick('cost')" *ngIf="!editableTexts.cost">
                              {{ changeUSMoneyData(companyDetails.BaseSubscriptionAmount) }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.cost">
                              <input pInputText class="p-text-right" [(ngModel)]="companyDetails.BaseSubscriptionAmount" (focus)="$event.target.select();" (blur)="changeUSMoney($event,companyDetails.BaseSubscriptionAmount);" (focus)="$event.target.select()" (blur)="handleChangedCard($event,'BaseSubscriptionAmount')">
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">
                              Cost
                              <i class="fa fa-question-circle ic-blue" pTooltip="Per User Cost" tooltipPosition="top"></i>
                          </div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-red ic-size-25" (click)="onLabelClick('monthly')" *ngIf="!editableTexts.monthly" pTooltip="Annual Amount: {{ changeUSMoneyData(companyDetails.AnnualAmount) }}" tooltipPosition="top">
                              {{ changeUSMoneyData(companyDetails.MonthlyBillingAmount) }}
                          </div>
                          <!-- <div class="p-text-right p-fluid" *ngIf="editableTexts.monthly">
                              <input pInputText [(ngModel)]="companyDetails.MonthlyBillingAmount" (focus)="$event.target.select();" (blur)="changeUSMoney($event,companyDetails.MonthlyBillingAmount);" (focus)="$event.target.select()" (blur)="handleChangedCard($event,'MonthlyBillingAmount')">
                          </div> -->
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Monthly Cost</div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-25"  (click)="onLabelClick('elClientLimit')" *ngIf="!editableTexts.elClientLimit">
                              {{ companyDetails.ELClientLimit ? companyDetails.ELClientLimit : 0 }}
                          </div>
                          <div class="p-text-right p-fluid" *ngIf="editableTexts.elClientLimit">
                            <p-inputNumber  [(ngModel)]="companyDetails.ELClientLimit" 
                                            styleClass="p-text-right" 
                                            (onBlur)="handleChangedCard($event,'ELClientLimit')" 
                                            inputId="users" 
                                            [allowEmpty]="false" 
                                            [showButtons]="true" 
                                            (onFocus)="$event.target.select()" />
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Engagement Letters Client Limit</div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-size-25 ic-blue">
                              {{ lastInvoiceDate ? (lastInvoiceDate | date:'MM/dd/YYYY') : "N/A" }}
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Last Invoice Date</div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-size-25 ic-blue">
                              {{ lastTimeRecordDate ? (lastTimeRecordDate | date:'MM/dd/YYYY') : "N/A" }}
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Last Time Record Date</div>
                      </div>
                  </div>
                  <div class="width-16p p-pt-2">
                      <div class="card p-px-2 p-pb-1">
                          <div class="p-text-center p-text-bold ic-size-25 ic-blue">
                              {{ companyDetails.Telephone }}
                          </div>
                          <div class="p-text-right ic-black p-pt-3 ic-size-14">Telephone #</div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <div class="p-col-12">
              <div class="grid">
                  <div class="p-col-4">
                      <div class="box p-p-1">
                          <div class="p-text-center p-text-bold ic-blue ic-size-30">
                              $40
                          </div>
                          <div class="p-text-right ic-black ic-size-16">Per User Cost</div>
                      </div>
                  </div>
                  <div class="p-col-4">
                      <div class="box p-p-3">
                          <div class="p-text-center p-text-bold ic-blue ic-size-30">
                              $200
                          </div>
                          <div class="p-text-right ic-black ic-size-16">Monthly Cost</div>
                      </div>
                  </div>
                  <div class="p-col-4">
                      <div class="card p-p-3">
                          <div class="p-text-center p-text-bold ic-blue ic-size-30">
                              $2,400
                          </div>
                          <div class="p-text-right ic-black ic-size-16">Annual Cost</div>
                      </div>
                  </div>
              </div>
          </div> -->

          <div class="p-col-12 p-pb-0 p-pr-5 p-pt-0" *ngIf="companySel?.CompanyID">
              <div class="card p-pt-0">
                  <div class="p-col-12 p-pb-0 p-d-flex p-jc-between">
                      <div>
                          <h3 class="ic-black p-mb-0">Staff Information</h3>
                      </div>
                      <div>
                          <!-- <p-button pRipple label="Save" icon="fal fa-plus" styleClass="p-button-sm"
                              class="p-button-help p-pr-2" (click)="onSaveStaff()"
                              [disabled]="getChangedRows().length === 0"></p-button>
                          <p-button pRipple label="Edit" icon="fal fa-plus" styleClass="p-button-sm"
                              class="p-button-help" (click)="onEditStaff()"></p-button> -->
                          &nbsp;
                      </div>
                  </div>
                  <div class="p-col-12">
                      <p-table [value]="staffDataSource" [responsive]="true"
                          [scrollHeight]="'calc(90vh - 480px)'" [virtualScroll]="true" [scrollable]="true"
                          dataKey="staffID" [rowHover]="true" #dt
                          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                          <ng-template pTemplate="header">
                              <tr>
                                  <th class="width-15p p-text-left">
                                      Staff Name
                                  </th>
                                  <th class="width-15p p-text-left">Email</th>
                                  <th class="width-8p p-text-center">Permission Level</th>
                                  <th class="width-12p p-text-left" pTooltip="Password's length should be greater than 2" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">Password <i class="fa fa-question-circle ic-blue"></i></th>
                                  <th class="width-4p p-text-center">&nbsp;</th>
                                  <th class="width-5p p-text-center">&nbsp;</th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                              <tr>
                                  <td class="p-text-left p-text-nowrap p-text-truncate width-15p" pTooltip="{{rowData.name}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                      {{rowData.StaffName}}
                                  </td>
                                  <td class="p-text-left p-text-nowrap p-text-truncate width-15p" pTooltip="{{rowData.email}}" showDelay="800" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                      {{rowData.Email}}
                                  </td>
                                  <td class="p-text-center width-8p">
                                      {{rowData.PermissionDescription}}
                                  </td>
                                  <td pEditableColumn class="p-text-left p-text-nowrap p-text-truncate width-12p">
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <input type="text" onfocus="this.type='password'" autocomplete="new-password" maxlength="80" pInputText [(ngModel)]="rowData['SystemPassword']" (focus)="$event.target.select()" (ngModelChange)="onEditPassword(rowData)" class="p-text-left text-ellipse">
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              ******
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                                  <td class="p-text-center width-4p">
                                      <p-button pRipple label="Login" styleClass="p-button-sm" class="p-button-help p-pr-2" (click)="onLogin(rowData)"></p-button>
                                  </td>
                                  <td class="p-text-center width-5p">
                                      <p-button pRipple label="Save" icon="fal fa-check" styleClass="p-button-sm" [disabled]="!rowData['IsRowChanged']" class="p-button-help p-pr-2" (click)="onSaveStaff(rowData)"></p-button>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="4">
                                      <p-message severity="info" text="Data not found." styleClass="p-px-2">
                                      </p-message>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>
                  </div>
              </div>

          </div>
      </div>
      <p-dialog header="Confirmation"
          [(visible)]="showPasswordDialog" [modal]="true" [style]="{width: '25vw'}" [maximizable]="false"
          showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false">

          <div class="p-formgrid p-grid common-dialog">
              <div class="p-col-12">
                  <label class="label-text p-ml-2">Type in the full name of the company you want to delete.</label>
                  <div class="p-fluid p-col p-mt-2">
                      <input type="text" pInputText [(ngModel)]="companyName" [ngModelOptions]="{standalone: true}" class="p-mb-2 p-text-left" name="companyName" autocomplete="off"
                          placeholder="Enter Company Name" />
                  </div>
              </div>
          </div>

          <ng-template pTemplate="footer">
              <button type="button" pButton pRipple label="Delete" icon="fal fa-trash-alt"
                  class="p-button-sm p-button-danger" (click)="onConfirmDelete()"
                  [disabled]="companyName?.length == 0"></button>
              <button type="button" pButton pRipple icon="fal fa-times" (click)="closePasswordDialog()"
                  class="p-button-sm" label="{{ 'cancel_button' | translate }}"></button>
          </ng-template>

      </p-dialog>

  </div>
  <p-sidebar [(visible)]="isShowSuperAdminTool" position="right" (onHide)="isShowSuperAdminTool=false"
      [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
      <div class="card" style="min-height: calc(100vh - 35px)">
          <label class="label-text ic-size-18 p-mb-3">Super Admin Tool</label>
          <div class="p-mb-3 p-mt-2">
              <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
          </div>
          <div *ngIf="viewType==tabValues.INVOICE">
            <p-table #dtInvoices [value]="invoices" 
                    [rowHover]="true" 
                    dataKey="BillingHeaderID"
                    [pageLinks]="10" 
                    [filterDelay]="0" 
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [sortOrder]="-1" 
                    sortField="InvoiceDate"
                    [scrollable]="true"
                    [scrollHeight]="'calc(100vh - 350px)'" 
                    [paginator]="true" 
                    [rows]="invoicesRows" 
                    [totalRecords]="invoicesTotalRecords" 
                    [rowsPerPageOptions]="[10,25,50,100,500,1000]" 
                    currentPageReportTemplate= "Page {currentPage} of {totalPages}"
                    paginatorPosition="top"
                    [showCurrentPageReport] = "true"
                    [lazy]="true" 
                    (onLazyLoad)="loadInvoicesLazy($event)">
                  <ng-template pTemplate="caption">
                      <div class="table-header">
                          <span class="p-input-icon-left">
                              <i class="fal fa-search"></i>
                              <input #searchValueInvoice pInputText type="text" (keyup)="onChangeInputTextInvoices($event)" placeholder="{{ 'globalsearch' | translate }}" />
                          </span>
                          <span>
                              <button pButton pRipple type="button" icon="pi pi-times"
                                  class="p-button-rounded p-button-danger p-button-text"
                                  (click)="clearSearchFilterInvoice()" tooltipPosition="top"
                                  pTooltip="{{ 'clear' | translate }}"></button>
                          </span>
                          <p-message *ngIf="filteredItemsSizeInvoice > -1" severity="success"
                              text="{{filteredItemsSizeInvoice}} Record(s) found." styleClass="p-ml-2"></p-message>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                          <th pSortableColumn="ClientName" pResizableColumn class="width-8p p-text-left">
                              Client
                              <p-sortIcon field="ClientName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="InvoiceDate" pResizableColumn class="width-8p p-text-left">{{
                              'Accounts.invoice-date' | translate }}
                              <p-sortIcon field="InvoiceDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="InvoiceNumber" pResizableColumn class="width-8p p-text-left">{{
                              'Accounts.invoice-number' | translate }}
                              <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                          </th>
                          <th pResizableColumn class="width-20p p-text-left">{{
                              'billing-invoicing.invoice-description' | translate }}</th>
                          <th pResizableColumn class="width-7p p-text-right">Invoice Amount</th>
                          <th pResizableColumn class="width-7p p-text-right">Discount</th>
                          <th pResizableColumn class="width-7p p-text-right">TotalPayments
                          </th>
                          <th pSortableColumn="InvoiceBalance" pResizableColumn class="width-7p p-text-right">
                              Balance
                              <p-sortIcon field="InvoiceBalance"></p-sortIcon>
                          </th>
                          <th pSortableColumn="TotalAmountApplied" pResizableColumn class="width-7p p-text-right">
                              Total Amount Applied (Payment detail)
                              <p-sortIcon field="TotalAmountApplied"></p-sortIcon>
                          </th>
                          <th pSortableColumn="Remarks" pResizableColumn class="width-7p p-text-center">
                              Remarks
                              <p-sortIcon field="Remarks"></p-sortIcon>
                          </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-client let-expanded="expanded">
                      <tr class="cursor-hand" [ngClass]="client.InvoicePosted?'-':'ic-red'">
                          <td class="width-8p">
                              <div class="p-text-center">
                                  <i class="fal fa-pencil p-mr-3 ic-green" tooltipPosition="top"
                                      pTooltip="{{ 'Edit' | translate }}" (click)="onClickEditInvoice(client)"></i>
                                  <i class="fal fa-link p-mr-3 ic-red" tooltipPosition="top"
                                      pTooltip="Link a Payment Detail" (click)="onClickLinkPaymentDetail(client)"></i>
                                  <i class="fal fa-trash-alt p-mr-3 ic-solid-red" tooltipPosition="top"
                                      pTooltip="Delete" (click)="onClickDeleteInvoice(client)"></i>
                              </div>
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.ClientName }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''"
                          >
                              {{ client.ClientName }}
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.InvoiceDate | date:'MM/dd/yyyy' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''"
                          >
                              {{ client.InvoiceDate | date:'MM/dd/yyyy' }}
                          </td>
                          <td class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.InvoiceNumber}}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{client.InvoiceNumber}}
                          </td>
                          <td class="width-20p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                              pTooltip="{{client.DescriptionShort}}" tooltipStyleClass="tooltip-width-800"
                              showDelay="800"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{client.DescriptionShort}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip=" {{client.InvoiceAmount | currency : 'USD' : true : '1.2-2' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{client.InvoiceAmount | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.Discount | currency : 'USD' : true : '1.2-2' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{client.Discount | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.TotalPayments | currency : 'USD' : true : '1.2-2' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{client.TotalPayments | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{(client.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{(client.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{(client.TotalAmountApplied) | currency : 'USD' : true : '1.2-2' }}"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{(client.TotalAmountApplied) | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-center"
                              [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                              {{ client.Remarks }}
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="9">
                              <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                  styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
          <div *ngIf="viewType==tabValues.PAYMENT_HEADER">
            <p-table #dtPaymentHeader 
                [value]="paymentHeaders" 
                [rowHover]="true" 
                dataKey="PaymentHeaderID"
                [pageLinks]="10" 
                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                [sortOrder]="-1" 
                sortField="PaymentDate"                    
                [scrollable]="true"
                [scrollHeight]="'calc(100vh - 350px)'" 
                [paginator]="true" 
                [rows]="paymentHeaderRows" 
                [totalRecords]="paymentHeadersTotalRecords" 
                [rowsPerPageOptions]="[10,25,50,100,500,1000]" 
                currentPageReportTemplate= "Page {currentPage} of {totalPages}"
                paginatorPosition="top"
                [showCurrentPageReport] = "true"
                [lazy]="true" 
                (onLazyLoad)="loadPaymentHeaderLazy($event)">
                  <ng-template pTemplate="caption">
                      <div class="table-header">
                          <span class="p-input-icon-left">
                              <i class="fal fa-search"></i>
                              <input #searchValuePaymentHeader pInputText type="text" (keyup)="onChangeInputTextPaymentHeader($event)" placeholder="{{ 'globalsearch' | translate }}" />
                          </span>
                          <span>
                              <button pButton pRipple type="button" icon="pi pi-times"
                                  class="p-button-rounded p-button-danger p-button-text"
                                  (click)="clearSearchFilterPaymentHeader()" tooltipPosition="top"
                                  pTooltip="{{ 'clear' | translate }}"></button>
                          </span>
                          <p-message *ngIf="filteredItemsSizePaymentHeader > -1" severity="success"
                              text="{{filteredItemsSizePaymentHeader}} Record(s) found." styleClass="p-ml-2"></p-message>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                          <th pSortableColumn="ClientName" pResizableColumn class="width-8p p-text-left">
                              Client
                              <p-sortIcon field="ClientName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="PaymentDate" pResizableColumn class="width-8p p-text-left">
                              Payment Date
                              <p-sortIcon field="PaymentDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="CheckRef" pResizableColumn class="width-8p p-text-left">
                              Check Ref
                              <p-sortIcon field="CheckRef"></p-sortIcon>
                          </th>
                          <th pResizableColumn class="width-29p p-text-left">
                              Payment Note
                          </th>
                          <th pResizableColumn class="width-7p p-text-right">Payment Type</th>
                          <th pResizableColumn class="width-7p p-text-right">Payment Amount</th>
                          <th pResizableColumn class="width-7p p-text-right">Payment Unapplied
                          </th>
                          <th pResizableColumn class="width-7p p-text-right">
                              Write Off Amount
                          </th>
                          <th pSortableColumn="TotalAmountApplied" pResizableColumn class="width-7p p-text-right">
                              Total Amount Applied (Payment detail)
                              <p-sortIcon field="TotalAmountApplied"></p-sortIcon>
                          </th>
                          <th pSortableColumn="Remarks" pResizableColumn class="width-7p p-text-center">
                              Remarks
                              <p-sortIcon field="Remarks"></p-sortIcon>
                          </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-client let-expanded="expanded">
                      <tr class="cursor-hand">
                          <td class="width-8p">
                              <div class="p-text-center">
                                  <i class="fal fa-pencil p-mr-3 ic-green" tooltipPosition="top" 
                                      pTooltip="{{ 'Edit' | translate }}" (click)="onClickEditPaymentHeader(client)"></i>
                                  <i class="fal fa-money-check p-mr-3 ic-red" tooltipPosition="top" 
                                      pTooltip="Create a Payment Detail" (click)="onClickCreatePaymentDetail(client)"></i>
                                  <i class="fal fa-trash-alt p-mr-3 ic-solid-red" tooltipPosition="top"
                                      pTooltip="Delete" (click)="onClickDeletePaymentHeader(client)"></i>
                              </div>
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.ClientName }}"
                          >
                              {{ client.ClientName }}
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.PaymentDate | date:'MM/dd/yyyy' }}"
                          >
                              {{ client.PaymentDate | date:'MM/dd/yyyy' }}
                          </td>
                          <td class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.CheckRef}}">
                              {{client.CheckRef}}
                          </td>
                          <td class="width-29p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                              pTooltip="{{client.PaymentNote}}" tooltipStyleClass="tooltip-width-800"
                              showDelay="800">
                              {{client.PaymentNote}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.PaymentType}}">
                              {{client.PaymentType}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.PaymentAmount | currency : 'USD' : true : '1.2-2'}}">
                              {{client.PaymentAmount | currency : 'USD' : true : '1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.PaymentUnapplied | currency : 'USD' : true : '1.2-2' }}">
                              {{client.PaymentUnapplied | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{(client.WriteOffAmount) | currency : 'USD' : true : '1.2-2' }}">
                              {{(client.WriteOffAmount) | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{(client.TotalAmountApplied) | currency : 'USD' : true : '1.2-2' }}">
                              {{(client.TotalAmountApplied) | currency : 'USD' : true : '1.2-2' }}
                          </td>
                          <td class="width-7p p-text-center">
                              {{ client.Remarks }}
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="11">
                              <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                  styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
          <div *ngIf="viewType==tabValues.PAYMENT_DETAIL">
            <p-table #dtPaymentDetail 
                    [value]="paymentDetails" 
                    [rowHover]="true" 
                    dataKey="PaymentDetailID"
                    [pageLinks]="10" 
                    [filterDelay]="100" 
                    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                    [sortOrder]="-1" 
                    sortField="PaymentDate"                    
                    [scrollable]="true"
                    [scrollHeight]="'calc(100vh - 350px)'" 
                    [paginator]="true" 
                    [rows]="paymentDetailsRows" 
                    [totalRecords]="paymentDetailsTotalRecords" 
                    [rowsPerPageOptions]="[10,25,50,100,500,1000]" 
                    currentPageReportTemplate= "Page {currentPage} of {totalPages}"
                    paginatorPosition="top"
                    [showCurrentPageReport] = "true"
                    [lazy]="true" 
                    (onLazyLoad)="loadPaymentDetailsLazy($event)">
                  <ng-template pTemplate="caption">
                      <div class="table-header">
                          <span class="p-input-icon-left">
                              <i class="fal fa-search"></i>
                              <input #searchValuePaymentDetail pInputText type="text" (keyup)="onChangeInputTextPaymentDetails($event)" placeholder="{{ 'globalsearch' | translate }}"/>
                          </span>
                          <span>
                              <button pButton pRipple type="button" icon="pi pi-times"
                                  class="p-button-rounded p-button-danger p-button-text"
                                  (click)="clearSearchFilterPaymentDetail()" tooltipPosition="top"
                                  pTooltip="{{ 'clear' | translate }}"></button>
                          </span>
                          <p-message *ngIf="filteredItemsSizePaymentDetail > -1" severity="success"
                              text="{{filteredItemsSizePaymentDetail}} Record(s) found." styleClass="p-ml-2"></p-message>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                          <th pSortableColumn="ClientName" pResizableColumn class="width-8p p-text-left">
                              Client
                              <p-sortIcon field="ClientName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="PaymentDate" pResizableColumn class="width-8p p-text-left">
                              Payment Date
                              <p-sortIcon field="PaymentDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="InvoiceDate" pResizableColumn class="width-8p p-text-left">
                              Invoice Date
                              <p-sortIcon field="InvoiceDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="InvoiceNumber" pResizableColumn class="width-8p p-text-right">
                              Invoice Number
                              <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                          </th>
                          <th pResizableColumn class="width-29p p-text-left">
                              Payment Note
                          </th>
                          <th pSortableColumn="CheckRef" pResizableColumn class="width-8p p-text-left">
                              Check Ref
                              <p-sortIcon field="CheckRef"></p-sortIcon>
                          </th>
                          <th pResizableColumn class="width-7p p-text-left">Payment Type</th>
                          <th pResizableColumn class="width-7p p-text-right">Applied Amount</th>
                          <th pResizableColumn class="width-7p p-text-right">
                              Write Off Amount
                          </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-client let-expanded="expanded">
                      <tr class="cursor-hand">
                          <td class="width-8p">
                              <div class="p-text-center">
                                  <i class="fal fa-pencil p-mr-3 ic-green" tooltipPosition="top" 
                                      pTooltip="{{ 'Edit' | translate }}" (click)="onClickEditPaymentDetail(client)"></i>
                                  <i class="fal money-check p-mr-3 ic-red" tooltipPosition="top" 
                                      pTooltip="Add to Billing Header" (click)="onClickAddToBillingHeader(client)"></i>
                                  <i class="fal fa-trash-alt p-mr-3 ic-solid-red" tooltipPosition="top"
                                      pTooltip="Delete" (click)="onClickDeletePaymentDetail(client)"></i>
                              </div>
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.ClientName }}"
                          >
                              {{ client.ClientName }}
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.PaymentDate | date:'MM/dd/yyyy' }}"
                          >
                              {{ client.PaymentDate | date:'MM/dd/yyyy' }}
                          </td>
                          <td
                              class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{ client.InvoiceDate | date:'MM/dd/yyyy' }}"
                          >
                              {{ client.InvoiceDate | date:'MM/dd/yyyy' }}
                          </td>
                          <td class="width-8p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.InvoiceNumber}}">
                              {{client.InvoiceNumber}}
                          </td>
                          <td class="width-29p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                              pTooltip="{{client.PaymentNote}}" tooltipStyleClass="tooltip-width-800"
                              showDelay="800">
                              {{client.PaymentNote}}
                          </td>
                          <td class="width-8p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.CheckRef}}">
                              {{client.CheckRef}}
                          </td>
                          
                          <td class="width-7p p-text-left"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.PaymentType}}">
                              {{client.PaymentType}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{client.AppliedAmount | currency : 'USD' : true : '1.2-2'}}">
                              {{client.AppliedAmount | currency : 'USD' : true : '1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right"
                              tooltipPosition="top"
                              showDelay="800"
                              pTooltip="{{(client.WriteOffAmount) | currency : 'USD' : true : '1.2-2' }}">
                              {{(client.WriteOffAmount) | currency : 'USD' : true : '1.2-2' }}
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="10">
                              <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                  styleClass="p-px-2">
                              </p-message>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
          <div *ngIf="viewType==tabValues.STAX">
              <div class="p-fluid p-formgrid p-grid p-m-1">
                  <form #staxDetailForm="ngForm" class="p-col-4">
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">StaxToken
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="StaxToken" name="StaxToken" #StaxToken="ngModel"
                                  [(ngModel)]="staxDetailObj.StaxToken"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">StaxMerchantID
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="StaxMerchantID" name="StaxMerchantID" #StaxMerchantID="ngModel"
                                  [(ngModel)]="staxDetailObj.StaxMerchantID"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">StaxStatus
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="StaxStatus" name="StaxStatus" #StaxStatus="ngModel"
                                  [(ngModel)]="staxDetailObj.StaxStatus"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">StaxMerchantAPIKey
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="StaxMerchantAPIKey" name="StaxMerchantAPIKey" #StaxMerchantAPIKey="ngModel"
                                  [(ngModel)]="staxDetailObj.StaxMerchantAPIKey"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">StaxPublicKey
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="StaxPublicKey" name="StaxPublicKey" #StaxPublicKey="ngModel"
                                  [(ngModel)]="staxDetailObj.StaxPublicKey"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <p-checkbox id="isStaxEnrollmentStarted" name="isStaxEnrollmentStarted" #isStaxEnrollmentStarted="ngModel" 
                                  [(ngModel)]="staxDetailObj.isStaxEnrollmentStarted" binary="true"
                                  label="isStaxEnrollmentStarted"
                                  class="label-text">
                              </p-checkbox>
                          </div>
                      </div>
                      <div class="p-col-4 p-d-flex p-jc-end">
                          <button type="button" pButton pRipple icon="fal fa-check" label="Save" class="p-mr-3"
                              (click)="updateStaxValues()" [disabled]="!isStaxDetailFormValid"></button>
                          <button type="button" pButton pRipple icon="fal fa-times" label="Reset"
                              (click)="clearStaxValues()"></button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </p-sidebar>

  <p-dialog [(visible)]="isShowLinkPaymentDetailDialog" [style]="{width: '65vw'}" [modal]="true" [maximizable]="false"
      showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
      header="Link Payment Detail to Invoice #: {{selectedInvoice?.InvoiceNumber}}">
      <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
          <p-table #dtPaymentDetailLink [value]="paymentDetails" [rowHover]="true" [responsive]="true" dataKey="PaymentDetailID"
              [pageLinks]="3"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [(selection)]="selectedPaymentDetails"
              [sortOrder]="-1" sortField="PaymentDate"
              [scrollable]="true"
              [scrollHeight]="'calc(100vh - 350px)'">
              <ng-template pTemplate="header">
                  <tr>
                      <th class="width-8p p-text-center"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                      <th pSortableColumn="ClientName" pResizableColumn class="width-8p p-text-left">
                          Client
                          <p-sortIcon field="ClientName"></p-sortIcon>
                      </th>
                      <th pSortableColumn="PaymentDate" pResizableColumn class="width-8p p-text-left">
                          Payment Date
                          <p-sortIcon field="PaymentDate"></p-sortIcon>
                      </th>
                      <th pSortableColumn="InvoiceDate" pResizableColumn class="width-8p p-text-left">
                          Invoice Date
                          <p-sortIcon field="InvoiceDate"></p-sortIcon>
                      </th>
                      <th pSortableColumn="InvoiceNumber" pResizableColumn class="width-8p p-text-right">
                          Invoice Number
                          <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                      </th>
                      <th pResizableColumn class="width-29p p-text-left">
                          Payment Note
                      </th>
                      <th pSortableColumn="CheckRef" pResizableColumn class="width-8p p-text-left">
                          Check Ref
                          <p-sortIcon field="CheckRef"></p-sortIcon>
                      </th>
                      <th pResizableColumn class="width-7p p-text-left">Payment Type</th>
                      <th pResizableColumn class="width-7p p-text-right">Applied Amount</th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-client>
                  <tr class="cursor-hand">
                      <td class="width-8p p-text-center">
                          <p-tableCheckbox [value]="client">
                          </p-tableCheckbox>
                      </td>
                      <td
                          class="width-8p p-text-left"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{ client.ClientName }}"
                      >
                          {{ client.ClientName }}
                      </td>
                      <td
                          class="width-8p p-text-left"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{ client.PaymentDate | date:'MM/dd/yyyy' }}"
                      >
                          {{ client.PaymentDate | date:'MM/dd/yyyy' }}
                      </td>
                      <td
                          class="width-8p p-text-left"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{ client.InvoiceDate | date:'MM/dd/yyyy' }}"
                      >
                          {{ client.InvoiceDate | date:'MM/dd/yyyy' }}
                      </td>
                      <td class="width-8p p-text-right"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{client.InvoiceNumber}}">
                          {{client.InvoiceNumber}}
                      </td>
                      <td class="width-29p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                          pTooltip="{{client.PaymentNote}}" tooltipStyleClass="tooltip-width-800"
                          showDelay="800">
                          {{client.PaymentNote}}
                      </td>
                      <td class="width-8p p-text-left"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{client.CheckRef}}">
                          {{client.CheckRef}}
                      </td>
                      
                      <td class="width-7p p-text-left"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{client.PaymentType}}">
                          {{client.PaymentType}}
                      </td>
                      <td class="width-7p p-text-right"
                          tooltipPosition="top"
                          showDelay="800"
                          pTooltip="{{client.AppliedAmount | currency : 'USD' : true : '1.2-2'}}">
                          {{client.AppliedAmount | currency : 'USD' : true : '1.2-2'}}
                      </td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="9">
                          <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                              styleClass="p-px-2">
                          </p-message>
                      </td>
                  </tr>
              </ng-template>
          </p-table>
      </div>
      <p-footer>
          <button type="button" pButton pRipple icon="fal fa-check" label="Link Payment Detail(s)"
              (click)="linkPaymentDetailsToInvoice()" [disabled]="selectedPaymentDetails.length === 0"></button>
          <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}"
              (click)="isShowLinkPaymentDetailDialog = false"></button>
      </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="isShowEditBillingHeaderDialog" [style]="{width: '23vw'}" [modal]="true" [maximizable]="false"
      showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
      header="Update Billing Header: {{selectedBillingHeader?.BillingHeaderID}}">
      <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
          <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
              <form #billingHeaderForm="ngForm" class="p-col-12">
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">InvoiceAmount
                          </label>
                          <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                              currency="USD" locale="en-US" prefix="$" id="InvoiceAmount" name="InvoiceAmount" #InvoiceAmount="ngModel"
                              [(ngModel)]="billingHeaderObj.InvoiceAmount"
                              (click)="$event.target.select();" autocomplete="off"
                              inputStyleClass="p-text-right"
                              required />
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">TotalPayments
                          </label>
                          <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                              currency="USD" locale="en-US" prefix="$" id="TotalPayments" name="TotalPayments" #TotalPayments="ngModel"
                              [(ngModel)]="billingHeaderObj.TotalPayments"
                              (click)="$event.target.select();" autocomplete="off"
                              inputStyleClass="p-text-right"
                              required />
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">InvoiceBalance
                          </label>
                          <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                              currency="USD" locale="en-US" prefix="$" id="InvoiceBalance" name="InvoiceBalance" #InvoiceBalance="ngModel"
                              [(ngModel)]="billingHeaderObj.InvoiceBalance"
                              (click)="$event.target.select();" autocomplete="off"
                              inputStyleClass="p-text-right"
                              required />
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">InvoiceDate
                          </label>
                          <p-calendar
                              id="InvoiceDate" name="InvoiceDate" #InvoiceDate="ngModel"
                              [(ngModel)]="billingHeaderObj.InvoiceDate"
                              showButtonBar="true"
                              yearRange="2000:2099"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              appendTo="body"
                          >
                          </p-calendar>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      <p-footer>
          <button type="button" pButton pRipple icon="fal fa-check" label="Update"
              (click)="updateBillingHeader()" [disabled]="!isBillingHeaderFormValid"></button>
          <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}"
              (click)="isShowEditBillingHeaderDialog = false"></button>
      </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="isShowEditPaymentHeaderDialog" [style]="{width: '23vw'}" [modal]="true" [maximizable]="false"
      showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
      header="Update Payment Header: {{selectedPaymentHeader?.PaymentHeaderID}}">
      <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
          <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
              <form #paymentHeaderForm="ngForm" class="p-col-12">
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">BillingHeaderID
                          </label>
                          <p-dropdown [options]="invoiceOptions" [(ngModel)]="paymentHeaderObj.BillingHeaderID" 
                              name="BillingHeaderID" id="BillingHeaderID" #BillingHeaderID="ngModel" 
                              styleClass="plan-dropdown"
                              appendTo="body" [style]="{'width':'100% !important'}"></p-dropdown>
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">PaymentAmount
                          </label>
                          <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                              currency="USD" locale="en-US" prefix="$" id="PaymentAmount" name="PaymentAmount" #PaymentAmount="ngModel"
                              [(ngModel)]="paymentHeaderObj.PaymentAmount"
                              (click)="$event.target.select();" autocomplete="off"
                              inputStyleClass="p-text-right"
                              required />
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">PaymentUnapplied
                          </label>
                          <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                              currency="USD" locale="en-US" prefix="$" id="PaymentUnapplied" name="PaymentUnapplied" #PaymentUnapplied="ngModel"
                              [(ngModel)]="paymentHeaderObj.PaymentUnapplied"
                              (click)="$event.target.select();" autocomplete="off"
                              inputStyleClass="p-text-right"/>
                      </div>
                  </div>
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">PaymentDate
                          </label>
                          <p-calendar
                              id="PaymentDate" name="PaymentDate" #PaymentDate="ngModel"
                              [(ngModel)]="paymentHeaderObj.PaymentDate"
                              showButtonBar="true"
                              yearRange="2000:2099"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              appendTo="body"
                          >
                          </p-calendar>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      <p-footer>
          <button type="button" pButton pRipple icon="fal fa-check" label="Update"
              (click)="updatePaymentHeader()" [disabled]="!isPaymentFormValid"></button>
          <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}"
              (click)="isShowEditPaymentHeaderDialog = false"></button>
      </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="isShowAddEditPaymentDetailDialog" [style]="{width: '50vw'}" [modal]="true" [maximizable]="false"
      showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
      header="{{selectedPaymentDetail ? 'Update' : 'Create'}} Payment Detail: {{selectedPaymentDetail ? selectedPaymentDetail?.PaymentDetailID : ''}}">
      <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual">
          <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
              <form #paymentDetailForm="ngForm" class="p-col-12">
                  <div class="p-formgroup-inline">
                      <div class="p-field p-col-12">
                          <label class="label-text">BillingHeaderID
                          </label>
                          <p-dropdown [options]="invoiceOptions" [(ngModel)]="paymentDetailObj.BillingHeaderID" 
                              name="BillingHeaderID" id="BillingHeaderID" #BillingHeaderID="ngModel" 
                              styleClass="plan-dropdown" (onChange)="onChangeBillingHeader($event, tabValues.PAYMENT_DETAIL)"
                              appendTo="body" [style]="{'width':'100% !important'}" required></p-dropdown>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">AppliedAmount
                              </label>
                              <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                                  currency="USD" locale="en-US" prefix="$" id="AppliedAmount" name="AppliedAmount" #AppliedAmount="ngModel"
                                  [(ngModel)]="paymentDetailObj.AppliedAmount"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-right"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">PaymentDate
                              </label>
                              <p-calendar
                                  id="PaymentDate" name="PaymentDate" #PaymentDate="ngModel"
                                  [(ngModel)]="paymentDetailObj.PaymentDate"
                                  showButtonBar="true"
                                  yearRange="2000:2099"
                                  appendTo="body"
                                  [monthNavigator]="true"
                                  [yearNavigator]="true"
                              >
                              </p-calendar>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <label class="label-text">PaymentNote
                              </label>
                              <input pInputText type="text" class="p-text-left"
                                  id="PaymentNote" name="PaymentNote" #PaymentNote="ngModel"
                                  [(ngModel)]="paymentDetailObj.PaymentNote"
                                  (click)="$event.target.select();" autocomplete="off"
                                  inputStyleClass="p-text-left"/>
                          </div>
                      </div>
                      <div class="p-formgroup-inline">
                          <div class="p-field p-col-12">
                              <p-checkbox [(ngModel)]="paymentDetailObj.isAdvancePayment" binary="true"
                                  [ngModelOptions]="{standalone: true}" label="isAdvancePayment"
                                  class="label-text">
                              </p-checkbox>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      <p-footer>
          <button type="button" pButton pRipple icon="fal fa-check" label="{{selectedPaymentDetail ? 'Update' : 'Create'}}"
              (click)="createOrUpdatePaymentDetail()" [disabled]="!paymentDetailObj.BillingHeaderID"></button>
          <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}"
              (click)="isShowAddEditPaymentDetailDialog = false"></button>
      </p-footer>
  </p-dialog>

  <p-dialog [modal]="true" [(visible)]="isShowUpdateStaxEnumDialog" [style]="{width: '35vw'}" [responsive]="true" (onHide)="isShowUpdateStaxEnumDialog = false"
  [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="10000" [maximizable]="false">
      <p-header>STAX Enums</p-header>
      <p-table #dt [value]="staxEnums" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
          [rowHover]="true" dataKey="StaxEnumID" sortField="StaxEnumID" [sortOrder]="1" #staxEnumTable>
          <ng-template pTemplate="header">
          <tr>
              <th class="width-10p p-text-center">
              </th>
              <th class="width-20p p-text-left" pSortableColumn="StaxEnumID">
                  ID
                  <p-sortIcon styleClass="p-ml-0" field="StaxEnumID"></p-sortIcon>
              </th>
              <th class="width-40p p-text-left" pSortableColumn="Value">
                  Value
                  <p-sortIcon styleClass="p-ml-0" field="Value"></p-sortIcon>
              </th>
          </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
          <tr>
              <td class="width-10p p-text-center">
                  <i class="fal fa-save ic-blue ic-size-15 p-mr-2 cursor-hand" (click)="updateStaxEnum($event, rowData)"
                      *ngIf="rowData['isUpdated']" pTooltip="Save" tooltipPosition="top"></i>
              </td>
              <td class="width-20p p-text-left">
                  {{ rowData.StaxEnumID }}
              </td>
              <td class="width-70p p-text-left" pEditableColumn>
                  <p-cellEditor>
                      <ng-template pTemplate="input">
                          <input pInputText type="text"
                              [(ngModel)]="rowData.Value" [ngModelOptions]="{ standalone: true }"
                              (ngModelChange)="rowData.isUpdated = true;"
                              (focus)="$event.target.select()"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                          <span>{{ rowData["Value"] }}</span>
                      </ng-template>
                  </p-cellEditor>
              </td>
          </tr>
          </ng-template>
      </p-table>

      <p-footer>
          <button pButton label="{{ 'close' | translate }}" (click)="isShowUpdateStaxEnumDialog = false"></button>
      </p-footer>
  </p-dialog>
</div>
