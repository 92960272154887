<p-sidebar *ngIf="invoiceMemoParent" [(visible)]="showParentMemo" (onHide)="closeInvoiceMemo()" position="bottom" [style]="{'min-height':'300px','background':'#8e8f92'}">
  <div class="card">
      <div class="control-label header-color text-nowrap p-col-12">
          <h4 class="label-text">{{'Invoice Memo' | translate}}</h4>
      </div>
      <span class="pull-right ic-size-15 p-text-bold">
          <span [ngStyle]="{'color':(invoiceMemoParent.customDescription.length > 250)? '#f28686' : '#83cc44' }">{{ invoiceMemoParent.customDescription.length }}</span>/250
      </span>
      <div class="p-col-12 col-md-12">
          <mwl-text-input-autocomplete-container>
              <textarea pInputTextarea class="width-100p" [rows]="7" #parentRowMemo maxlength="250" [ngStyle]="{'border-color':(invoiceMemoParent.customDescription.length > 250)? '#f28686' : '#ced4da' }" style="resize: vertical;" mwlTextInputAutocomplete [(ngModel)]="invoiceMemoParent.customDescription" [findChoices]="mangoUtils.findChoices" [getChoiceLabel]="mangoUtils.getChoiceLabel" (keyup)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" (focus)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" (keydown)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" placeholder="{{'shortcut-placeholder' | translate}}"></textarea>
          </mwl-text-input-autocomplete-container>
          <app-mentions [rows]="7" [(inputText)]="invoiceMemoParent.customDescription"
              (inputTextChange)="markAsChanged();replaceCaret2('customDescription');" 
              [ngStyle]="{'border-color':(invoiceMemoParent.customDescription.length > 250)? '#f28686' : '#ced4da' }"
              style="width:100% !important; resize: vertical;"></app-mentions>
      </div>
  </div>
</p-sidebar>

<div class="batch-billing">
  <div class="layout-content p-pt-0">
      <form class="form-horizontal batch-billing-form" role="form" [formGroup]="batchBillingForm" novalidate>
          <p-sidebar [(visible)]="showDefaultMemo" position="bottom" [style]="{'min-height':'300px','background':'#8e8f92'}">
              <div class="card">
                  <div class="control-label header-color text-nowrap p-col-12 p-formgroup-inline">
                      <h4 class="label-text">{{'Internal-Invoice-Memo' | translate}}</h4>
                      <div class="p-ml-3 default-desc" style="margin-top: -23px;height: 10px;">
                          <p-messages styleClass="default-desc" [(value)]="msgs1" [enableService]="false"></p-messages>
                      </div>
                  </div>
                  <span class="pull-right ic-size-15 p-text-bold">
                      <span [ngStyle]="{'color':(descCount > 250)? '#f28686' : '#83cc44' }">{{ descCount }}</span>/250
                  </span>
                  <div class="p-col-12 col-md-12">
                      <mwl-text-input-autocomplete-container>
                          <textarea pInputTextarea class="width-100p" [rows]="7" #defaultMemo maxlength="250" [ngStyle]="{'border-color':(descCount > 250)? '#f28686' : '#ced4da' }" (keyup)="markAsChanged();replaceShortcuts(null, 'DescriptionShort');replaceCaret2('DescriptionShort');charCountDescriptionShort()" (focus)="replaceShortcuts(null, 'DescriptionShort');replaceCaret2('DescriptionShort');charCountDescriptionShort()" (keydown)="markAsChanged();replaceShortcuts(null, 'DescriptionShort');replaceCaret2('DescriptionShort');charCountDescriptionShort()" formControlName="DescriptionShort" name="memo" style="resize: vertical;" mwlTextInputAutocomplete [findChoices]="mangoUtils.findChoices" [getChoiceLabel]="mangoUtils.getChoiceLabel" placeholder="{{'shortcut-placeholder' | translate}} "></textarea>
                      </mwl-text-input-autocomplete-container>
                      <app-mentions [rows]="7" maxlength="250" [(inputText)]="batchBillingForm.controls['DescriptionShort'].value"
                          (inputTextChange)="batchBillingForm.controls['DescriptionShort'].setValue($event);markAsChanged();replaceCaret2('DescriptionShort');charCountDescriptionShort()" 
                          [ngStyle]="{'border-color':(descCount > 250)? '#f28686' : '#ced4da' }"
                          style="width:100% !important; resize: vertical;"></app-mentions>
                  </div>
              </div>
          </p-sidebar>
          <span class="pull-right p-mr-3 clientform-btn-top">
              <demo-video videoCode="b50262x5p0"></demo-video>
              <button pButton pRipple label="{{'process-invoices' | translate}}" icon="fal fa-file-text" class="p-button-sm p-pr-2 p-mr-2" [disabled]="(selectedItems.length== 0)" (click)="processInvoiceList()"></button>
              <button pButton pRipple label="Save Customer Settings" icon="fas fa-save" class="p-button-sm p-pr-2 p-mr-2" (click)="save()" pTooltip="Update Customer Settings"></button>
              <button pButton pRipple label="{{'filters' | translate}}" icon="fal fa-filter" class="p-button-sm p-px-2 p-mr-2" (click)="showFilterSideBar = true;"></button>
              <button [ngStyle]="{'background-color': descCount > 0? 'green': ''}" pButton pRipple label="{{'default_memo' | translate}}" icon="fal fa-file-invoice" class="p-button-sm p-px-2 p-mr-2" (click)="showDefaultMemoDialog()"></button>
          </span>

          <div class="card p-col-12 p-formgroup-inline p-ai-center p-py-0 p-px-4 p-mx-2 p-mb-2">
              <div class="p-field width-17p p-grid p-mb-0">
                  <label class="label-text required-field">{{ 'Accounts.invoice-date' | translate }}</label>
                  <div class="p-col">
                      <p-calendar formControlName="InvoiceDate" yearRange="2000:2090" showButtonBar="true" [monthNavigator]="true" [yearNavigator]="true" (onSelect)="markAsChanged(); onDateSelect($event, 'InvoiceDate'); verifySystemLocking($event); validateInvoiceDate();">
                      </p-calendar>
                  </div>
              </div>
              <div class="p-field width-10p p-grid p-ai-center p-mb-0 p-ml-3">
                  <label class="label-text required-field">{{'Accounts.invoice-number' | translate}}</label>
                  <div class="p-mb-2 p-text-bold ic-red">{{NextInvoiceNumber + 1}}</div>
              </div>
              <div class="p-field p-col-8 p-grid p-mb-0">
                  <div class="p-field p-ml-3 p-mb-0 p-p-2">
                      <label for="For" class=" label-text p-mt-2">{{'Month' | translate}}</label>
                      <i class="fa fa-question-circle ic-blue p-mr-3" style="margin-left: -2px;" showDelay="800" tooltipStyleClass="tooltip-width-300" pTooltip="{{'selectedMonth_tooltip' | translate}}" tooltipPosition="top"></i>
                      <p-dropdown class="p-mr-2 pull-right month-dropdown" [ngModelOptions]="{ standalone: true }" [style]="{'min-width':'120px'}" [autoWidth]="false" [options]="months" (ngModelChange)="markAsChanged()" [(ngModel)]="currentMonth" [scrollHeight]="'340px !important'"></p-dropdown>
                  </div>
                  <div class="p-field p-ml-3 p-mb-0 p-p-2">
                      <label for="For" class="label-text p-mt-2">{{'Quarter' | translate}}</label>
                      <i class="fa fa-question-circle ic-blue p-mr-3" style="margin-left: -2px;" showDelay="800" tooltipStyleClass="tooltip-width-300" pTooltip="{{'selectedQuarter_tooltip' | translate}}" tooltipPosition="top"></i>
                      <p-dropdown class="p-mr-2 pull-right" [ngModelOptions]="{ standalone: true }" (ngModelChange)="markAsChanged()" [style]="{'min-width':'120px'}" [autoWidth]="false" [options]="quarters" [(ngModel)]="currentQuarter"></p-dropdown>
                  </div>
                  <div class="p-field p-ml-3 p-mb-0 p-p-2">
                      <label for="For" class="label-text p-mt-2">{{'Year' | translate}}</label>
                      <i class="fa fa-question-circle ic-blue p-mr-3" style="margin-left: -2px;" showDelay="800" tooltipStyleClass="tooltip-width-300" pTooltip="{{'selectedYear_tooltip' | translate}}" tooltipPosition="top"></i>
                      <p-dropdown class="p-mr-2 pull-right" [ngModelOptions]="{ standalone: true }" [style]="{'min-width':'120px'}" [autoWidth]="false" [options]="years" (ngModelChange)="markAsChanged()" [(ngModel)]="currentYear"></p-dropdown>
                  </div>
              </div>
          </div>

          <!-- Second Card -->
          <div class="card p-col-12 p-py-0 p-px-4 p-mx-2">
            <p-table #clientInvoiceListdt [value]="parentLevelOne" [rowHover]="true" [responsive]="true" [rows]="12" dataKey="uuid" selectionMode="single" [showCurrentPageReport]="true" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" [resizableColumns]="true" [(selection)]="selectedItems" (onFilter)="onFilter($event)" [globalFilterFields]="['ClientName']" (onHeaderCheckboxToggle)="levelOneCheckUncheckRow($event,true)" rowExpandMode="single" [scrollable]="true" [scrollHeight]="'calc(90vh - 210px)'" [virtualScroll]="true">
                  <ng-template pTemplate="caption">
                      <div class="table-header">
                          <span class="p-input-icon-left">
                              <i class="fal fa-search"></i>
                              <input #searchValue pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="{{ 'Search-by' | translate }}" />
                          </span>
                          <span>
                              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                          </span>
                          <span class="pull-right p-mt-2">
                              <p-checkbox name="selectedOnly" value="selectedOnly" [(ngModel)]="selectedOnly" [binary]="true" label="Selected Only" class="label-text" (ngModelChange)="onSelectedOnlyChange()" [ngModelOptions]="{standalone: true}"></p-checkbox>
                          </span>
                          <p-message *ngIf="filteredItemsSize > -1" severity="success" text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2"></p-message>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                      <tr>
                          <th class="width-3p p-text-center">
                              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                          </th>
                          <th class="width-2p p-text-center"></th>
                          <th class="width-8p p-text-center"></th>
                          <th class="width-19p p-text-left">{{'client.name' | translate}}</th>
                          <th class="width-4p p-text-center" pTooltip="{{'NDE' | translate}}" tooltipPosition="top">
                              Exp.
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-left" pTooltip="{{'engagement.billing_method' | translate}}" tooltipPosition="top">
                              {{'Method' | translate}}
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-left" pTooltip="Finalize Action" tooltipPosition="top">
                              Finalize
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-left" pTooltip="Invoice Template" tooltipPosition="top">
                              Invoice
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-right" pTooltip="{{'billable-expenses' | translate}}" tooltipPosition="top">
                              {{'billing-invoicing.apply-to-expenses' | translate}}
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-right" pTooltip="{{'billable-services' | translate}}" tooltipPosition="top">
                              {{'billable-time' | translate}}
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-right">{{'billing-invoicing.discount' | translate}}</th>
                          <th class="width-7p p-text-right" pTooltip="{{'billable-amount' | translate}}" tooltipPosition="top">
                              {{'billable' | translate}}
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-7p p-text-right" pTooltip="{{'writes-up-down' | translate}}" tooltipPosition="top">
                              WUWD
                              <i class="fa fa-question-circle ic-blue"></i>
                          </th>
                          <th class="width-8p p-text-right">
                              {{'Accounts.invoice-amount' | translate}}
                          </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-parentRecord let-expanded="expanded">
                      <tr class="cursor-hand" [ngClass]="{'bg-light-blue': (!parentRecord.isSelected && !parentRecord.isChildItem),'bg-blue': (parentRecord.isSelected && !parentRecord.isChildItem)}">
                          <td class="width-3p p-text-center">
                              <p-tableCheckbox [value]="parentRecord" (click)="levelOneCheckUncheckRow(parentRecord,false)"></p-tableCheckbox>
                          </td>
                          <td class="width-2p">
                              <i *ngIf="parentRecord.isChildItem" [ngClass]="{'notAllowed': !parentRecord.isSelected, '': parentRecord.isSelected}" class="fal fa-pencil-alt ic-red cursor-hand p-pl-2" pTooltip="{{ 'Edit' | translate }}" showDelay="400" tooltipPosition="top" aria-hidden="true" (click)="onShowRecords(parentRecord)"></i>
                          </td>
                          <td class="width-8p">
                              <i *ngIf="!parentRecord.isChildItem" pRipple class="pi pi-info-circle ic-black ic-size-18 p-mr-3 cursor-hand" style="margin-bottom: -2px;" tooltipPosition="top" showDelay="400" tooltipStyleClass="tooltip-no-wrap" pTooltip="{{ 'show_customer_information' | translate }}" (click)="showSidePanel(parentRecord);"></i>
                              <i *ngIf="!parentRecord.isChildItem" class="fal fa-print ic-black p-mr-3 ic-size-18" aria-hidden="true" pTooltip="{{'preview' | translate}}" tooltipPosition="top" (click)="previewInvoice(parentRecord)" showDelay="800"></i>
                              <i class="fa fa-sticky-note p-pr-2 cursor-hand ic-size-17" *ngIf="!parentRecord.isChildItem && parentRecord.isSelected && descCount == 0" [ngClass]="{'ic-grey': (!parentRecord.customDescription || parentRecord.customDescription.length == 0), 'ic-solid-red': (parentRecord.customDescription && parentRecord.customDescription.length > 250), 'ic-folder-yellow': (parentRecord.customDescription && parentRecord.customDescription.length > 0)}" pTooltip="{{ 'Accounts.invoice-memo' | translate }}" (click)="openInvoiceMemo(parentRecord);" tooltipPosition="top" showDelay="800"></i>
                          </td>
                          <td class="width-15p p-text-left">
                              <span *ngIf="!parentRecord.isChildItem">{{parentRecord.ClientName}}</span>
                              <span *ngIf="parentRecord.isChildItem">{{parentRecord.EngagementName}}</span>
                          </td>
                          <td class="p-text-center width-4p">
                              <p-checkbox *ngIf="parentRecord.isChildItem" [(ngModel)]="parentRecord.IsExpenseRollUp" (onChange)="updateExpenseRollUp($event,parentRecord);" binary="true" [ngModelOptions]="{ standalone: true }">
                              </p-checkbox>
                          </td>
                          <td class="width-7p p-text-left">
                              <span *ngIf="!parentRecord.isChildItem">&nbsp;</span>
                              <span *ngIf="parentRecord.isChildItem" style="font-weight: 600;" [ngStyle]="{'color':(parentRecord.BillingMethod === 'Fixed Fee' && !parentRecord.isSkipFlatAmount)? '#05b0ff' : '#333' }">{{parentRecord.BillingMethod}}</span>
                          </td>
                          <td class="width-7p p-text-left" pTooltip="{{parentRecord.FinalizeAction}}" tooltipPosition="top" pEditableColumn tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                              <span *ngIf="(parentRecord.isChildItem)">&nbsp;</span>
                              <p-cellEditor *ngIf="(!parentRecord.isChildItem)">
                                  <ng-template pTemplate="input">
                                      <p-dropdown [options]="finalizeActions" placeholder="{{parentRecord.FinalizeAction}}" [(ngModel)]="parentRecord.FinalizeAction" [ngModelOptions]="{ standalone: true }" (onChange)="handleDropStaffSelectClick($event,parentRecord,'FinalizeAction'); markAsChanged()" appendTo="body" [style]="{'width':'200px !important'}" class="p-text-left">
                                      </p-dropdown>
                                  </ng-template>
                                  <ng-template pTemplate="output">
                                      <div>{{parentRecord.FinalizeAction}}</div>
                                  </ng-template>
                              </p-cellEditor>
                          </td>
                          <td class="width-7p p-text-left" pTooltip="{{getInvoiceTemplate(parentRecord.DefaultInvoiceTemplate)}}" pEditableColumn tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" showDelay="800">
                              <span *ngIf="(parentRecord.isChildItem)">&nbsp;</span>
                              <p-cellEditor *ngIf="(!parentRecord.isChildItem)">
                                  <ng-template pTemplate="input">
                                      <p-dropdown [options]="invoiceTemplateTypes" placeholder="{{getInvoiceTemplate(parentRecord.DefaultInvoiceTemplate)}}" [(ngModel)]="parentRecord.DefaultInvoiceTemplate" [ngModelOptions]="{ standalone: true }" (onChange)="handleDropStaffSelectClick($event,parentRecord,'InvoiceTemplate'); markAsChanged()" appendTo="body" [style]="{'width':'200px !important'}" class="p-text-left">
                                      </p-dropdown>
                                  </ng-template>
                                  <ng-template pTemplate="output">
                                      <div>{{getInvoiceTemplate(parentRecord.DefaultInvoiceTemplate)}}</div>
                                  </ng-template>
                              </p-cellEditor>
                          </td>
                          <td class="width-7p p-text-right">
                              {{parentRecord.billableexpenses | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right">
                              {{parentRecord.billableservices | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right" *ngIf="parentRecord.isChildItem && parentRecord.isSelected && !parentRecord.isDisableDiscount" style="background-color:#fff2e6" pEditableColumn>
                              <p-cellEditor>
                                  <ng-template pTemplate="input">
                                      <p-inputNumber inputStyleClass="width-100p p-text-right" [ngModelOptions]="{standalone: true}" [step]="0" [(ngModel)]="parentRecord.parentDiscount" maxlength="14" mode="decimal" (onFocus)="$event.target.select();parentRecord['PreviousDiscount'] = parentRecord['parentDiscount'];" [minFractionDigits]="2" [maxFractionDigits]="2" (keydown.enter)="onDiscountChange(parentRecord);" (onBlur)="onDiscountChange(parentRecord);" (ngModelChange)="parentRecord.isDiscountChanged=true;">
                                      </p-inputNumber>
                                  </ng-template>
                                  <ng-template pTemplate="output">
                                      <span>{{ parentRecord.parentDiscount | currency:'USD':'symbol':'1.2-2' }}</span>
                                  </ng-template>
                              </p-cellEditor>
                          </td>
                          <td class="width-7p p-text-right" *ngIf="parentRecord.isChildItem && parentRecord.isSelected && parentRecord.isDisableDiscount">
                              {{parentRecord.parentDiscount | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right" *ngIf="!parentRecord.isChildItem || !parentRecord.isSelected">
                              {{parentRecord.parentDiscount | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right">
                              {{parentRecord.parentBillableAmount | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td class="width-7p p-text-right">
                              <span [style.color]="parentRecord.isChildItem ? setWriteUpDownColor(parentRecord.parentWUWDAmount) : ''">
                                  <span *ngIf="parentRecord.parentWUWDAmount < 0">
                                      ({{ mangoUtils.getAbsolute(parentRecord.parentWUWDAmount) | currency: "USD":"symbol":"1.2-2" }})
                                  </span>
                                  <span *ngIf="parentRecord.parentWUWDAmount >= 0">{{ parentRecord.parentWUWDAmount | currency: "USD":"symbol":"1.2-2" }}</span>
                              </span>
                          </td>
                          <td class="width-7p p-text-right" *ngIf="parentRecord.isChildItem && parentRecord.isSelected && !parentRecord.isDisableDiscount" [ngStyle]="{'background-color':(parentRecord.BillingMethod === 'Fixed Fee' && !parentRecord.isSkipFlatAmount)? '#99dcfb' : '#fff2e6' }" pEditableColumn>
                              <p-cellEditor>
                                  <ng-template pTemplate="input">
                                      <p-inputNumber inputStyleClass="width-100p p-text-right" [ngModelOptions]="{standalone: true}" [step]="0" [(ngModel)]="parentRecord.parentInvoiceAmount" maxlength="14" mode="decimal" (onFocus)="$event.target.select();parentRecord['PreviousInvoiceAmt'] = parentRecord['parentInvoiceAmount'];" [minFractionDigits]="2" [maxFractionDigits]="2" (keydown.enter)="calculateWPWD(parentRecord);" (onBlur)="calculateWPWD(parentRecord);">
                                      </p-inputNumber>
                                  </ng-template>
                                  <ng-template pTemplate="output">
                                      <span>{{ parentRecord.parentInvoiceAmount | currency:'USD':'symbol':'1.2-2' }}</span>
                                  </ng-template>
                              </p-cellEditor>
                          </td>
                          <td *ngIf="parentRecord.isChildItem && parentRecord.isSelected && parentRecord.isDisableDiscount" class="width-7p p-text-right">
                              {{parentRecord.parentInvoiceAmount | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                          <td *ngIf="!parentRecord.isChildItem || !parentRecord.isSelected" class="width-8p p-text-right">
                              {{parentRecord.parentInvoiceAmount | currency:'USD':'symbol':'1.2-2'}}
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="10">
                              <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2"></p-message>
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                      <tr>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-7p"></td>
                          <td class="p-text-right ic-red width-53p">{{'Totals' | translate}}</td>
                          <td class="p-text-right ic-red width-7p">
                              <span>{{ parentTotalBillableExpenses | currency:'USD':true:'1.2-2'}}</span>
                          </td>
                          <td class="p-text-right ic-red width-7p">
                              <span>{{ parentTotalBillableServices | currency:'USD':true:'1.2-2'}}</span>
                          </td>
                          <td class="p-text-right ic-red width-7p">
                              <span>{{ parentTotalDiscount | currency:'USD':true:'1.2-2' }}</span>
                          </td>
                          <td class="p-text-right ic-red width-7p">
                              <span>{{ parentBillableAmount | currency:'USD':true:'1.2-2' }}</span>
                          </td>
                          <td class="p-text-right ic-red width-7p">
                              <span>{{ parentWUWDAmount | currency:'USD':true:'1.2-2' }}</span>
                          </td>
                          <td class="p-text-right ic-red width-8p">
                              <span>{{ parentInvoiceAmount | currency:'USD':true:'1.2-2' }}</span>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
      </form>
  </div>

  <p-sidebar [(visible)]="sidePanelDisplay" (onHide)="resetClientInformation()" position="right" [style]="{'min-width':'70%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
      <div class="card" style="min-height: calc(100vh - 34px)">
          <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 label-text ic-size-18 p-pb-4">{{'billing-invoicing.customer-information' | translate}}</div>
              <div class="p-field p-col-2 ic-size-12">
                  <label class="label-text">{{ 'customer_name' | translate }}:</label>
              </div>
              <div class="p-field p-col-10">
                  <strong>{{ customerName }}</strong>
              </div>
              <div class="p-field p-col-2 ic-size-12">
                  <label class="label-text">{{ 'user.user_address' | translate }}:</label>
              </div>
              <div class="p-field p-col-10" *ngIf="clientInfo">
                  <div class="p-ml-1">{{clientInfo.BusStreet1}}</div>
                  <div class="p-ml-1" *ngIf="clientInfo.BusStreet2">{{clientInfo.BusStreet2}}</div>
                  <div class="p-ml-1">
                      <span *ngIf="clientInfo.BusCity">{{clientInfo['BusCity']}}, </span>
                      <span *ngIf="clientInfo.BusState">{{clientInfo['BusState']}}, </span>
                      <span *ngIf="clientInfo.BusZip">{{clientInfo['BusZip']}}</span>
                  </div>
              </div>
              <div class="p-field p-col-2 ic-size-12">
                  <label class="label-text">{{ 'Account.customer_balance' | translate}}:</label>
              </div>
              <div class="p-field p-col-7">
                  <strong>{{ (customerBalance || 0) | currency : 'USD' : true : '1.2-2'}}</strong>
              </div>
              <div class="p-field p-col-3">
                  <p-dropdown [options]="viewRecordOptions" [(ngModel)]="selectedViewRecord" [ngModelOptions]="{standalone: true}" (onChange)="getInvoiceHistoryData(clientInfo['ClientID']);getClientPaymentHistory(clientInfo['ClientID']);">
                  </p-dropdown>
              </div>
          </div>
          <div class="p-col-12 p-mt-0 p-pt-0 p-pr-0 manual">
              <p-panel header="{{ 'billing-invoicing.invoice-history' | translate }}" [toggleable]="true">
                  <p-table [value]="historyDataSource" [responsive]="true" dataKey="InvoiceNumber" [scrollable]="true" [scrollHeight]="'calc(55vh - 200px)'" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th class="p-text-left width-15p">
                                  {{'Accounts.invoice-date' | translate}}
                              </th>
                              <th class="p-text-left width-15p">
                                  {{'Invoice' | translate}} #
                              </th>
                              <th class="width-40p p-text-left">
                                  {{'Accounts.invoice-memo' | translate}}
                              </th>
                              <th class="p-text-right width-15p">
                                  {{'Accounts.invoice-amount' | translate}}
                              </th>
                              <th class="p-text-right width-15p">
                                  {{'Accounts.invoice-balance' | translate}}
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                          <tr>
                              <td class="p-text-left width-15p">
                                  {{ rowData.InvoiceDate | date: "MM/dd/yyyy" }}
                              </td>
                              <td class="p-text-left width-15p">
                                  {{ rowData.InvoiceNumber }}
                              </td>
                              <td class="p-text-left width-40p p-text-nowrap p-text-truncate">
                                  <span pTooltip="{{ rowData.DescriptionShort }}" tooltipStyleClass="tooltip-width-450" tooltipPosition="top">{{ rowData.DescriptionShort }}</span>
                              </td>
                              <td class="p-text-right width-15p">{{ rowData.InvoiceAmount | currency:'USD':true:'1.2-2' }}</td>
                              <td class="p-text-right width-15p">{{ rowData.InvoiceBalance ? rowData.InvoiceBalance : 0 | currency:'USD':true:'1.2-2' }}</td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                              <td colspan="8">
                                  <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2"></p-message>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
              </p-panel>
          </div>
          <div class="p-col-12 p-mt-3 p-pr-0 manual">
              <p-panel header="{{ 'payments_history' | translate }}" [toggleable]="true">
                  <p-table [value]="clientPaymentsHistory"[responsive]="true" dataKey="PaymentHeaderID" [scrollable]="true" [scrollHeight]="'calc(55vh - 200px)'" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th class="width-10p p-text-left">{{ 'Accounts.payment-date' | translate }}</th>
                              <th class="width-10p p-text-left">{{ 'Accounts.payment-type' | translate }}</th>
                              <th class="width-11p p-text-left">{{ 'Accounts.check-reference' | translate }}</th>
                              <th class="width-11p p-text-right">{{ 'Accounts.payment-amount' | translate }}</th>
                              <th class="width-11p p-text-right">{{ 'Accounts.retainer_balance' | translate }}</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                          <tr>
                              <td class="width-10p p-text-left">
                                  {{rowData.PaymentDate | date:'MM/dd/yyyy'}}
                              </td>
                              <td class="width-10p p-text-left">
                                  {{rowData.PaymentType}}
                              </td>
                              <td class="width-11p p-text-left">
                                  {{rowData.CheckRef}}
                              </td>
                              <td class="width-11p p-text-right">
                                  {{ rowData.PaymentAmount | currency:'USD':true:'1.2-2'}}
                              </td>
                              <td class="width-11p p-text-right">
                                  {{ rowData.PaymentUnapplied ? rowData.PaymentUnapplied : 0 | currency:'USD':true:'1.2-2'}}
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                              <td>
                                  <p-message severity="info" text="{{ 'data_not_found' | translate }}." styleClass="p-px-2"></p-message>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
              </p-panel>
          </div>
      </div>
  </p-sidebar>

  <p-sidebar [(visible)]="showFilterSideBar" (onHide)="onCloseFilter()" position="right" [style]="{'min-width':'30%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
      <div class="card" style="min-height: calc(100vh - 400px)">
          <form role="form" [formGroup]="batchBillingForm" novalidate>
              <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text required-field">{{'Bill-Through-Date' | translate}}</label>
                      <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2090" (onSelect)="onDateSelect($event, 'InvoiceStartDate');" formControlName="InvoiceStartDate" showButtonBar="true"></p-calendar>
                  </div>
                  <div class="p-field p-col-12 p-mb-0 p-mt-2 p-pl-3 p-grid">
                      <p-checkbox [(ngModel)]="isIncluedeBillThrough" binary="true" [ngModelOptions]="{standalone: true}" label="{{'billing.include_bill_through_date_on_invoice' | translate}}"></p-checkbox>
                  </div>
                  <div class="p-field p-col-12 p-mb-0 p-mt-2 p-pl-3 p-grid">
                      <p-checkbox [(ngModel)]="includeInactiveClients" binary="true" [ngModelOptions]="{standalone: true}" label="{{'include_inactive_clients' | translate}}"></p-checkbox>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'user-title' | translate}}</label>
                      <p-dropdown [options]="AllStaffsTypes" formControlName="staffId">
                      </p-dropdown>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'Billing_Partner' | translate}}</label>
                      <p-dropdown [options]="AllStaffsTypes" formControlName="billingPartnerId"></p-dropdown>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'Locations' | translate}}</label>
                      <p-dropdown [options]="companyLocations" formControlName="companyLocationsId"></p-dropdown>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'Engagements' | translate}}</label>
                      <p-multiSelect [options]="engagementsTypes" class="multi-select" (onChange)="SelectEngagements($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selEngagementType" defaultLabel="All Engagements">
                          <ng-template let-engagement pTemplate="item">
                              <div style="width:100%;display:inline-block;vertical-align:middle">{{engagement.label}}</div>
                          </ng-template>
                      </p-multiSelect>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'billing-group' | translate}}</label>
                      <p-dropdown [options]="groupList" formControlName="selectedGroup" placeholder="All"></p-dropdown>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'client.group' | translate}}</label>
                      <p-multiSelect [options]="clientGroupList" formControlName="selectedGroupArray" selectedItemsLabel="{0} selected" placeholder="All"></p-multiSelect>
                  </div>
                  <div class="p-field p-col-12 p-fluid">
                      <label class="label-text">{{'engagement.billing_method' | translate}}</label>
                      <p-dropdown [options]="billingMethodList" formControlName="selectedBillingMethod" placeholder="All"></p-dropdown>
                  </div>
                  <div class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns">
                      <button type="button" pButton pRipple icon="fal fa-sync" class="p-mr-2" (click)="onFilterFetch()" label="{{ 'fetch' | translate }}"></button>
                      <button type="button" pButton pRipple icon="fal fa-undo" class="p-mr-2" label="{{ 'Reset' | translate }}" (click)="onResetFilters()"></button>
                      <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'cancel_button' | translate }}" (click)="onCloseFilter()"></button>
                  </div>
              </div>
          </form>
      </div>
  </p-sidebar>

  <p-sidebar [(visible)]="showRecordsSideBar" (onHide)="onCloseRecords()" position="right" [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
      <div class="card" *ngIf="recordsTable" style="min-height: calc(100vh - 35px)">
          <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
              <div class="p-col-12">
                  <label class="label-text ic-size-18">{{'customer_records' | translate}} </label>
              </div>
              <div class="p-col-12 p-formgrid p-grid ">
                  <div class="p-col-4 p-formgrid p-grid p-pl-2 p-pt-2 p-mt-3">
                      <div class="p-field p-col-4 ic-size-12">
                          <label class="label-text">{{ 'customer_name' | translate }}:</label>
                      </div>
                      <div class="p-field p-col-8">
                          <strong>{{ recordsTable.SearchClientName }}</strong>
                      </div>
                      <div class="p-field p-col-4 ic-size-12">
                          <label class="label-text">{{ 'engagement' | translate }}:</label>
                      </div>
                      <div class="p-field p-col-8 p-mb-0 p-d-flex p-jc-between">
                          <strong>{{ recordsTable.EngagementName }}</strong>
                      </div>
                  </div>
                  <div class="p-col-6 p-formgrid p-grid">
                      <div class="p-col-12 ic-size-12">
                          <label class="label-text">{{ 'engagement_invoice_description' | translate }}
                              <i class="fa fa-question-circle ic-blue ic-size-14" tooltipPosition="bottom" tooltipStyleClass="tooltip-width-800" pTooltip="{{'caret_template_tooltip' | translate}}"></i>
                          </label>
                      </div>
                      <div class="p-col-10">
                        <app-mentions [rows]="3" [(inputText)]="recordsTable['EngagementInvoiceDescription']"
                              (inputTextChange)="replaceCaret2('EngagementInvoiceDescription')" 
                              style="width:100% !important; resize: vertical;"></app-mentions>
                      </div>
                      <div class="p-col-2 p-pt-1">
                          <p-checkbox [binary]="true" label="{{'auto_update' | translate}}" class="label-text ic-size-12" [(ngModel)]="recordsTable['isAutoUpdate']"></p-checkbox>
                      </div>
                  </div>
                  <div class="p-col-2 p-pt-4">
                      <span style="margin-right: -25px;" class="pull-right" *ngIf="recordsTable?.allSelectedTimeSlips?.length > 0 || recordsTable?.allSelectedExpenseSlips?.length > 0">
                          <app-batch-options-time [selOpenTimeRecords]="recordsTable.allSelectedTimeSlips" [selOpenExpRecords]="recordsTable.allSelectedExpenseSlips" [showUpdateBilledAmt]="true" [defaultClient]="{ ClientID: recordsTable['ClientID'], ClientName: recordsTable['ClientName'] }" [deleteWuwdAndBilledAmt]="true" (onUpdate)="fetchDataSource()" (onUpdateBilledAmt)="onUpdateBilledAmt($event)"></app-batch-options-time>
                      </span>
                  </div>
              </div>
              <div class="p-col-12 p-pr-0 manual">
                  <p-panel header="{{ 'Open-Time-Records' | translate }}" [toggleable]="true">
                      <p-table [value]="recordsTable.allTimeSlips" [rows]="5" [pageLinks]="3" sortField="displayDate" [sortOrder]="-1" [rowHover]="true" [(selection)]="recordsTable.allSelectedTimeSlips" [responsive]="true" [resizableColumns]="true" metaKeySelection="true" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" (onHeaderCheckboxToggle)="checkHeaderCheckbox(recordsTable)" #dtchild (onFilter)="onFilterTime($event)" [globalFilterFields]="['StaffName', 'StaffNumber', 'Description', 'ServiceCode', 'Memo']" (onEditInit)="onEditInit($event, 'OpenTime')" (onEditComplete)="onEditComplete()" (onEditCancel)="onEditCancel()" [columns]="selectedColumns" [scrollable]="true" [scrollHeight]="recordsTable.allExpenseSlips.length > 0 ? 'calc(55vh - 200px)' : 'calc(85vh - 200px)'">
                          <ng-template pTemplate="caption">
                              <div class="table-header" style="margin-top: -10px;">
                                  <div class="p-d-flex p-jc-between">
                                      <div>
                                          <span class="p-input-icon-left">
                                              <i class="fal fa-search"></i>
                                              <input #searchValueTime pInputText type="text" (input)="dtchild.filterGlobal($event.target.value, 'contains')" placeholder="{{ 'Search-by' | translate }}" />
                                          </span>
                                          <span>
                                              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="dtchild.reset();clearSearchFilterTime()" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                          </span>
                                          <p-message *ngIf="filteredTimeItemsSize > -1" severity="success" text="{{filteredTimeItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2"></p-message>
                                      </div>
                                      <div class="p-d-flex p-jc-end">
                                          <span class="p-mt-1 p-mr-4" *ngIf="mangoCompanyData.ActivateLaborRates">
                                              <h5 class="ic-red">Tax: <span style="font-weight: 700">{{engagementLevelTimeTax  | currency: 'USD':true:'1.2-2'}}</span></h5>
                                          </span>
                                          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" (onChange)="onChangeSelectedCols($event)" placeholder="Choose Columns"></p-multiSelect>
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                  <th class="width-4p p-text-center">
                                      <p-tableHeaderCheckbox [disabled]="searchValueTime && searchValueTime.nativeElement.value"></p-tableHeaderCheckbox>
                                  </th>
                                  <th class="width-5p p-text-center">{{'actions' | translate}}</th>
                                  <th class="width-3p p-text-center" pTooltip="{{'Private-Memo' | translate}}" tooltipPosition="top">
                                      {{'PM' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field" class="p-text-left">
                                      <div [ngClass]="col?.canFilter ? 'p-d-flex p-jc-between' : ''">
                                          <div class="p-mt-1 p-d-flex p-ai-center">
                                              {{col.header}}
                                              <i *ngIf="col.field === 'ServiceCode'" pTooltip="{{'activity-code' | translate}}" tooltipPosition="top" class="fa fa-question-circle ic-blue"></i>
                                              <p-sortIcon [field]="col.field"></p-sortIcon>
                                          </div>
                                          <p-columnFilter *ngIf="col?.canFilter" [type]="col.type || 'text'" [field]="col.field" display="menu" (onFilter)="onFilterTime($event)"></p-columnFilter>
                                      </div>
                                  </th>
                                  <th class="width-5p p-text-right" pTooltip="{{'Billing-Hours' | translate}}" tooltipPosition="top">
                                      {{'billable-hrs' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-5p p-text-right">{{'billing-rate' | translate}}</th>
                                  <th class="width-3p p-text-center" pTooltip="{{'Non-Billable-tooltip' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                      NB
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-8p p-text-right" pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">{{'Standard_Amount' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-5p p-text-right" pTooltip="{{'writes-up-down' | translate}}" tooltipPosition="top">
                                      WUWD
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-5p p-text-right" pTooltip="{{'billed-amount' | translate}}" tooltipPosition="top">
                                      {{'billed' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-childrowData let-columns="columns" let-rowIndex="rowIndex">
                              <tr class="cursor-hand">
                                  <td class="width-4p p-text-center">
                                      <p-tableCheckbox [value]="childrowData" (click)="singleUncheck($event, childrowData, recordsTable);markAsChanged();">
                                      </p-tableCheckbox>
                                  </td>
                                  <td class="width-5p p-text-center">
                                      <i class="fal fa-save ic-blue ic-size-15 p-mr-2" (click)="saveTimeEntry($event, childrowData)" *ngIf="childrowData['IsColumnChanges']" pTooltip="{{'save_button' | translate}}" tooltipPosition="top"></i>
                                      <i class="fal fa-trash-alt ic-red ic-size-15" (click)="deleteClientAlert(childrowData,rowIndex, 'OpenTime');" tooltipPosition="top" pTooltip="{{'mgmt-delete' | translate}}"></i>
                                  </td>
                                  <td class="width-3p p-text-center">
                                      <i class="fa fa-sticky-note p-pr-2 cursor-hand" [ngClass]="(childrowData.PrivateMemo === null || childrowData.PrivateMemo === '') ? 'ic-grey' : 'ic-folder-yellow'" (click)="onShowScratchpad(childrowData)" pTooltip="{{ 'private_memo' | translate }}" tooltipPosition="top" showDelay="800"></i>
                                  </td>
                                  <td *ngFor="let col of columns" class="{{col.rowClass}} p-text-truncate"
                                      pTooltip="{{col.field === 'Ddate' ? childrowData['displayDate'] : childrowData[col.field]}}" showDelay="800"
                                      tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" pEditableColumn>
                                      <span *ngIf="col.field==='Ddate'">
                                          {{childrowData['displayDate']}}
                                      </span>
                                      <span *ngIf="col.field==='StaffName'">
                                          <p-cellEditor>
                                              <ng-template pTemplate="input">
                                                  <p-autoComplete placeholder="{{ childrowData.StaffName }}"
                                                      [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                                                      [(ngModel)]="childrowData['StaffName']" [suggestions]="filteredStaffSingle"
                                                      (onSelect)="handleDropStaffSelectClick($event, childrowData, 'Staff')"
                                                      (completeMethod)="filterStaffItems($event,childrowData)" field="label"
                                                      [size]="30" [minLength]="1" [dropdown]="true" appendTo="body">
                                                  </p-autoComplete>
                                              </ng-template>
                                              <ng-template pTemplate="output">
                                                  <div>{{ childrowData.StaffName }}</div>
                                              </ng-template>
                                          </p-cellEditor>
                                      </span>
                                      <span *ngIf="col.field==='StaffNumber'">
                                          {{ childrowData.StaffNumber }}
                                      </span>
                                      <span *ngIf="col.field==='scdescr'">
                                          <p-cellEditor>
                                              <ng-template pTemplate="input">
                                                  <p-dropdown [options]="activityList" placeholder="{{ childrowData.Description }}" [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])" [(ngModel)]="childrowData.ServiceCodeID" id="TimeDescription" name="TimeDescription" [filter]="true" #TimeDescription="ngModel" (onChange)="handleDropStaffSelectClick($event,childrowData,'Activity');markAsChanged()" appendTo="body" [style]="{'width':'200px !important'}" class="p-text-left">
                                                  </p-dropdown>
                                              </ng-template>
                                              <ng-template pTemplate="output">
                                                  <div>{{ childrowData['Description'] }}
                                                  </div>
                                              </ng-template>
                                          </p-cellEditor>
                                      </span>
                                      <span *ngIf="col.field==='ServiceCode'">
                                          {{ childrowData.ServiceCode }}
                                      </span>
                                      <span *ngIf="col.field==='Memo'" class="shortcut-desc">
                                          <p-cellEditor>
                                              <ng-template pTemplate="input">
                                                  <span>
                                                      <mwl-text-input-autocomplete-container>
                                                          <textarea pInputTextarea class="p-text-left width-100p" [(ngModel)]="childrowData['Memo']" (ngModelChange)="markAsChanged();replaceShortcuts2($event, childrowData, 'Memo');replaceCaret($event, childrowData)" id="desc_{{ childrowData['uuid'] }}" [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])" (focus)="$event.target.select()" [ngModelOptions]="{ standalone: true }" [rows]="1" style="resize: vertical;" mwlTextInputAutocomplete [findChoices]="mangoUtils.findChoices" [getChoiceLabel]="mangoUtils.getChoiceLabel" placeholder="{{'shortcut-placeholder' | translate}}" #textarea></textarea>
                                                      </mwl-text-input-autocomplete-container>
                                                      <app-mentions [rows]="1" [(inputText)]="childrowData['Memo']"
                                                          id="desc_{{ childrowData['uuid'] }}"
                                                          [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                                                          (inputTextChange)="replaceCaret($event, childrowData);childrowData['IsColumnChanges'] = true;" 
                                                          style="width:100% !important; resize: vertical;"></app-mentions>
                                                  </span>
                                              </ng-template>
                                              <ng-template pTemplate="output">
                                                  {{ childrowData.Memo }}
                                              </ng-template>
                                          </p-cellEditor>
                                      </span>
                                  </td>
                                  <td class="width-5p p-text-right" pEditableColumn>
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <input pInputText type="text"
                                              pKeyFilter="num"
                                              [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || (!this.timeexp?.ActualTimeEdit)"
                                              [(ngModel)]="childrowData['TotalTime']" mode="decimal"
                                              [ngModelOptions]="{standalone: true}"
                                              (ngModelChange)="onTotalTimeChange($event, childrowData);markAsChanged();childrowData['IsColumnChanges'] = true;"
                                              (focus)="$event.target.select()" class="width-100p p-text-right"
                                              (mouseup)="$event.preventDefault()" />
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              {{ childrowData.TotalTime }}
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                                  <td class="width-5p p-text-right" pEditableColumn>
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <p-inputNumber inputStyleClass="width-100p p-text-right" [ngModelOptions]="{standalone: true}" placeholder="{{childrowData['BillingRate']}}" [step]="0" (onFocus)="$event.target.select()" [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || (!this.timeexp?.BillableTimeEdit)" (keydown.enter)="onBillRateChange($event, childrowData);markAsChanged();" [(ngModel)]="childrowData['BillingRate']" mode="currency" currency="USD" locale="en-US" (onBlur)="onBillRateChange($event, childrowData)" (onInput)="childrowData['IsColumnChanges'] = true; markAsChanged();">
                                              </p-inputNumber>
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              {{ childrowData.BillingRate | currency: "USD":true:"1.2-2" }}
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                                  <td class="width-3p p-text-center" id="border-right">
                                      <i *ngIf="childrowData.nonbillableamount" class="pi pi-check ic-green"></i>
                                  </td>
                                  <td class="width-8p p-text-right" id="border-right">
                                      {{ childrowData.billableamount || childrowData.nonbillableamount | currency: "USD":true:"1.2-2" }}
                                  </td>
                                  <td class="width-5p p-text-right" id="border-right">
                                      <span [style.color]="setWriteUpDownColor(childrowData.WriteUpDown)">
                                          <span *ngIf="childrowData.WriteUpDown < 0">
                                              ({{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }})
                                          </span>
                                          <span *ngIf="childrowData.WriteUpDown >= 0">{{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }}
                                          </span>
                                      </span>
                                  </td>
                                  <td class="width-5p p-text-right" pEditableColumn>
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <p-inputNumber maxlength="14" minFractionDigits="2" maxFractionDigits="2" (onFocus)="$event.target.select()" placeholder="{{childrowData.BilledAmount}}" [ngModelOptions]="{standalone: true}" inputStyleClass="width-100p p-text-right" [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || !childrowData['Billable']" [step]="0" (onBlur)="onBilledAmountChange($event, childrowData);childrowData['BilledAmountChanged'] = true;markAsChanged();" (keydown.enter)="onBilledAmountChange($event, childrowData);childrowData['BilledAmountChanged'] = true;markAsChanged();" [(ngModel)]="childrowData.BilledAmount" mode="decimal">
                                              </p-inputNumber>
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              {{ childrowData.BilledAmount | currency: "USD":true:"1.2-2" }}
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="footer" let-columns>
                              <tr *ngIf="recordsTable.allTimeSlips.length > 0">
                                  <td class="width-4p"></td>
                                  <td class="width-5p"></td>                                  
                                  <td *ngFor="let col of columns" class="{{col.rowClass}}"></td>
                                  <td class="width-3p p-text-right p-text-bold ic-red">Totals</td>
                                  <td class="width-5p p-text-right p-text-bold ic-red">
                                      <span>{{ recordsTable.grandExpenseHrs | number: "1.2-2" }}</span>
                                  </td>
                                  <td class="width-5p"></td>
                                  <td class="width-3p p-text-right p-text-bold ic-red">&nbsp;</td>
                                  <td class="width-8p p-text-right p-text-bold ic-red">
                                      <span>{{ recordsTable.grandBillableamount + recordsTable.grandNonbillableamount | currency: "USD":true:"1.2-2" }}</span>
                                  </td>
                                  <td class="width-5p p-text-right p-text-bold ic-red">
                                      <span [style.color]="setWriteUpDownColor(recordsTable.grandWUWDamount)">
                                          <span *ngIf="recordsTable.grandWUWDamount < 0">
                                              ({{ recordsTable.grandWUWDamount | currency: "USD":"symbol":"1.2-2" }})
                                          </span>
                                          <span *ngIf="recordsTable.grandWUWDamount >= 0">{{ recordsTable.grandWUWDamount | currency: "USD":"symbol":"1.2-2" }}</span>
                                      </span>
                                  </td>
                                  <td class="width-5p p-text-right p-text-bold ic-red">
                                      <span>{{ recordsTable.grandBilledamount | currency: "USD":true:"1.2-2" }}</span>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="7">
                                      <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2"></p-message>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>
                  </p-panel>
              </div>
              <div class="p-col-12 p-mt-3 p-pr-0 manual" *ngIf="recordsTable.allExpenseSlips.length > 0">
                  <p-panel header="{{ 'Expense-Records' | translate }}" [toggleable]="true">
                      <p-table [value]="recordsTable.allExpenseSlips" [rows]="5" [pageLinks]="3" sortField="displayDate" [sortOrder]="-1" [rowHover]="true" [(selection)]="recordsTable.allSelectedExpenseSlips" [resizableColumns]="true" metaKeySelection="true" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" (onHeaderCheckboxToggle)="checkHeaderCheckbox(recordsTable)" #dtchildex (onEditInit)="onEditInit($event, 'Expense')" (onEditComplete)="onEditComplete()" (onEditCancel)="onEditCancel()" editMode="row" [scrollable]="true" [scrollHeight]="'calc(55vh - 200px)'">
                          <ng-template pTemplate="caption">
                              <div class="table-header" style="margin-top: -10px;">
                                  <div class="p-d-flex p-jc-end">
                                      <span class="p-mt-1 p-mr-4" *ngIf="mangoCompanyData.ActivateExpenseRates">
                                          <h5 class="ic-red">Tax: <span style="font-weight: 700">{{engagementLevelExpTax  | currency: 'USD':true:'1.2-2'}}</span></h5>
                                      </span>
                                      <span style="width: 200px">&nbsp;</span>
                                  </div>
                              </div>
                          </ng-template>
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                  <th class="width-4p p-text-center">
                                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                  </th>
                                  <th class="width-5p p-text-center">{{ 'action' | translate }}</th>
                                  <th class="width-9p p-text-center" pSortableColumn="displayDate">{{'date' | translate}}
                                      <p-sortIcon field="displayDate"></p-sortIcon>
                                  </th>
                                  <th class="width-15p p-text-left">{{'user-title' | translate}}</th>
                                  <th class="width-18p p-text-left">{{'description' | translate}}</th>
                                  <th class="width-9p p-text-right">{{'Units' | translate}}</th>
                                  <th class="width-9p p-text-right">{{'Cost' | translate}}</th>
                                  <th class="width-8p p-text-right" pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">{{'Standard_Amount' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-5p p-text-right" pTooltip="{{'writes-up-down' | translate}}" tooltipPosition="top">
                                      WUWD
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                                  <th class="width-5p p-text-right" pTooltip="{{'billed-amount' | translate}}" tooltipPosition="top">{{'billed' | translate}}
                                      <i class="fa fa-question-circle ic-blue"></i>
                                  </th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-childrowData let-rowIndex="rowIndex" let-columns="columns" let-editing="editing">
                              <tr class="cursor-hand" [pEditableRow]="childrowData" [pReorderableRow]="rowIndex">
                                  <td class="width-4p p-text-center">
                                      <p-tableCheckbox [value]="childrowData" (click)="singleUncheck($event, childrowData, recordsTable)">
                                      </p-tableCheckbox>
                                  </td>
                                  <td class="width-5p p-text-center">
                                      <i class="fal fa-save ic-blue ic-size-15 p-mr-2" (click)="saveTimeExpense($event, childrowData)" *ngIf="childrowData['IsColumnChanges']" pTooltip="{{'save_button' | translate}}" tooltipPosition="top"></i>
                                      <i class="fal fa-trash-alt ic-red ic-size-15" (click)="deleteClientAlert(childrowData,rowIndex, 'Expense');" tooltipPosition="top" pTooltip="{{'mgmt-delete' | translate}}"></i>
                                  </td>
                                  <td class="width-9p p-text-center" pEditableColumn>{{childrowData['displayDate']}}</td>
                                  <td class="width-15p p-text-left" pEditableColumn>
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <p-autoComplete placeholder="{{ childrowData.StaffName }}" [(ngModel)]="childrowData['StaffName']"
                                                  [suggestions]="filteredStaffSingle"
                                                  (onSelect)="handleDropStaffSelectClick($event, childrowData, 'Staff')"
                                                  (completeMethod)="filterStaffItems($event,childrowData)" field="label" [size]="30"
                                                  [minLength]="1" [dropdown]="true" appendTo="body"></p-autoComplete>
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              <div>{{ childrowData.StaffName }}</div>
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                                  <td class="width-18p p-text-left text-nowrap p-text-truncate shortcut-desc" pTooltip="{{childrowData.Memo}}" tooltipPosition="top" showDelay="800" [ngStyle]="{'overflow': (cellFocused && cellFocused.table === 'Expense' && cellFocused.field === 'Memo' && cellFocused.index === rowIndex) ? 'visible' : 'hidden'}" [pEditableColumn]="childrowData" [pEditableColumnField]="'Memo'" [pEditableColumnRowIndex]="rowIndex">
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <span>
                                                  <mwl-text-input-autocomplete-container>
                                                      <textarea pInputTextarea class="text-left width-100p" [(ngModel)]="childrowData['Memo']" (ngModelChange)="replaceShortcuts2($event, childrowData, 'Memo')" id="desc_{{ childrowData['uuid'] }}" (focus)="$event.target.select()" [ngModelOptions]="{ standalone: true }" [rows]="1" style="resize: vertical;" mwlTextInputAutocomplete [findChoices]="mangoUtils.findChoices" [getChoiceLabel]="mangoUtils.getChoiceLabel" placeholder="{{'shortcut-placeholder' | translate}}" #textarea></textarea>
                                                  </mwl-text-input-autocomplete-container>
                                                  <app-mentions [rows]="1" [(inputText)]="childrowData['Memo']"
                                                      id="desc_{{ childrowData['uuid'] }}" (inputTextChange)="childrowData['IsColumnChanges'] = true;"
                                                      style="width:100% !important; resize: vertical;"></app-mentions>
                                              </span>
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              {{ childrowData.Memo }}
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                                  <td class="width-9p p-text-right" (click)="openTimeEntryDailog(childrowData,false,recordsTable)">{{ childrowData.Units }}</td>
                                  <td class="width-9p p-text-right" (click)="openTimeEntryDailog(childrowData,false,recordsTable)">{{ childrowData.Cost }}</td>
                                  <td class="width-8p p-text-right" (click)="openTimeEntryDailog(childrowData,false,recordsTable)">{{childrowData.StandardAmount | currency: "USD":true:"1.2-2"}}</td>
                                  <td class="width-5p p-text-right" id="border-right">
                                      <span [style.color]="setWriteUpDownColor(childrowData.WriteUpDown)">
                                          <span *ngIf="childrowData.WriteUpDown < 0">
                                              ({{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }})
                                          </span>
                                          <span *ngIf="childrowData.WriteUpDown >= 0">{{ childrowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }}</span>
                                      </span>
                                  </td>
                                  <td class="width-5p p-text-right" pEditableColumn>
                                      <p-cellEditor>
                                          <ng-template pTemplate="input">
                                              <p-inputNumber maxlength="14" minFractionDigits="2" maxFractionDigits="2" (onFocus)="$event.target.select()" placeholder="{{childrowData.BilledAmount}}" [ngModelOptions]="{standalone: true}" inputStyleClass="width-100p p-text-right" [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate']) || !childrowData['Billable']" [step]="0" (onBlur)="onBilledAmountChange($event, childrowData, true);childrowData['BilledAmountChanged'] = true;markAsChanged();" (keydown.enter)="onBilledAmountChange($event, childrowData, true);childrowData['BilledAmountChanged'] = true;" [(ngModel)]="childrowData.BilledAmount" mode="decimal">
                                              </p-inputNumber>
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                              {{ childrowData.BilledAmount | currency: "USD":true:"1.2-2" }}
                                          </ng-template>
                                      </p-cellEditor>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="footer">
                              <tr *ngIf="recordsTable.allExpenseSlips.length > 0">
                                  <td class="width-4p"></td>
                                  <td class="width-5p"></td>
                                  <td class="width-9p"></td>
                                  <td class="width-15p"></td>
                                  <td class="width-18p"></td>
                                  <td class="width-9p"></td>
                                  <td class="width-9p p-text-right p-text-bold ic-red">Totals</td>
                                  <td class="width-8p p-text-right p-text-bold ic-red">
                                      <span>{{ recordsTable.grandExpenseTotalAmt | currency: "USD":true:"1.2-2" }}</span>
                                  </td>
                                  <td class="width-5p p-text-right p-text-bold ic-red">
                                      <span [style.color]="setWriteUpDownColor(recordsTable.grandExpenseWUWDamount)">
                                          <span *ngIf="recordsTable.grandExpenseWUWDamount < 0">
                                              ({{ recordsTable.grandExpenseWUWDamount | currency: "USD":"symbol":"1.2-2" }})
                                          </span>
                                          <span *ngIf="recordsTable.grandExpenseWUWDamount >= 0">{{ recordsTable.grandExpenseWUWDamount | currency: "USD":"symbol":"1.2-2" }}</span>
                                      </span>
                                  </td>
                                  <td class="width-5p p-text-right p-text-bold ic-red">
                                      <span>{{ recordsTable.grandExpenseBilledamount | currency: "USD":true:"1.2-2" }}</span>
                                  </td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td class="75">
                                      <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                      </p-message>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>
                  </p-panel>
              </div>
          </div>
      </div>
  </p-sidebar>
  <!-- ScratchPad sidebar -->
  <p-sidebar *ngIf="editableData != null" [(visible)]="scratchPadEnabled" position="bottom" [style]="{'min-height':'522px','background':'#8e8f92'}" (onHide)="onScratchpadClose()">
      <div class="card">
          <div class="control-label header-color text-nowrap p-col-12">
              <h4 class="label-text">{{ 'Private-Memo' | translate }}</h4>
          </div>
          <div class="p-col-12 col-md-12">
              <ejs-richtexteditor id='clientEditor' #clientEditor [(ngModel)]="editableData.PrivateMemo" [toolbarSettings]='tools' [height]="400" (ngModelChange)="onScratchpadChange()">
              </ejs-richtexteditor>
          </div>
      </div>
  </p-sidebar>
</div>
<p-sidebar [(visible)]="isPreviewVisible " [closeOnEscape]="false " [dismissible]="false " [showCloseIcon]="false" [fullScreen]="true " [style]="{ 'background': '#8e8f92'} ">
  <div class="card ">
      <div class="pull-right p-pr-4">
          <button type="button" pButton pRipple icon="fal fa-times p-mr-2" label="{{ 'close' | translate }}" (click)="closeDialog()"></button>
      </div>
      <div>
          <p-tabView>
              <p-tabPanel *ngIf="dialogTab1Display" header="{{headerTitle}}" [selected]="dialogTab1Display">
                  <div>
                      <ngx-extended-pdf-viewer *ngIf="dialogTab1Display" [(src)]="pdfUrl" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true" [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false" [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false" [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                      </ngx-extended-pdf-viewer>
                  </div>
              </p-tabPanel>
          </p-tabView>
      </div>
  </div>
</p-sidebar>
<p-sidebar *ngIf="invoiceMemoParent" [(visible)]="showParentMemo" (onHide)="closeInvoiceMemo()" position="bottom" [style]="{'min-height':'300px','background':'#8e8f92'}">
  <div class="card">
      <div class="control-label header-color text-nowrap p-col-12">
          <h4 class="label-text">{{'Invoice Memo' | translate}}</h4>
      </div>
      <span class="pull-right ic-size-15 p-text-bold">
          <span [ngStyle]="{'color':(invoiceMemoParent.customDescription.length > 250)? '#f28686' : '#83cc44' }">{{ invoiceMemoParent.customDescription.length }}</span>/250
      </span>
      <div class="p-col-12 col-md-12">
          <mwl-text-input-autocomplete-container>
              <textarea pInputTextarea class="width-100p" [rows]="7" #parentRowMemo maxlength="250" [ngStyle]="{'border-color':(invoiceMemoParent.customDescription.length > 250)? '#f28686' : '#ced4da' }" style="resize: vertical;" mwlTextInputAutocomplete [(ngModel)]="invoiceMemoParent.customDescription" [findChoices]="mangoUtils.findChoices" [getChoiceLabel]="mangoUtils.getChoiceLabel" (keyup)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" (focus)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" (keydown)="markAsChanged();replaceShortcuts(null, 'customDescription');replaceCaret2('customDescription');" placeholder="{{'shortcut-placeholder' | translate}}"></textarea>
          </mwl-text-input-autocomplete-container>
      </div>
  </div>
</p-sidebar>
