<div class="client-time-slips">
    <div class="layout-content">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="timeExpenseRecords" [rowHover]="true" [responsive]="true"
                        dataKey="ProjectMasterID" rowExpandMode="single" [pageLinks]="3"
                        [scrollable]="true" [scrollHeight]="'calc(100vh - 350px)'" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [filterDelay]="0" sortField="EngagementName"
                        [globalFilterFields]="['EngagementName','expenseamount']" [resizableColumns]="true"
                        (onFilter)="onFilter($event)" [sortOrder]="-1">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <span class="pull-right">
                                    <p-button label="{{'Time' | translate}}" pRipple icon="fal fa-plus"
                                        styleClass="p-button-sm p-mx-2"
                                        (click)="openTimeEntryOrExpenseEntryDailog({'IsTimeRecord':'T'},true)">
                                    </p-button>
                                    <p-button label="{{'Expense' | translate}}" pRipple icon="fal fa-plus"
                                        styleClass="p-button-sm"
                                        (click)="openTimeEntryOrExpenseEntryDailog({'IsTimeRecord':'X'},true)">
                                    </p-button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-4p"></th>
                                <th pSortableColumn="EngagementName" class="width-60p">{{ 'engagement' | translate }}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-right">{{ 'billing-invoicing.apply-to-expenses' | translate
                                    }}</th>
                                <th class="width-10p p-text-right">{{ 'billable-time' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'Non-Billable-Amount' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'billable-amount' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-parentRecord let-expanded="expanded">
                            <tr class="cursor-hand">
                                <td class="width-4p">
                                    <!-- <span [pRowToggler]="parentRecord" *ngIf="parentRecord.hasChildrens">
                                        <i pTooltip="{{ 'show_open_time_records' | translate }}" tooltipPosition="right"
                                            showDelay="900"
                                            [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                                        </i>
                                    </span> -->
                                    <i *ngIf="parentRecord.hasChildrens" class="fal fa-pencil-alt ic-red cursor-hand p-pl-2" pTooltip="{{ 'Edit' | translate }}"
                                        tooltipPosition="top" aria-hidden="true" (click)="onShowRecords(parentRecord)"></i>
                                </td>
                                <td class="p-text-left width-60p">
                                    {{parentRecord.EngagementName}}
                                </td>
                                <td class="width-10p p-text-right">{{ parentRecord.expenseamount |
                                    currency:'USD':true:'1.2-2' }}</td>
                                <td class="width-10p p-text-right">{{ parentRecord.totaltime | number:'1.2-2' }}</td>
                                <td class="width-10p p-text-right">{{ parentRecord.nonbillableamount |
                                    currency:'USD':true:'1.2-2' }}</td>
                                <td class="width-10p p-text-right">{{ parentRecord.laboramount |
                                    currency:'USD':true:'1.2-2' }}</td>
                            </tr>
                        </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="6">
                        <p-message severity="info" text="{{ 'data_not_found' | translate }}" styleClass="p-px-2">
                        </p-message>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && timeExpenseRecords.length > 0)">
                    <td colspan="2" class="p-text-right ic-red width-64p">{{ 'Totals' | translate }}</td>
                    <td class="p-text-right ic-red width-10p">
                        <span>{{ grandExpenseAmt | currency:'USD':true:'1.2-2'}}</span>
                    </td>
                    <td class="p-text-right ic-red width-10p">
                        <span>{{ grandBillableTime | number:'1.2-2'}}</span>
                    </td>
                    <td class="p-text-right ic-red width-10p">
                        <span>{{ grandNonBillableAmt | currency:'USD':true:'1.2-2' }}</span>
                    </td>
                    <td class="p-text-right ic-red width-10p">
                        <span>{{ grandBillableAmt | currency:'USD':true:'1.2-2' }}</span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </div>
    </div>
</div>
</div>
<p-sidebar [(visible)]="showRecordsSideBar" (onHide)="onCloseRecords()" position="right"
        [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card" *ngIf="recordsTable" style="min-height: calc(100vh - 35px)">
            <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
                <div class="p-col-12">
                    <label class="label-text ic-size-18">{{'customer_records' | translate}}</label>
                </div>
                <div class="p-fluid p-formgrid p-grid p-pl-2 p-pt-2 p-mt-3">
                    <div class="p-field p-col-1 ic-size-12">
                        <label class="label-text">{{ 'customer_name' | translate }}:</label>
                    </div>
                    <div class="p-field p-col-11">
                        <strong>{{ clientName }}</strong>
                    </div>
                    <div class="p-field p-col-1 ic-size-12">
                        <label class="label-text">{{ 'engagement' | translate }}:</label>
                    </div>
                    <div class="p-col-11 p-field p-mb-0 p-d-flex p-jc-between">
                        <strong>{{ recordsTable.EngagementName }}</strong>
                        <span *ngIf="selOpenTimeRecords[recordsTable['ProjectMasterID']]?.length > 0 || selOpenExpRecords[recordsTable['ProjectMasterID']]?.length > 0">
                            <app-batch-options-time 
                                [selOpenTimeRecords]="selOpenTimeRecords[recordsTable['ProjectMasterID']]" 
                                [defaultClient]="{ ClientID: clientID, ClientName: clientName }" 
                                [selOpenExpRecords]="selOpenExpRecords[recordsTable['ProjectMasterID']]"
                                (onUpdate)="fetchData()"
                            ></app-batch-options-time>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-pr-0 manual">
                    <p-panel header="{{ 'Open-Time-Records' | translate }}" [toggleable]="true">
                        <p-table [value]="recordsTable.allTimeSlips" [rows]="5" [pageLinks]="3"
                            sortField="Ddate" [sortOrder]="-1" [rowHover]="true"
                            [responsive]="true" [resizableColumns]="true" metaKeySelection="true"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            #dtchildOpen (onEditCancel)="onEditCancel()" [(selection)]="selOpenTimeRecords[recordsTable?.ProjectMasterID]"
                            (onEditInit)="onEditInit($event, 'OpenTime')" (onEditComplete)="onEditComplete()"
                            [scrollable]="true" [scrollHeight]="recordsTable?.allExpenseSlips?.length > 0 ? 'calc(55vh - 200px)' : 'calc(85vh - 200px)'">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-5p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-7p p-text-center">
                                        {{ 'actions' | translate }}
                                    </th>
                                    <th pSortableColumn="Ddate" class="width-8p p-text-center">{{ 'date' | translate }}
                                        <p-sortIcon field="Ddate"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="StaffName" class="width-9p p-text-left">{{ 'staff' | translate }}
                                        <p-sortIcon field="StaffName"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Description" class="width-15p p-text-left">{{ 'activity' |
                                        translate }}
                                        <p-sortIcon field="Description"></p-sortIcon>
                                    </th>
                                    <th class="p-text-left width-20p">
                                        {{ 'description' | translate }}
                                    </th>
                                    <th class="p-text-right width-5p">
                                        {{ 'billable-hrs' | translate }}
                                    </th>
                                    <th class="p-text-right width-5p">
                                        {{ 'billing-rate' | translate }}
                                    </th>
                                    <th class="p-text-right width-7p text-initial">
                                        {{ 'Non-Billable' | translate }}
                                    </th>
                                    <th class="p-text-right width-7p text-initial">
                                        {{ 'billable' | translate }}
                                    </th>
                                    <th class="width-6p p-text-center">
                                        {{ 'approved' | translate }}
                                    </th>
                                    <th class="width-6p p-text-right">
                                        {{ 'Flat-Amount' | translate }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-childTimeData let-columns="columns" let-rowIndex="rowIndex">
                                <tr class="cursor-hand">
                                    <td class="width-5p p-text-center">
                                        <p-tableCheckbox [value]="childTimeData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-7p p-text-left">
                                        <div class="p-text-center">
                                            <i class="fal fa-pencil cursor-hand  ic-green"
                                                (click)="openTimeEntryDailog(childTimeData,true)" tooltipPosition="top"
                                                pTooltip="{{ 'Edit' | translate }}"></i>
                                            <i (click)="deleteEngagement(childTimeData.SlipMasterID, childTimeData);"
                                                class="fal fa-trash-alt ic-red cursor-hand p-mx-3" tooltipPosition="top"
                                                pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                            <i class="fal fa-save ic-blue ic-size-15"
                                                (click)="saveTimeEntry($event, childTimeData)"
                                                *ngIf="childTimeData['IsColumnChanges']"
                                                pTooltip="{{ 'save_button' | translate }}" tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td class="width-8p p-text-center">
                                        <span>{{childTimeData['displayDate'] | date:'MM/dd/yyyy'}}</span>
                                    </td>
                                    <td class="width-9p p-text-left" pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-autoComplete placeholder="{{ childTimeData.StaffName }}"
                                                    [(ngModel)]="childTimeData['StaffName']"
                                                    [disabled]="childTimeData['Billed'] || isLocked(childTimeData['Ddate'])"
                                                    [suggestions]="filteredStaffSingle"
                                                    (onSelect)="handleDropStaffSelectClick($event, childTimeData, 'Staff');formChanged()"
                                                    (completeMethod)="filterStaffItems($event,childTimeData)" field="label"
                                                    [size]="30" [minLength]="1" [dropdown]="true" appendTo="body">
                                                </p-autoComplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div>{{ childTimeData.StaffName }}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-15p p-text-left" pEditableColumn tooltipPosition="top" showDelay="800"
                                        pTooltip="{{childTimeData.ServiceCode}}">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [options]="activityList"
                                                    placeholder="{{ childTimeData.ServiceCode }}"
                                                    [disabled]="childTimeData['Billed'] || isLocked(childTimeData['Ddate'])"
                                                    [(ngModel)]="childTimeData.ServiceCodeID" id="TimeDescription"
                                                    name="TimeDescription" [filter]="true" #TimeDescription="ngModel"
                                                    (onChange)="handleDropStaffSelectClick($event,childTimeData,'Activity');formChanged()"
                                                    appendTo="body" [style]="{'width':'200px !important'}"
                                                    class="p-text-left"></p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div>{{ childTimeData['ServiceCode'] }}
                                                </div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-20p p-text-left text-nowrap p-text-truncate" [ngStyle]="{'overflow': (cellFocused && cellFocused.table === 'OpenTime' && cellFocused.field === 'Memo' && cellFocused.index === rowIndex) ? 'visible' : 'hidden'}"
                                        [pEditableColumn]="childTimeData" [pEditableColumnField]="'Memo'"
                                        [pEditableColumnRowIndex]="rowIndex">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span>
                                                    <app-mentions [rows]="1" [(inputText)]="childTimeData['Memo']" (inputTextChange)="childTimeData['IsColumnChanges']=true;formChanged();"
                                                        style="width:100% !important; resize: vertical;" [disabled]="childTimeData['Billed'] || isLocked(childTimeData['Ddate'])"
                                                        id="desc_{{ childTimeData['uuid'] }}">
                                                    </app-mentions>
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{ childTimeData.Memo }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-5p p-text-right" pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="width-100p" pInputText
                                                    [(ngModel)]="childTimeData['TotalTime']"
                                                    (focus)="$event.target.select();"
                                                    [disabled]="childTimeData['Billed'] || isLocked(childTimeData['Ddate'])"
                                                    (blur)="onTotalTimeChange($event, childTimeData)"
                                                    (ngModelChange)="childTimeData['IsColumnChanges']=true;formChanged()">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{childTimeData.TotalTime | number:'1.2-2'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-5p p-text-right" pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="width-100p" pInputText
                                                    [(ngModel)]="childTimeData['BillingRate']"
                                                    (focus)="$event.target.select();"
                                                    [disabled]="childTimeData['Billed'] || isLocked(childTimeData['Ddate'])"
                                                    (blur)="changeUSMoney($event,childTimeData);onBillRateChange($event, childTimeData)"
                                                    (focus)="$event.target.select()"
                                                    (ngModelChange)="childTimeData['IsColumnChanges'] = true;formChanged()">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{ childTimeData.BillingRate | currency: "USD":true:"1.2-2" }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-7p p-text-right" (click)="openTimeEntryDailog(childTimeData,true)">
                                        {{childTimeData.nonbillableamount | currency:'USD':true:'1.2-2'}}
                                    </td>
                                    <td class="width-7p p-text-right" (click)="openTimeEntryDailog(childTimeData,true)">
                                        {{childTimeData.billableamount | currency:'USD':true:'1.2-2'}}
                                    </td>
                                    <td class="width-6p p-text-center" (click)="openTimeEntryDailog(childTimeData,true)">
                                        <i class="far fa-check ic-red" *ngIf="childTimeData['Approved']"></i>
                                    </td>
                                    <td class="width-6p p-text-right">
                                        <i class="far fa-check ic-red" *ngIf="childTimeData['isFlatFee']"></i>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="recordsTable?.allTimeSlips?.length > 0">
                                    <td class="width-5p"></td>
                                    <td class="width-7p"></td>
                                    <td class="width-8p"></td>
                                    <td class="width-9p"></td>
                                    <td class="width-15p"></td>
                                    <td class="width-20p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                    <td class="width-5p p-text-right ic-red">
                                        <span>{{ recordsTable.grandExpenseHrs | number:'1.2-2'}}</span>
                                    </td>
                                    <td class="width-5p p-text-right ic-red">
                                        <span>&nbsp;</span>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <span>{{ recordsTable.grandNonbillableamount | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <span>{{ recordsTable.grandBillableamount | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                    <td class="width-6p p-text-right ic-red">
                                        <span>&nbsp;</span>
                                    </td>
                                    <td class="width-6p p-text-right ic-red">
                                        <span>&nbsp;</span>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="11">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
                <div class="p-col-12 p-mt-3 p-pr-0 manual" *ngIf="recordsTable?.allExpenseSlips?.length > 0">
                    <p-panel header="{{ 'Expense-Records' | translate }}" [toggleable]="true">
                        <p-table [value]="recordsTable.allExpenseSlips" [pageLinks]="3"
                            sortField="Ddate" [sortOrder]="-1" [rowHover]="true" [(selection)]="selOpenExpRecords[recordsTable?.ProjectMasterID]"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            (onEditInit)="onEditInit($event, 'Expense')" (onEditComplete)="onEditComplete()"
                            (onEditCancel)="onEditCancel()" #dtchildExp dataKey="SlipMasterID"
                            [scrollable]="true" [scrollHeight]="'calc(55vh - 200px)'">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-5p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-7p p-text-center">{{ 'actions' | translate }}</th>
                                    <th class="width-8p p-text-center" pSortableColumn="Ddate">
                                        {{ 'date' | translate }}
                                        <p-sortIcon field="Ddate"></p-sortIcon>
                                    </th>
                                    <th class="width-9p p-text-left">{{ 'staff' | translate}}</th>
                                    <th class="width-10p p-text-left">{{ 'expense-code' | translate }}</th>
                                    <th class="width-23p p-text-center">{{ 'description' | translate }}</th>
                                    <th class="width-8p p-text-right">{{ 'Units' | translate }}</th>
                                    <th class="width-8p p-text-right">{{ 'Cost' | translate }}</th>
                                    <th class="width-8p p-text-right">{{ 'amount' | translate }}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-childrowData let-rowIndex="rowIndex">
                                <tr class="cursor-hand">
                                    <td class="width-5p p-text-center">
                                        <p-tableCheckbox [value]="childrowData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-7p p-text-center">
                                        <i class="fal fa-pencil cursor-hand  ic-green"
                                            (click)="openTimeEntryDailog(childrowData,false)" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}"></i>
                                        <i (click)="deleteEngagement(childrowData.SlipMasterID, childrowData)"
                                            class="fal fa-trash-alt ic-red cursor-hand p-mx-3" tooltipPosition="top"
                                            pTooltip="{{ 'mgmt-delete' | translate }}"></i>
                                        <i class="fas fa-save ic-blue ic-size-15" (click)="saveTimeExpense($event, childrowData)"
                                            *ngIf="childrowData['IsColumnChanges']" pTooltip="{{ 'save_button' | translate }}"
                                            tooltipPosition="top"></i>
                                    </td>
                                    <td class="width-8p p-text-center">
                                        <span>{{childrowData['displayDate'] | date:'MM/dd/yyyy'}}</span>
                                    </td>
                                    <td class="width-9p p-text-left" pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-autoComplete placeholder="{{ childrowData.StaffName }}"
                                                    [(ngModel)]="childrowData['StaffName']" [suggestions]="filteredStaffSingle"
                                                    (onSelect)="handleDropStaffSelectClick($event, childrowData, 'Staff');formChanged()"
                                                    (completeMethod)="filterStaffItems($event,childrowData)" field="label"
                                                    [size]="30" [minLength]="1" [dropdown]="true" appendTo="body"></p-autoComplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div>{{ childrowData.StaffName }}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-10p p-text-left" (click)="openTimeEntryDailog(childrowData,false)">
                                        {{childrowData.ExpenseCode}}
                                    </td>
                                    <td class="width-23p p-text-left text-nowrap p-text-truncate" [ngStyle]="{'overflow': (cellFocused && cellFocused.table === 'Expense' && cellFocused.field === 'Memo' && cellFocused.index === rowIndex) ? 'visible' : 'hidden'}"
                                        [pEditableColumn]="childrowData" showDelay="800" tooltipPosition="top"
                                        pTooltip="{{childrowData['Memo']}}" tooltipStyleClass="tooltip-no-wrap"
                                        [pEditableColumnField]="'Memo'" [pEditableColumnRowIndex]="rowIndex">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span>
                                                    <app-mentions [rows]="1" [(inputText)]="childrowData['Memo']" (inputTextChange)="childrowData['IsColumnChanges']=true;formChanged();"
                                                        style="width:100% !important; resize: vertical;"
                                                        id="desc_{{ childrowData['uuid'] }}">
                                                    </app-mentions>
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{ childrowData.Memo }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-8p p-text-right" (click)="openTimeEntryDailog(childrowData,false)">
                                        {{ childrowData.Units }}
                                    </td>
                                    <td class="width-8p p-text-right" (click)="openTimeEntryDailog(childrowData,false)">
                                        {{ childrowData.Cost }}
                                    </td>
                                    <td class="width-8p p-text-right" (click)="openTimeEntryDailog(childrowData,false)">
                                        {{childrowData.StandardAmount | currency: "USD":true:"1.2-2"}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="recordsTable.allExpenseSlips.length > 0">
                                    <td class="width-5p"></td>
                                    <td class="width-7p"></td>
                                    <td class="width-8p"></td>
                                    <td class="width-9p"></td>
                                    <td class="width-10p"></td>
                                    <td class="width-23p"></td>
                                    <td class="width-8p"></td>
                                    <td class="width-8p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                    <td class="width-8p p-text-right ic-red">
                                        <span>{{ recordsTable.grandExpenseTotalAmt | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="8">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>