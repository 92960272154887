<p-dialog
  header="{{ 'QuickLinks.expense-entry' | translate }}"
  [(visible)]="displayExpenseEntryModal"
  [modal]="true"
  [style]="{width: '50vw','min-height':'85%','max-height': '98%'}"
  [maximizable]="false"
  showEffect="fade"
  [contentStyle]="{overflow: 'visible'}"
  [baseZIndex]="1"
  [draggable]="true"
  [resizable]="false"
  (onHide)="closeExpenseEntryForm(true);"
>
  <div
    class="p-fluid p-formgrid p-m-1"
  >
    <form
      #expenseEntryMainForm="ngForm"
    >
      <div
        class="p-col-12 common-dialog"
      >
        <div
          class="p-formgroup-inline"
        >
          <div
            class="p-field p-col-6"
          >
            <label
              class="required-field label-text"
            >
              {{ 'staff' | translate }}
              <i
                tooltipPosition="top"
                pTooltip="{{ 'timesheetentry.staff-tooltip' | translate }}"
                tooltipStyleClass="tooltip-width-300"
                class="fa fa-question-circle ic-blue cursor-hand"
              >
              </i>
            </label>
            <p-dropdown
              [options]="staffList"
              [(ngModel)]="tse.StaffID"
              name="staffName"
              panelStyleClass="dialog-dropdown"
              #staffName="ngModel"
              placeholder="{{ 'select' | translate }} {{ 'staff' | translate }}"
              required
            >
            </p-dropdown>
          </div>
          <div
            class="p-field p-col-6"
          >
            <label
              class="label-text"
            >
              {{ 'expense-date' | translate }}
            </label>
            <p-calendar
              name="Ddate"

              yearRange="2000:2030"
              showButtonBar="true"

              [monthNavigator]="true"
              [yearNavigator]="true"

              [inputStyle]="{'width':'100px'}"

              [(ngModel)]="tse.Ddate"

              (onSelect)="
                validateExpenseDate( );
              "
            >
            </p-calendar>
          </div>
        </div>
        <div
          class="p-formgroup-inline"
        >
          <div
            class="p-field p-col-6"
          >
            <label
              class="required-field label-text"
            >
              {{ 'client' | translate }}
            </label>
            <p-autoComplete #autoComplete 
              placeholder="{{ 'type_client_name' | translate }}"
              [(ngModel)]="selClient"
              [suggestions]="filteredClients"
              autocomplete="off"
              [minLength]="3"
              (completeMethod)="filterClients($event)"
              (onSelect)="handleSelectClick($event)"
              field="ClientName"
              name="ClientIDstr"
              [disabled]="tse.isDisable"
              [dropdown]="true"
              required
              (onKeyUp)="handleClientKeyPress($event)">
              <ng-template let-client pTemplate="item">
                <div class="ui-helper-clearfix">
                  <div style="margin:8px 0 0 0">
                    {{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div
            class="p-col-2 p-mt-5"
          >
            <p-checkbox
              [binary]="true"
              label="{{ 'approved' | translate }}"
              class="label-text"
              [(ngModel)]="tse.ExpenseApproved"
              name="approved"
              [disabled]="true"
            >
            </p-checkbox>
          </div>
          <div
            class="p-col-2 p-mt-5"
          >
            <p-checkbox
              [binary]="true"
              label="{{ 'billable' | translate }}"
              [(ngModel)]="tse.Billable"
              name="billable"
              class="label-text"
            >
            </p-checkbox>
          </div>
          <div
            class="p-col-2 p-mt-5"
          >
            <p-checkbox
              [binary]="true"
              label="{{ 'Reimbursable' | translate }}"
              class="label-text"
              [(ngModel)]="tse.Reimburseable"
              name="reimburseable"
            >
            </p-checkbox>
          </div>
        </div>
        <div
          class="p-formgroup-inline"
        >
          <div
            class="p-field p-col-6"
          >
            <label
              class="required-field label-text"
            >
              {{ 'engagement' | translate }}
            </label>
            <button
              pButton
              pRipple
              (click)="addEngagementPopUp()"
              [disabled]="selClient==null || !selClient.ClientID"
              tooltipPosition="top"
              pTooltip="{{ 'add_engagement' | translate }}"
              tooltipStyleClass="tooltip-no-wrap"
              type="button"
              icon="fal fa-plus-circle"
              class="
                p-button-rounded
                p-button-danger
                p-button-text
                height-button
                pull-right
              "
            >
            </button>
            <p-dropdown
              panelStyleClass="dialog-dropdown"
              [options]="projectsList"
              [(ngModel)]="tse.ProjectMasterID"
              name="itemProjectSelected"
              [filter]="true"
              id="itemProjectSelected"
              #itemProjectSelected="ngModel"
              placeholder="{{ 'select-engagement' | translate }}"
              [disabled]="selClient==null || tse.isDisable || !selClient.ClientID"
              required
            >
            </p-dropdown>
          </div>
          <div
            class="p-field"
            [ngClass]="companyGlobalSetting.ActivateExpenseRates? 'p-col-2' : 'p-col-3'"
          >
            <label
              class="required-field label-text"
            >
              {{ 'Units' | translate }}
            </label>
            <input
              type="text"
              pInputText
              autocomplete="off"
              class="p-text-right"
              [(ngModel)]="tse.Units"
              name="units"
              #units="ngModel"
              required
              (blur)="calculateExpense()"
              (focus)="$event.target.select()"
            >
          </div>
          <div
            class="p-field"
            [ngClass]="companyGlobalSetting.ActivateExpenseRates? 'p-col-2' : 'p-col-3'"
          >
            <label
              class="required-field label-text"
            >
              {{ 'Cost' | translate }}
            </label>
            <input
              type="text"
              pInputText
              autocomplete="nope"
              [(ngModel)]="tse.Cost"
              (blur)="transformcost($event)"
              name="cost"
              class="p-text-right"
              [disabled]="!costOverRide"
              #cost="ngModel"
              (keypress)="calculateExpense()"
              (change)="calculateExpense()"
              required
              (focus)="$event.target.select()"
            >
          </div>
          <!--
          <div
            class="p-field p-col-2"
            *ngIf="companyGlobalSetting.ActivateExpenseRates"
          >
            <label
              class="label-text"
            >
              {{ 'tax' | translate }}
            </label>
            <input
              type="text"
              pInputText
              autocomplete="nope"
              defaultLabel="0"
              value="{{tse.Tax}}"
              disabled
              name="tax"
              class="p-text-right"
            >
          </div>
          -->
        </div>
        <div
          class="p-formgroup-inline"
        >
          <div
            class="p-field p-col-6"
          >
            <label
              class="required-field label-text"
            >
              {{ 'Expense' | translate }}
            </label>
            <button
              pButton
              pRipple
              (click)="openAddExpenses()"
              [disabled]="selClient==null || !selClient.ClientID"
              tooltipPosition="top"
              pTooltip="{{ 'Add_Expense' | translate }}"
              tooltipStyleClass="tooltip-no-wrap"
              type="button"
              icon="fal fa-plus-circle"
              class="p-button-rounded p-button-danger p-button-text pull-right height-button"
            >
            </button>
            <p-dropdown
              panelStyleClass="dialog-dropdown"
              name="selectedexpenseCodeID"
              [options]="activityList"
              [(ngModel)]="tse.ExpenseCodeID"
              placeholder="{{ 'select' | translate }} {{ 'Expense' | translate }}"
              [filter]="true"
              (onChange)="selectActivity($event)"
              #selectedexpenseCodeID="ngModel"
              required
              [disabled]="selClient==null || tse.isDisable || !selClient.ClientID"
            >
            </p-dropdown>
          </div>
          <div
            class="p-field"
            [ngClass]="companyGlobalSetting.ActivateExpenseRates? 'p-col-2' : 'p-col-3'"
          >
            <label
              class="label-text required-field"
            >
              {{ 'total-expenses' | translate }}
            </label>
            <input
              currencyMask
              pInputText
              type="text"
              class="p-text-right"
              [(ngModel)]="tse.StandardAmount"
              name="amount"
              [disabled]="true"
              #amount="ngModel"
              required
            />
          </div>
          <div
            class="p-mt-5"
            [ngClass]="companyGlobalSetting.ActivateExpenseRates? 'p-col-2' : 'p-col-3'"
          >
            <p-checkbox
              [binary]="true"
              label="{{ 'override-cost' | translate }}"
              class="label-text"
              [(ngModel)]="costOverRide"
              name="costOverRide"
            >
            </p-checkbox>
          </div>
          <div
            class="p-col-2 p-mt-5"
            *ngIf="companyGlobalSetting.ActivateExpenseRates"
          >
            <p-checkbox
              [binary]="true"
              label="{{ 'taxable' | translate }}"
              class="label-text"
              [(ngModel)]="tse.IsTaxable"
              (ngModelChange)="calculateSalesTax()"
              name="IsTaxable"
            >
            </p-checkbox>
          </div>
        </div>
        <div
          class="p-formgroup-inline"
        >
          <div
            class="p-col-3 p-mb-3"
          >
            <label
              class="label-text"
            >
              {{ 'Attachments' | translate }}
              <span
                [ngClass]="{'notAllowed': !expenseEntryMainForm.valid}"
              >
                <i
                  pTooltip="{{ 'attach-files.tooltip' | translate }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                  class="fal fa-cloud-upload upload-text-danger ic-size-16 p-ml-2 ic-red cursor-hand"
                  (click)="showFiles()"
                >
                </i>
              </span>
            </label>
          </div>
          <div
            class="p-col-9 p-mb-3 p-mx-0 p-px-0"
          >
            <span
              class="ic-blue p-mx-1"
              *ngFor="let fileObj of fileNames"
            >
              <span>
                {{fileObj['FName']}}
              </span>
              <i
                class="fal fa-times ic-red cursor-hand p-ml-1"
                (click)="deleteFile(fileObj)"
                tooltipPosition="top"
                pTooltip="{{ 'Remove' | translate }} {{ 'File' | translate }}"
                pRipple
              >
              </i>
            </span>
          </div>
        </div>
        <div
          class="p-field p-col-12"
          *ngIf="showAttachedFiles"
        >
          <label
            for="address"
            class="label-text"
          >
            {{ 'Upload' | translate }}
          </label>
          <div class="card flex justify-content-center" style="width: 100%;">
            <p-fileUpload name="fileupload" [disabled]="!expenseEntryMainForm.valid" #fileUpload [auto]="true" chooseLabel="Upload" (onSelect)="onSelect($event)"
              [multiple]="true" accept=".csv,.txt,.pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png,.tif,.rtf" maxFileSize="3145728">
              <ng-template pTemplate="content">
                <div class="p p-d-flex p-jc-center" *ngIf="files.length == 0" style="height: 40px;">
                  Drag file(s) here
                </div>
                <ul *ngIf="files.length > 0">
                  <li *ngFor="let file of files">
                    {{ file.name }} - {{ file.size }} bytes
                  </li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
        <div
          class="p-field p-col-12"
        >
          <label
            for="exense"
            class="label-text"
            [ngClass]="{'required-field': companyGlobalSetting.isExpenseMemoRequired, '': !companyGlobalSetting.isExpenseMemoRequired}"
          >
            {{ 'Expense' | translate }} {{ 'Memo' | translate }}
          </label>
          <app-mentions [(inputText)]="tse.Memo" [rows]="3" style="width: 100%; resize: vertical;"></app-mentions>
        </div>
      </div>
    </form>
  </div>
  <ng-template
    pTemplate="footer"
  >
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-file-alt"
      label="{{ 'Private-Memo' | translate }}"
      (click)="isShowMemo=!isShowMemo"
      class="pull-left"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      label="{{ 'save_and_close' | translate }}"
      [disabled]="expenseEntryMainForm.invalid || expenseEntryMainForm.pristine"
      (click)="saveAclose('c')"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      label="{{ 'save_and_new' | translate }}"
      [disabled]="expenseEntryMainForm.invalid || expenseEntryMainForm.pristine"
      (click)="saveAclose('n')"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      label="{{ 'save_and_duplicate' | translate }}"
      [disabled]="expenseEntryMainForm.invalid || expenseEntryMainForm.pristine"
      (click)="saveAclose('d')"
    >
    </button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      label="{{ 'cancel_button' | translate }}"
      (click)="closeExpenseEntryForm(true)"
    >
    </button>
  </ng-template>
</p-dialog>
<p-sidebar
  [(visible)]="isShowMemo"
  [baseZIndex]="10001"
  position="bottom"
  styleClass="p-sidebar-md"
>
  <div
    class="card"
  >
    <div
      class="p-field p-col-12"
    >
      <label
        class="label-text"
      >
        {{ 'Private-Memo' | translate }}
      </label>
      <app-mentions [(inputText)]="tse.PrivateMemo" style="width: 100%; resize: vertical;"></app-mentions>
    </div>
  </div>
</p-sidebar>
