<div class="client-invoices">
    <div class="layout-content">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="tempInvoice2" [rowHover]="true" [responsive]="true" dataKey="BillingHeaderID"
                        [pageLinks]="3" rowExpandMode="single" [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [sortOrder]="-1" sortField="InvoiceDate"
                        [globalFilterFields]="['InvoiceDate','InvoiceNumber','EngagementName','DescriptionShort','InvoiceBalance','TotalPayments','Discount','InvoiceAmount']"
                        (onRowExpand)="onRowExpandEvent($event)" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 350px)'" [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                                <span class="pull-right">
                                    <p-dropdown [options]="invoiceOption" [(ngModel)]="nameCT" optionLabel="label"
                                        (onChange)="chageFilterAIA()"></p-dropdown>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-left"></th>
                                <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="InvoiceDate" pResizableColumn class="width-8p p-text-left">{{
                                    'Accounts.invoice-date' | translate }}
                                    <p-sortIcon field="InvoiceDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="InvoiceNumber" pResizableColumn class="width-8p p-text-left">{{
                                    'Accounts.invoice-number' | translate }}
                                    <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-9p p-text-left">{{ 'engagement' | translate }}</th>
                                <th pResizableColumn class="width-29p p-text-left">{{
                                    'billing-invoicing.invoice-description' | translate }}</th>
                                <th pResizableColumn class="width-7p p-text-right">{{ 'Accounts.invoice-amount' |
                                    translate }}</th>
                                <th pResizableColumn class="width-7p p-text-right">{{ 'billing-invoicing.discount' |
                                    translate }}</th>
                                <th pResizableColumn class="width-7p p-text-right">{{ 'client.payments' | translate }}
                                </th>
                                <th pSortableColumn="InvoiceBalance" pResizableColumn class="width-7p p-text-right">
                                    {{ 'balance' | translate }}
                                    <p-sortIcon field="InvoiceBalance"></p-sortIcon>
                                </th>
                                <th pResizableColumn class="width-4p p-text-center">{{ 'email' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-client let-expanded="expanded">
                            <tr class="cursor-hand" [ngClass]="client.InvoicePosted?'-':'ic-red'">
                                <td class="width-3p p-text-center">
                                    <!-- <i tooltipPosition="top" pTooltip="{{ 'show_payments' | translate }}" pRipple showDelay="900"
                                        [ngClass]="expanded ? 'far fa-chevron-down cursor-hand' : 'far fa-chevron-right cursor-hand'">
                                    </i> -->
                                    <i *ngIf="client.isExpandble" class="far fa-chevron-right" tooltipPosition="top"
                                        pTooltip="{{ 'show_payments' | translate }}"
                                        (click)="openShowPayments(client)"></i>
                                </td>
                                <td class="width-8p">
                                    <div class="p-text-center">
                                        <i class="fal fa-print p-mr-3 ic-red" tooltipPosition="top"
                                            pTooltip="{{ 'preview' | translate }}"
                                            (click)="previewInvoice($event,client)"></i>
                                        <i class="fal fa-pencil p-mr-3 ic-green" tooltipPosition="top"
                                            pTooltip="{{ 'Edit' | translate }}" (click)="editInvoice(client)"></i>
                                        <i class="fal fa-history  p-mr-3 ic-orange" tooltipPosition="top"
                                            pTooltip="{{ 'Time-Report' | translate }}"
                                            (click)="previewTimeHistoryReport(client)"></i>
                                        <i class="fal fa-undo ic-blue" tooltipPosition="top"
                                            pTooltip="{{ 'reverse' | translate }}" (click)="reverseInvoice(client)"></i>
                                    </div>
                                </td>
                                <td
                                  class="width-8p p-text-left"
                                  tooltipPosition="top"
                                  pTooltip="{{ client.displayDate | date:'MM/dd/yyyy' }}"
                                  [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''"
                                >
                                  {{ client.displayDate | date:'MM/dd/yyyy' }}
                                </td>
                                <td class="width-8p p-text-left"
                                    tooltipPosition="top"
                                    pTooltip="{{client.InvoiceNumber}}"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{client.InvoiceNumber}}
                                </td>
                                <td class="width-9p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{client.EngagementNames}}" showDelay="1000"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{client.EngagementNames}}
                                </td>
                                <td class="width-29p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    pTooltip="{{client.DescriptionShort}}" tooltipStyleClass="tooltip-width-800"
                                    showDelay="1000"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{client.DescriptionShort}}
                                </td>
                                <td class="width-7p p-text-right"
                                    tooltipPosition="top"
                                    pTooltip=" {{(client.InvoiceAmount + (client.TotalTax ? client.TotalTax : 0)  + (client.TotalTaxExpenses ? client.TotalTaxExpenses : 0) ) | currency : 'USD' : true : '1.2-2' }}"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{(client.InvoiceAmount + (client.TotalTax ? client.TotalTax : 0)  + (client.TotalTaxExpenses ? client.TotalTaxExpenses : 0) ) | currency :
                                    'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-7p p-text-right"
                                    tooltipPosition="top"
                                    pTooltip="{{client.Discount | currency : 'USD' : true : '1.2-2' }}"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{client.Discount | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-7p p-text-right"
                                    tooltipPosition="top"
                                    pTooltip="{{client.TotalPayments | currency : 'USD' : true : '1.2-2' }}"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{client.appliedamountcalculated | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-7p p-text-right"
                                    tooltipPosition="top"
                                    pTooltip="{{(client.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}"
                                    [ngClass]="(client.InvoiceType === 'Retainer Invoice')?'ic-green':(client.InvoiceType === 'Debit Memo')?'ic-purple':''">
                                    {{(client.invoicebalance) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td class="width-4p p-text-center">
                                    <i class="far fa-envelope ic-blue" tooltipPosition="top"
                                        pTooltip="{{ 'email_invoice' | translate }}" (click)="sendMail(client);"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && tempInvoice2.length > 0)">
                                <td [attr.colspan]="6" class="p-text-right p-text-bold ic-red  width-68p">{{ 'Totals' |
                                    translate }}</td>
                                <td class="p-text-right p-text-bold ic-red width-7p" tooltipPosition="top" pTooltip="{{ (totalInvoiceAmount+totalTax+totalTaxExpenses) | currency:'USD':'symbol':'1.2-2' }}">
                                    <span *ngIf="tempInvoice2.length > 0">{{ (totalInvoiceAmount+totalTax+totalTaxExpenses) |
                                        currency:'USD':'symbol':'1.2-2' }}</span>
                                </td>
                                <td class="p-text-right p-text-bold ic-red width-7p" tooltipPosition="top" pTooltip="{{ totalDiscount | currency:'USD':'symbol':'1.2-2'}}">
                                    <span *ngIf="tempInvoice2.length > 0">{{ totalDiscount | currency:'USD':'symbol':'1.2-2'}}</span>
                                </td>
                                <td class="p-text-right p-text-bold ic-red width-7p" tooltipPosition="top" pTooltip="{{ totalPaymentsApplied | currency:'USD':'symbol':'1.2-2'}}">
                                    <span *ngIf="tempInvoice2.length > 0">{{ totalPaymentsApplied |
                                        currency:'USD':'symbol':'1.2-2'}}</span>
                                </td>
                                <td class="p-text-right p-text-bold ic-red width-7p" tooltipPosition="top" pTooltip="{{ totalInvoiceBalance | currency:'USD':'symbol':'1.2-2'}}">
                                    <span *ngIf="tempInvoice2.length > 0">{{ totalInvoiceBalance |
                                        currency:'USD':'symbol':'1.2-2'}}</span>
                                </td>
                                <td class="width-4p"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="11">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <p-sidebar [(visible)]="sidePanelDisplay" position="right" (onHide)="cancelShowPayments()"
        [style]="{'min-width':'70%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card" style="min-height: calc(100vh - 34px)">
            <div class="p-col-12 p-formgroup-inline">
                <label class="p-col-5 label-text ic-size-18 p-pb-4">
                    Payment Details - {{ engagementName }}
                </label>
            </div>
            <div class="card p-py-1">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-mt-3 p-pr-0 manual">
                        <p-panel header="Payments Applied" [toggleable]="false">
                            <p-table [value]="paymentDetails" [rowHover]="true" sortField="PaymentDate"
                                dataKey="BillingHeaderID" [sortOrder]="-1"
                                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [scrollable]="true" [scrollHeight]="'calc(85vh - 200px)'" #dtchild>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                        <th pSortableColumn="displayDate" pResizableColumn
                                            class="width-12p p-text-center">
                                            Payment Date
                                            <p-sortIcon field="displayDate"></p-sortIcon>
                                        </th>
                                        <th class="width-10p p-text-center">Payment Type</th>
                                        <th class="width-10p p-text-center">Check Reference</th>
                                        <th class="width-10p p-text-center">{{
                                            'Accounts.invoice-number' | translate }}</th>
                                        <th class="width-10p p-text-right">Payment</th>
                                        <th class="width-10p p-text-right">Written-Off</th>
                                        <th class="width-20p p-text-left">Payment Note</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-childrowData let-columns="columns">
                                    <tr class="cursor-hand">
                                        <td class="width-6p p-text-center">
                                            <i class="fal fa-undo p-mr-3 ic-green" tooltipPosition="top"
                                                (click)="unapplyPayment(childrowData)"
                                                pTooltip="{{ 'Unapply Payment' | translate }}"></i>
                                        </td>
                                        <td class="width-12p p-text-center">
                                            <span>{{childrowData.displayDate | date:'MM/dd/yyyy'}}</span>
                                        </td>
                                        <td class="width-10p p-text-center"> {{childrowData.PaymentType}}</td>
                                        <td class="width-10p p-text-center">{{childrowData.CheckRef}}</td>
                                        <td class="width-10p p-text-center">{{childrowData.InvoiceNumber}}</td>
                                        <td class="width-10p p-text-right">
                                            {{ childrowData.AppliedAmount | currency:'USD':'symbol':'1.2-2' }}
                                        </td>
                                        <td class="width-10p p-text-right">
                                            {{childrowData.WriteOffAmount | currency:'USD':'symbol':'1.2-2'}}
                                        </td>
                                        <td class="width-20p p-text-left p-text-nowrap p-text-truncate"
                                            tooltipPosition="top" pTooltip="{{childrowData.PaymentNote}}"
                                            showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                                            {{childrowData.PaymentNote}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td class="width-6p p-text-center"></td>
                                        <td class="width-12p"></td>
                                        <td class="width-10p"></td>
                                        <td class="width-10p"></td>
                                        <td class="width-10p p-text-right p-text-bold ic-red">{{ 'Totals' | translate }}
                                        </td>
                                        <td class="width-10p p-text-right p-text-bold ic-red">
                                            {{totalPaymentAmount[clientIndex] | currency:'USD':'symbol':'1.2-2'}}
                                        </td>
                                        <td class="width-10p p-text-right p-text-bold ic-red">
                                            {{ totalWriteOffAmount[clientIndex] | currency:'USD':'symbol':'1.2-2' }}
                                        </td>
                                        <td class="width-20p"></td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td [attr.colspan]="7">
                                            {{ 'data_not_found' | translate }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
