<div class="client-payments">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <app-apply-advance-to-invoice [selectedClient]="selectedCustomer"
                (onCloseApplyAdvanceDialog)="onCloseApplyAdvanceDialog($event)"
                [tooltip]="'Apply Multiple Advance Payments to Invoice(s)'" [label]="'Apply Advance Payments'">
            </app-apply-advance-to-invoice>
        </span>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt [value]="payments" [rowHover]="true" [responsive]="true" dataKey="PaymentHeaderID"
                        selectionMode="single" [pageLinks]="3" [filterDelay]="0" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 350px)'"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        rowExpandMode="single" sortField="PaymentDate" [sortOrder]="-1"
                        [globalFilterFields]="['PaymentDate','CheckRef','PaymentType','PaymentAmount','WriteOffAmount','PaymentUnapplied']"
                        [resizableColumns]="true" (onFilter)="onFilter($event)">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-center"></th>
                                <th class="width-8p p-text-center">{{ 'actions' | translate }}</th>
                                <th class="width-9p p-text-center" pSortableColumn="PaymentDate">
                                    {{ 'Accounts.payment-date' | translate }}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="CheckRef">
                                    {{ 'Accounts.check-reference' | translate }}
                                    <p-sortIcon field="CheckRef"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left" pSortableColumn="PaymentType">
                                    {{ 'Accounts.payment-type' | translate }}
                                    <p-sortIcon field="PaymentType"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-right">{{ 'Accounts.payment-amount' | translate }}</th>
                                <th class="width-7p p-text-right">{{ 'Accounts.retainer_balance' | translate }}</th>
                                <th class="width-7p p-text-right">{{ 'Account.written_off' | translate }}</th>
                                <th class="width-23p p-text-left">{{ 'Account.payment_note' | translate }}</th>
                                <th class="width-10p p-text-left">{{ 'Electronic Source' | translate }}</th>
                                <th class="width-5p p-text-center">{{ 'Account.deposited' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-client let-ri="rowIndex" let-expanded="expanded">
                            <tr class="cursor-hand">
                                <td class="width-3p">
                                    <i *ngIf="client.isExpandble" tooltipPosition="top" pTooltip="Payments Applied"
                                        (click)="openShowPayments(client)" class="far fa-chevron-right"> </i>
                                </td>
                                <td class="width-8p">
                                    <div class="p-text-center">
                                        <i class="fal fa-undo p-mr-3 ic-green" (click)="reversePayment(client,true,ri)"
                                            tooltipPosition="top" pTooltip="{{ 'reverse-payment' | translate }}"></i>
                                        <i (click)="reversePayment(client,false,ri,true)" *ngIf="!!!client['NSFPaymentHeaderID']"
                                            class="fal fa-thumbs-down  p-mr-3 ic-red" tooltipPosition="top"
                                            pTooltip="{{ 'process_returned_item' | translate }}"></i>
                                        <i *ngIf="client.PaymentType === 'Credit Memo'"
                                            class="fal fa-file-pdf ic-orange p-mr-3"
                                            (click)="previewInvoice($event,client)" tooltipPosition="top"
                                            pTooltip="{{ 'preview' | translate }}"></i>
                                        <i class="fal fa-money-check-alt ic-purple p-mr-3" tooltipPosition="top"
                                            *ngIf="client.PaymentUnapplied > 0"
                                            pTooltip="{{ 'convert_to_debit_memo' | translate }}"
                                            tooltipStyleClass="tooltip-no-wrap"
                                            (click)="onShowDebitMemoDialog(client)"></i>
                                        <i class="fal fa-exchange ic-mc p-mr-3" tooltipPosition="top"
                                            *ngIf="client.PaymentUnapplied > 0"
                                            pTooltip="{{ 'transfer-advance-payment' | translate }}"
                                            tooltipStyleClass="tooltip-no-wrap"
                                            (click)="transferAdvancePayment(client)"></i>
                                    </div>
                                </td>
                                <td
                                  class="
                                    width-9p
                                    p-text-center
                                  "

                                  [ngClass]="
                                      (client.hasRetainer > 0)
                                    ? 'ic-green'
                                    : (
                                          (client.hasDebitMemo > 0)
                                        ? 'ic-purple'
                                        : ''
                                      )
                                  "
                                >
                                  {{ client.PaymentDate | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-7p p-text-left" [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')">{{client.CheckRef}}</td>
                                <td class="width-7p p-text-left" [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')"> {{client.PaymentType}}</td>
                                <td class="width-7p p-text-right" [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')">{{ client.AppliedAmount | currency:'USD':true:'1.2-2' }}</td>
                                <td class="width-7p p-text-right" [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')">{{ client.PaymentUnapplied |
                                    currency:'USD':true:'1.2-2' }}</td>
                                <td class="width-7p p-text-right" [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')">{{ client.WriteOffAmountCalculated |
                                    currency:'USD':true:'1.2-2' }}</td>
                                <td class="width-23p p-text-left p-text-nowrap p-text-truncate" showDelay="800"
                                    tooltipPosition="top" pTooltip="{{client.PaymentNote}}"
                                    [ngClass]="(client.hasRetainer > 0)?'ic-green': ((client.hasDebitMemo > 0)? 'ic-purple' : '')"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    {{client.PaymentNote}}
                                </td>
                                <td class="width-10p p-text-left">
                                  {{client.paymentSource}}
                                </td>
                                <td class="width-5p p-text-center">
                                    <i class="fal fa-university ic-orange" *ngIf="client.PaymentToBankID"></i>
                                    <i class="fal fa-university" *ngIf="!client.PaymentToBankID"
                                        style="opacity: 0.2"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="rowexpansion" let-client>
                            <tr>
                                <td></td>
                                <td [attr.colspan]="9">
                                    <div class="p-p-2 bg-blue">
                                        <p-table #dtchild [value]="client.paymentDetails" dataKey="PaymentDetailID"
                                            [rowsPerPageOptions]="[5,10,20]" [rowHover]="true" sortField="InvoiceDate"
                                            [paginator]="client.paymentDetails.length > 10" [paginatorPosition]="'top'"
                                            [sortOrder]="-1"
                                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th class="width-14p p-text-center" pSortableColumn="InvoiceDate">
                                                        {{ 'Accounts.invoice-date' | translate }}
                                                        <p-sortIcon field="InvoiceDate"></p-sortIcon>
                                                    </th>
                                                    <th class="width-10p p-text-center">
                                                        {{ 'Accounts.invoice-number' | translate }}
                                                    </th>
                                                    <th class="width-14p p-text-right">
                                                        {{ 'Accounts.amount_applied' | translate }}
                                                    </th>
                                                    <th class="width-14p p-text-center">
                                                        {{ 'Account.write_off_amount' | translate }}
                                                    </th>
                                                    <th class="p-text-center">

                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-childrowData>
                                                <tr class="cursor-hand">
                                                    <td class="p-text-center">
                                                        <span>{{mangoUtils.formatDateString(childrowData.InvoiceDate) |
                                                            date:'MM/dd/yyyy'}}</span>
                                                    </td>
                                                    <td class="p-text-center">{{childrowData.InvoiceNumber}}</td>
                                                    <td class="p-text-right">
                                                        {{ childrowData.AppliedAmount | currency:'USD':'symbol':'1.2-2' }}
                                                    </td>
                                                    <td class="p-text-center">
                                                        <span *ngIf="childrowData.WriteOffAmount > 0" id="remove-border">{{
                                                            childrowData.WriteOffAmount | currency:'USD':true:'1.2-2' }}</span>
                                                    </td>
                                                    <td class="p-text-center">
                                                        <span></span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="footer">
                                                <tr>
                                                    <td colspan="2" class="p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                                    <td class="p-text-right ic-red">
                                                        <span>{{ client.paymentDetails.total | currency:'USD':true:'1.2-2' }}</span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                </div>
                </td>
                </tr>
                </ng-template> -->
                        <ng-template pTemplate="footer">
                            <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && payments.length > 0)">
                                <td class="width-3p"></td>
                                <td class="width-8p"></td>
                                <td class="width-9p p-text-center"></td>
                                <td class="width-7p p-text-left"></td>
                                <td class="width-7p p-text-right ic-red "> {{ 'Totals' | translate }}</td>
                                <td class="width-7p p-text-right ic-red"><span>{{ totalPaymentAmount |
                                        currency:'USD':true:'1.2-2' }}</span> </td>
                                <td class="width-7p p-text-right ic-red"><span>{{ totalRetainerBalance |
                                        currency:'USD':true:'1.2-2' }}</span></td>
                                <td class="width-7p p-text-right ic-red"><span>{{ totalWriteOff |
                                        currency:'USD':true:'1.2-2' }}</span></td>
                                <td class="width-23p p-text-left p-text-nowrap p-text-truncate"></td>
                                <td class="width-10p p-text-center"></td>
                                <td class="width-5p p-text-center"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="11">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}"
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
  <p-sidebar
    *ngIf="sidePanelDisplay"

    position="right"

    (onHide)="cancelShowPayments()"

    [(visible)]="sidePanelDisplay"

    [style]="
      {
        'min-width':'80%',
        'min-height':'100%',
        'background':'#8e8f92',
        'overflow': 'auto'
      }
    "
  >
    <div
      class="card"
      style="min-height: calc(100vh - 34px)"
    >
      <!--
      <div
        class="p-col-12 p-formgroup-inline"
      >
        <label
          class="p-col-5
          label-text ic-size-18 p-pb-4"
        >
          Payment Details - {{ engagementName }}
        </label>
      </div>
      -->
      <div
        class="card p-py-1"
      >
        <div
          class="p-grid p-fluid"
        >
          <div
            class="p-col-12 p-mt-3 p-pr-0 manual"
          >
            <p-panel
              header="{{'Invoices Applied'}} (Payment Date: {{ mangoUtils.formatDateString(selectedPayment?.PaymentDate || '') | date:'MM/dd/yyyy' }}, {{ selectedPayment['CheckRef'] ? 'Check Ref: ' + selectedPayment['CheckRef'] : 'Payment Type: ' + selectedPayment['PaymentType']  }})"

              [toggleable]="false"
            >
              <p-table
                #dtchild

                dataKey="PaymentDetailID"
                sortField="InvoiceDate"
                styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"

                [value]="paymentDetailsSidebar"
                [rowHover]="true"
                [sortOrder]="-1"
                [scrollable]="true"
                [scrollHeight]="'calc(85vh - 200px)'"
              >
                <ng-template
                  pTemplate="header"
                >
                  <tr>
                    <th
                      class="width-6p p-text-center"
                    >
                      {{ 'actions' | translate }}
                    </th>
                    <th
                      class="width-7p p-text-center"
                      pSortableColumn="InvoiceDate"
                    >
                      {{ 'Accounts.invoice-date' | translate }}
                      <p-sortIcon
                        field="InvoiceDate"
                      >
                      </p-sortIcon>
                    </th>
                    <th
                      class="width-7p p-text-center"
                    >
                      {{ 'Accounts.invoice-number' | translate }}
                    </th>
                    <th
                      class="width-30p p-text-left"
                    >
                      {{ 'Accounts.invoice-memo' | translate }}
                    </th>
                    <th
                      class="width-10p p-text-right"
                    >
                      {{ 'Accounts.amount_applied' | translate }}
                    </th>
                    <th
                      class="width-10p p-text-center"
                    >
                      {{ 'Account.write_off_amount' | translate }}
                    </th>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="body"
                  let-childrowData
                  let-columns="columns"
                >
                  <tr
                    class="cursor-hand"
                  >
                    <td
                      class="width-6p p-text-center"
                    >
                      <i
                        class="fal fa-undo p-mr-3 ic-green"

                        pTooltip="{{ 'Unapply Payment' | translate }}"
                        tooltipPosition="top"

                        (click)="unapplyPayment(childrowData)"
                      >
                      </i>
                      <i
                        class="fal fa-exchange p-mr-3 ic-mc"

                        pTooltip="{{ 'transfer-payment' | translate }}"
                        tooltipPosition="top"

                        (click)="transferPayment(childrowData)"
                      >
                      </i>
                    </td>
                    <td
                      class="width-7p p-text-center"
                    >
                      <span>
                        {{
                            mangoUtils.formatDateString(childrowData.InvoiceDate)
                          | date:'MM/dd/yyyy'
                        }}
                      </span>
                    </td>
                    <td
                      class="width-7p p-text-center"
                    >
                      {{ childrowData.InvoiceNumber }}
                    </td>
                    <td
                      class="width-30p p-text-left"

                      pTooltip="{{ childrowData.DescriptionShort }}"
                      tooltipStyleClass="tooltip-width-450"
                      tooltipPosition="top"

                      showDelay="800"
                    >
                      {{ childrowData.DescriptionShort }}
                    </td>
                    <td
                      class="width-10p p-text-right"
                    >
                      {{
                            childrowData.AppliedAmount
                          | currency:'USD':'symbol':'1.2-2'
                      }}
                    </td>
                    <td
                      class="width-10p p-text-center"
                    >
                      <span
                        id="remove-border"
                      >
                        {{
                              childrowData.WriteOffAmount
                            | currency:'USD':true:'1.2-2'
                        }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="footer"
                >
                  <tr>
                    <td
                      class="width-6p p-text-center"
                    >
                    </td>
                    <td
                      class="width-7p"
                    >
                    </td>
                    <td
                      class="width-7p"
                    >
                    </td>
                    <td
                      class="width-30p p-text-right ic-red"
                    >
                      {{ 'Totals' | translate }}
                    </td>
                    <td
                      class="width-10p p-text-right ic-red"
                    >
                      <span>
                        {{
                              paymentDetailsSidebar.total
                            | currency:'USD':true:'1.2-2'
                        }}
                      </span>
                    </td>
                    <td
                      class="width-10p p-text-center ic-red"
                    >
                      <span>
                        {{
                              paymentDetailsSidebar.totalwa
                            | currency:'USD':true:'1.2-2'
                        }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </p-panel>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>

<p-dialog [(visible)]="isClientInvoiceDisplay" [style]="{width: !isTransferAdvance ? '63vw' : '25vw'}" [modal]="true" [maximizable]="false"
    showEffect="fade" [baseZIndex]="1" [draggable]="true" [resizable]="false"
    header="{{ (!isTransferAdvance ? 'select_client_apply_payment' : 'transfer-advance-payment') | translate }}">
    <div class="p-col-12 p-pl-3 ic-size-15">
        <label class="label-text p-mr-5 p-pr-1">{{ 'customer_name' | translate }}:</label>
        <p-autoComplete [(ngModel)]="selClient" [ngModelOptions]="{standalone: true}"
            [inputStyle]="{'font-weight': '700 !important'}" [forceSelection]="true"
            autocomplete="off" [suggestions]="filteredClients" [styleClass]="!isTransferAdvance ? 'width-40p' : 'width-100p'"
            appendTo="body" (completeMethod)="filterClients($event)" field="ClientName" (onSelect)="handleSelectClick($event);"
            ngDefaultControl placeholder="Type Client Name.." #selclientID="ngModel" [dropdown]="true" required>
            <ng-template let-client pTemplate="item">
                <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
            </ng-template>
        </p-autoComplete>
    </div>
    <div class="p-col-12 p-d-flex p-jc-between p-pl-3">
        <div class="p-py-0 ic-size-15">
            <label class="label-text p-mr-3">{{ 'Accounts.unapplied-amount' | translate }}:</label>
            <strong>{{ newTotalUnapplied | currency : 'USD' : true : '1.2-2' }}</strong>
        </div>
    </div>
    <div class="p-col-12 p-pl-3" *ngIf="!isTransferAdvance">
        <div class="p-py-0 ic-size-15">
            <label for="address" class="label-text">{{ 'payment-memo-description' | translate }}</label>
            <!-- <mwl-text-input-autocomplete-container>
                <textarea pInputTextarea style="width: 100%;height: 105px; resize: vertical; margin-top: 5px;"
                    name="memo" mwlTextInputAutocomplete [findChoices]="mangoUtils.findChoices"
                    [getChoiceLabel]="mangoUtils.getChoiceLabel" [(ngModel)]="paymentNote"
                    placeholder="{{ 'type_search_shortcuts' | translate }}" (ngModelChange)="replaceShortcuts($event)"
                   ></textarea>
            </mwl-text-input-autocomplete-container> -->
        </div>
    </div>
    <div class="p-col-12 p-py-0 p-mb-3 p-pr-0 manual" *ngIf="!isTransferAdvance">
        <p-panel header="{{ 'Accounts.open-invoices' | translate }}" [toggleable]="true">
            <p-table [rowHover]="true" [responsive]="true" [rows]="7" [value]="clientInvoiceList"
                [showCurrentPageReport]="true" [pageLinks]="3" [filterDelay]="0" [scrollHeight]="'calc(90vh - 360px)'"
                [scrollable]="true" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                dataKey="InvoiceNumber" (onFilter)="onFilter($event)" sortField="InvoiceDate" [sortOrder]="-1"
                #historydt>
                <!-- Table Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th class="p-text-left width-9p" pSortableColumn="InvoiceDate">
                            {{'Accounts.invoice-date' | translate}}
                            <p-sortIcon field="InvoiceDate"></p-sortIcon>
                        </th>
                        <th class="p-text-left width-7p" pSortableColumn="InvoiceNumber">
                            {{'Invoice' | translate}} #
                            <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                        </th>
                        <th class="p-text-left width-15p">
                            {{'invoice-memo' | translate}}
                        </th>
                        <th class="p-text-right width-10p" pSortableColumn="TotalPayments">
                            {{'total-payments' | translate}}
                            <p-sortIcon field="TotalPayments"></p-sortIcon>
                        </th>
                        <th class="p-text-right width-10p" pSortableColumn="InvoiceBalance">
                            {{'Accounts.invoice-balance' | translate}}
                            <p-sortIcon field="InvoiceBalance"></p-sortIcon>
                        </th>

                        <th class="p-text-right width-10p">
                            {{'payments-applied' | translate}}
                        </th>
                        <th class="width-6p p-text-center">{{ 'Accounts.apply' | translate }}</th>
                    </tr>
                </ng-template>
                <!-- Rows -->
                <ng-template pTemplate="body" let-rowData>
                    <tr class="cursor-hand">
                        <td class="p-text-left width-9p"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                            {{ mangoUtils.formatDateString(rowData.InvoiceDate) | date: "MM/dd/yyyy" }}
                        </td>
                        <td class="p-text-left width-7p"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                            {{ rowData.InvoiceNumber }}
                        </td>
                        <td class="p-text-left p-text-nowrap p-text-truncate width-15p" tooltipPosition="top"
                            pTooltip="{{ rowData.DescriptionShort }}" showDelay="800"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''"
                            tooltipStyleClass="tooltip-no-wrap">
                            {{ rowData.DescriptionShort }}
                        </td>
                        <td class="p-text-right width-10p"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">{{
                            rowData.TotalPayments | currency : 'USD' : true : '1.2-2'
                            }}</td>
                        <td class="p-text-right width-10p"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">{{
                            rowData.InvoiceBalance | currency : 'USD' : true : '1.2-2'
                            }}</td>
                        <td class="p-text-right width-10p"
                            [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">{{
                            rowData.PaymentsApplied | currency : 'USD' : true :
                            '1.2-2' }}</td>
                        <td class="width-6p p-text-center">
                            <p-checkbox binary="true" (click)="calculateData(rowData)"
                                [ngClass]="{'wrapper notAllowed': rowData.isEditing}" [(ngModel)]="rowData['selected']"
                                [ngModelOptions]="{standalone: true}">
                            </p-checkbox>
                        </td>
                    </tr>
                </ng-template>
                <!-- No data -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="5">
                            <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                            </p-message>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-panel>
    </div>
    <p-footer>
        <button type="button" pButton pRipple icon="fal fa-check" label=" {{'Accounts.apply' | translate}}"
            (click)="transferPaymentToInvoice()" [disabled]="disableButton()"></button>
        <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}"
            (click)="cancelTransferPayment()"></button>
    </p-footer>
</p-dialog>

<app-debit-memo-dialog [amountUnapplied]="selectedRowData?.PaymentUnapplied" [showDebitMemoDialog]="showDebitMemoDialog"
    (onCloseDebitMemoDialog)="onCloseDebitMemoDialog($event)" (onSaveDebitMemoDialog)="onSaveDebitMemoDialog($event)">
</app-debit-memo-dialog>
